import ActionBarComponent from "../components/General/ActionBarComponent/ActionBarComponent";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import {
  DetailsList,
  DetailsListLayoutMode,
  FontIcon,
  SearchBox,
  SelectionMode,
  Stack,
} from "@fluentui/react";
import Header from "../components/General/Header";
import { isLoading } from "../components/General/Loading";
import { CreateTeam, DeleteTeam, GetTeams } from "../services/TeamService";
import { isEmpty } from "../components/General/Messages/EmptyMessage";
import { teamsColumns } from "../helpers/data";
import ConfirmationDialog from "../components/Modals/ConfirmationDialog";
import { useHistory } from "react-router-dom";
import TeamModal from "../components/Modals/TeamModal";
import CustomMessageBar from "../components/General/Messages/CustomMessageBar";
import { useTitle } from "../helpers/useTitle";

const TeamsPage = () => {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [sortedItems, setSortedItems] = useState([]);
  const [listColumns, setListColumns] = useState([...teamsColumns]);
  const history = useHistory();
  const { t } = useTranslation();
  useTitle("Team management");

  const getTeams = () => {
    setLoading(true);
    GetTeams()
      .then((res) => setTeams(res.data))
      .finally(() => setLoading(false));
  };

  const handleDelete = () => {
    setLoading(true);
    DeleteTeam(selectedItem.id)
      .then(() => {
        setTeams(teams.filter((team) => team.id !== selectedItem.id));
      })
      .catch((e) => {
        setErrorMessage(e.response.data);
        setShowErrorMessage(true);
      })
      .finally(() => setLoading(false));
  };

  const handleCreate = (teamName) => {
    setLoading(true);
    CreateTeam({ name: teamName })
      .then((value) => {
        setTeams((prev) => [...prev, value.data]);
      })
      .catch((e) => {
        setErrorMessage(e.response.data);
        setShowErrorMessage(true);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getTeams();
  }, []);

  useEffect(() => {
    setSortedItems(teams);
  }, [teams]);

  const onColumnClick = (e, column) => {
    let tempColumns = listColumns.map((c) => {
      return {
        ...c,
        isSorted: c.key === column.key,
        isSortedDescending: c.key === column.key && !c.isSortedDescending,
      };
    });
    setListColumns(tempColumns);
    sortItems(column.key, !column.isSortedDescending);
  };

  const sortItems = (key, descending) => {
    let tempItems = [...teams];
    tempItems.sort((a, b) => {
      const valueA = String(a[key]).toLowerCase();
      const valueB = String(b[key]).toLowerCase();

      return !descending
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    });
    setSortedItems(tempItems);
  };

  const filteredItems = useCallback(() => {
    return sortedItems.filter((team) => {
      return (
        !searchQuery ||
        team.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  }, [sortedItems, searchQuery, teams]);

  const renderTeams = (item, index, column) => {
    switch (column.key) {
      case "delete":
        return (
          <FontIcon
            iconName="Trash"
            className={"trash"}
            onClick={() => {
              setSelectedItem(item);
              setOpenDeleteModal(true);
            }}
          />
        );
      case "view":
        return (
          <FontIcon
            iconName="More"
            className={"trash"}
            onClick={() => {
              history.push(`teams/${item.id}`);
            }}
          />
        );
      default:
        return (
          <Stack
            verticalFill
            verticalAlign="center"
            className="clickable"
            onClick={() => {
              history.push(`teams/${item.id}`);
            }}
          >
            {item[column.key]}
          </Stack>
        );
    }
  };

  return (
    <Stack verticalFill>
      <ActionBarComponent
        actions={[
          {
            text: t("teams.createTeam"),
            primary: true,
            action: () => setOpenCreateModal(true),
          },
        ]}
      />
      {showErrorMessage && (
        <CustomMessageBar
          visible={showErrorMessage}
          message={errorMessage}
          error
          setShowSuccessMessage={() => {
            setShowErrorMessage(false);
            setErrorMessage("");
          }}
        />
      )}
      <TeamModal
        isOpen={openCreateModal}
        onDismiss={() => setOpenCreateModal(false)}
        onSubmit={handleCreate}
        headlineText={t("teams.addTeam")}
        submitButtonText={t("common.create")}
      />
      <ConfirmationDialog
        isOpen={openDeleteModal}
        modalText={t("common.delete")}
        onDismiss={() => {
          setOpenDeleteModal(false);
          setSelectedItem(null);
        }}
        onDelete={() => {
          handleDelete();
          setOpenDeleteModal(false);
        }}
        text={"deleteMessage.team"}
      />
      <Stack verticalFill className="page" style={{ paddingRight: 30 }}>
        <Stack
          horizontal
          horizontalAlign={"space-between"}
          style={{ marginBottom: 10, marginTop: 10 }}
        >
          <Header text={t("teams.teams")} />
          <SearchBox
            underlined
            placeholder={t("common.search")}
            onChange={(e, query) => setSearchQuery(query)}
            onClear={() => setSearchQuery("")}
            styles={{ root: { width: "20%" } }}
          />
        </Stack>
        <Stack
          verticalFill
          grow
          style={{
            overflow: "auto",
            height: window.innerHeight - 200,
          }}
        >
          {isLoading(
            loading,
            isEmpty(
              filteredItems(),
              <DetailsList
                items={filteredItems()}
                selectionMode={SelectionMode.none}
                columns={listColumns.map((oc) => {
                  return {
                    ...oc,
                    onColumnClick: oc.unSortable ? null : onColumnClick,
                    name:
                      oc.key === "view"
                        ? `${t("teams.teams")}: ${filteredItems().length}`
                        : t(oc.name),
                  };
                })}
                layoutMode={DetailsListLayoutMode.justified}
                onRenderItemColumn={renderTeams}
              />,
              {
                text: t("emptyMessages.noTeams"),
                icon: "Group",
              }
            )
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TeamsPage;
