import {
  DetailsList,
  DetailsListLayoutMode,
  FontIcon,
  Icon,
  IconButton,
  SearchBox,
  SelectionMode,
  Stack,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import ActionBarComponent from "../components/General/ActionBarComponent/ActionBarComponent";
import React, { useCallback, useEffect, useState } from "react";
import Header from "../components/General/Header";
import { isLoading } from "../components/General/Loading";
import EmptyMessage, {
  isEmpty,
} from "../components/General/Messages/EmptyMessage";
import { teamMembersColumns, TeamRoles } from "../helpers/data";
import {
  AddMembersToTeam,
  DeleteTeamMember,
  GetTeamById,
  UpdateTeam,
} from "../services/TeamService";
import { useHistory, useParams } from "react-router-dom";
import TeamModal from "../components/Modals/TeamModal";
import CustomMessageBar from "../components/General/Messages/CustomMessageBar";
import AddMemberToTeamModal from "../components/Modals/AddMemberToTeamModal";
import ConfirmationDialog from "../components/Modals/ConfirmationDialog";
import { useTitle } from "../helpers/useTitle";

const TeamPage = () => {
  const { id } = useParams();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentTeam, setCurrentTeam] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showEmptyMessage, setShowEmptyMessage] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [sortedItems, setSortedItems] = useState([]);
  const [listColumns, setListColumns] = useState([...teamMembersColumns]);
  const history = useHistory();
  const { t } = useTranslation();
  useTitle("Team");

  const getTeam = () => {
    setLoading(true);
    GetTeamById(id)
      .then((res) => setCurrentTeam(res.data))
      .catch(() => setShowEmptyMessage(true))
      .finally(() => setLoading(false));
  };

  const handleEdit = (teamName) => {
    setLoading(true);
    UpdateTeam(id, { name: teamName })
      .then(() =>
        setCurrentTeam((team) => ({
          ...team,
          name: teamName,
        }))
      )
      .catch((e) => {
        setShowErrorMessage(true);
        setErrorMessage(e.response.data);
      })
      .finally(() => setLoading(false));
  };

  const handleAddMember = (member, role) => {
    setLoading(true);
    AddMembersToTeam(id, [
      { userId: member.key, role: role, teamId: Number(id) },
    ])
      .then((value) =>
        setCurrentTeam((team) => ({
          ...team,
          teamMembers: value.data,
        }))
      )
      .catch((e) => {
        setShowErrorMessage(true);
        setErrorMessage(e.response.data);
      })
      .finally(() => setLoading(false));
  };

  const handleDeleteMember = () => {
    setLoading(true);
    DeleteTeamMember(id, selectedItem.userId)
      .then(() =>
        setCurrentTeam((team) => ({
          ...team,
          teamMembers: team.teamMembers.filter(
            (member) => member.userId !== selectedItem.userId
          ),
        }))
      )
      .catch((e) => {
        setShowErrorMessage(true);
        setErrorMessage(e.response.data);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setCurrentTeam(getTeam);
  }, []);

  useEffect(() => {
    setSortedItems(currentTeam?.teamMembers);
  }, [currentTeam]);

  const onColumnClick = (e, column) => {
    let tempColumns = listColumns.map((c) => {
      return {
        ...c,
        isSorted: c.key === column.key,
        isSortedDescending: c.key === column.key && !c.isSortedDescending,
      };
    });
    setListColumns(tempColumns);
    sortItems(!column.isSortedDescending);
  };

  const sortItems = (descending) => {
    let tempItems = [...currentTeam.teamMembers];
    tempItems.sort((a, b) => {
      const valueA = String(a.user.displayName).toLowerCase();
      const valueB = String(b.user.displayName).toLowerCase();

      return !descending
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    });
    setSortedItems(tempItems);
  };

  const filteredItems = useCallback(() => {
    return sortedItems?.filter((member) => {
      return (
        !searchQuery ||
        member.user?.displayName
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
    });
  }, [sortedItems, searchQuery, currentTeam]);
  const renderMembers = (item, index, column) => {
    switch (column.key) {
      case "delete":
        return (
          <FontIcon
            iconName="Trash"
            className={"trash"}
            onClick={() => {
              setSelectedItem(item);
              setOpenDeleteModal(true);
            }}
          />
        );
      case "name":
        return (
          <Stack verticalFill verticalAlign="center">
            {item.user?.displayName}
          </Stack>
        );
      case "role":
        return (
          <Stack verticalFill verticalAlign="center">
            {t(TeamRoles[item.role])}
          </Stack>
        );
      default:
        return (
          <Stack verticalFill verticalAlign="center">
            {item[column.key]}
          </Stack>
        );
    }
  };

  return (
    <Stack verticalFill>
      {showEmptyMessage ? (
        <EmptyMessage text={t("emptyMessages.noTeam")} icon={"Group"} />
      ) : (
        <>
          <ActionBarComponent
            actions={[
              {
                text: t("teams.addMember"),
                primary: true,
                action: () => setOpenAddModal(true),
              },
              {
                text: t("teams.editTeam"),
                primary: false,
                action: () => setOpenEditModal(true),
              },
            ]}
          />
          {showErrorMessage && (
            <CustomMessageBar
              visible={showErrorMessage}
              message={errorMessage}
              setShowSuccessMessage={() => {
                setShowErrorMessage(false);
                setErrorMessage("");
              }}
              error
            />
          )}
          <AddMemberToTeamModal
            isOpen={openAddModal}
            onSubmit={handleAddMember}
            onDismiss={() => setOpenAddModal(false)}
          />
          <TeamModal
            isOpen={openEditModal}
            onSubmit={handleEdit}
            onDismiss={() => setOpenEditModal(false)}
            headlineText={t("teams.editTeam")}
            submitButtonText={t("common.edit")}
          />
          <ConfirmationDialog
            isOpen={openDeleteModal}
            modalText={t("common.delete")}
            onDismiss={() => {
              setOpenDeleteModal(false);
              setSelectedItem(null);
            }}
            onDelete={() => {
              handleDeleteMember();
              setOpenDeleteModal(false);
            }}
            text={"deleteMessage.user"}
          />
          <Stack verticalFill className="page" style={{ paddingRight: 30 }}>
            <Stack
              horizontal
              horizontalAlign={"space-between"}
              style={{ marginBottom: 10, marginTop: 10 }}
            >
              <Stack horizontal>
                <IconButton
                  iconProps={{
                    iconName: "Back",
                    styles: { root: { fontSize: 20 } },
                  }}
                  title={t("teams.backToTeams")}
                  ariaLabel={t("teams.backToTeams")}
                  style={{
                    height: "34px",
                    width: "34px",
                    marginRight: "10px",
                    color: "#000000",
                  }}
                  onClick={() => {
                    history.push("/teams");
                  }}
                />
                <Header text={currentTeam?.name} />
              </Stack>
              <SearchBox
                underlined
                placeholder={t("common.search")}
                styles={{ root: { width: "20%" } }}
                onChange={(e, query) => setSearchQuery(query)}
                onEmptied={() => setSearchQuery("")}
              />
            </Stack>
            <Stack
              verticalFill
              grow
              style={{
                overflow: "auto",
                height: window.innerHeight - 200,
              }}
            >
              {isLoading(
                loading,
                isEmpty(
                  filteredItems(),
                  <DetailsList
                    items={filteredItems()}
                    selectionMode={SelectionMode.none}
                    columns={listColumns.map((oc) => {
                      return {
                        ...oc,
                        onColumnClick: oc.unSortable ? null : onColumnClick,
                        name:
                          oc.key === "delete"
                            ? `${t("management.users")}: ${
                                filteredItems()?.length
                              }`
                            : t(oc.name),
                      };
                    })}
                    layoutMode={DetailsListLayoutMode.justified}
                    onRenderItemColumn={renderMembers}
                  />,
                  {
                    text: t("teams.noUsers"),
                    icon: "Group",
                  }
                )
              )}
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default TeamPage;
