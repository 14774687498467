import { Stack, StackItem, Text } from "@fluentui/react";
import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ActionBarComponent from "../components/General/ActionBarComponent/ActionBarComponent";
import Header from "../components/General/Header";
import { RoleContext, TeamLeaderContext } from "../components/General/Layout";
import "../styles/custom.css";
import { useTitle } from "../helpers/useTitle";

const DeskManagementPage = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  useTitle("Desk management");

  const isTeamLeader = useContext(TeamLeaderContext);
  const roles = useContext(RoleContext);

  const hasRole = useCallback(
    (role) => {
      return roles?.filter((r) => r.role.name === role).length > 0;
    },
    [roles]
  );

  useEffect(() => {
    if (!hasRole("Permission Manager") && !isTeamLeader)
      history.push("/nopermission");
  }, [roles]);

  return (
    <>
      <ActionBarComponent
        id="action-bar"
        actions={[
          {
            key: "viewOrRemove",
            text: t("sidebar.viewRemove"),
            icon: "",
            primary: true,
            action: () => history.push("/deskmanagement/view-remove"),
            disabled: false,
            leader: true,
          },
          {
            key: "assignDesk",
            text: t("sidebar.assignDesk"),
            icon: "",
            primary: true,
            action: () => history.push("/deskmanagement/assigndesk"),
            disabled: false,
            leader: true,
          },
          {
            key: "manageFloorplans",
            text: t("sidebar.manage"),
            icon: "",
            primary: true,
            action: () => history.push("/deskmanagement/floorplans"),
            disabled: false,
          },
        ].filter((button) =>
          hasRole("Permission Manager") ? true : button.leader
        )}
      />
      <Stack
        style={{ marginTop: 30 }}
        tokens={{ childrenGap: 40 }}
        className="page"
      >
        <Header text={t("sidebar.deskManage")} />
        <StackItem style={{ maxWidth: "900px" }}>
          <h5 style={{ paddingBottom: 15 }}>{t("sidebar.viewRemove")}</h5>
          <Text>{t("deskManagement.viewOrRemove")}</Text>
        </StackItem>
        <StackItem style={{ maxWidth: "900px" }}>
          <h5 style={{ paddingBottom: 15 }}>{t("sidebar.assignDesk")}</h5>
          <Text>{t("deskManagement.assignDesk")}</Text>
        </StackItem>
        <StackItem style={{ maxWidth: "900px" }}>
          <h5 style={{ paddingBottom: 15 }}>{t("sidebar.manage")}</h5>
          <Text>{t("deskManagement.manageFloorplans")}</Text>
        </StackItem>
      </Stack>
    </>
  );
};

export default DeskManagementPage;
