import { IconButton, Stack } from "@fluentui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Image as KonvaImage, Layer, Stage, Transformer } from "react-konva";

import Konva from "konva";
import DeskComponent from "./DeskComponent";
import OfficeComponent from "./OfficeComponent";

let oldAttrs;

const FloorplanComponent = ({
  offices,
  drawing,
  desks,
  officeRefs,
  handleOnClick,
  handleDeskSelection,
  deskRefs,
  deskEditing,
  officeEditing,
  transformerRef,
  image,
  edit,
  editing,
  setNewDesks,
  handleMouseDown,
  handleMouseUp,
  handleMouseMove,
  rotation,
  mobile,
  radius,
  allowDrawing,
}) => {
  if (rotation) {
    Konva.hitOnDragEnabled = true;
  }
  const [stageScale, setStageScale] = useState(1);
  const [stageSize, setStageSize] = useState({ width: 0, height: 0 });
  const [stageX, setStageX] = useState(0);
  const [stageY, setStageY] = useState(0);
  const [scaleChanged, setScaleChanged] = useState(false);
  const stageRef = useRef();
  const layerRef = useRef();
  const [lastPinch, setLastPinch] = useState({ dist: 0, center: null });

  useEffect(() => {
    const checkSize = () => {
      if (image) {
        setStageSize({
          width: document.getElementById("test").clientWidth,
          height: window.innerHeight * 0.9,
        });
        setStageX(rotation ? -window.innerWidth / 10 : 0);
        setStageY(0);
        if (image.width > image.height) {
          let res = image.width / window.innerWidth;
          setStageScale(mobile ? res * 1.5 : res / 1.2);
        } else {
          let res = image.height / window.innerHeight;
          setStageScale(mobile ? res * 1.5 : res / 6);
        }
      }
    };
    window.addEventListener("resize", checkSize);
    checkSize();
    return () => window.removeEventListener("resize", checkSize);
  }, [image]);

  const getWidth = () => {
    if (mobile) return window.innerWidth * 0.75;
    return "100%";
  };

  const handleTouchStart = (e) => {
    if (e.evt.touches.length > 1 && stageRef?.current) {
      stageRef.current.on("touchend", () => {
        setLastPinch({ dist: 0, center: null });
      });
    } else {
      stageRef.current.off("touchend");
    }
  };

  const handleDeskClick = (index, e) => {
    if (!edit && !desks[0].view) handleDeskSelection(desks[index], e);
  };

  const renderOffices = () =>
    offices.map((o, i) => (
      <OfficeComponent
        key={i}
        office={o}
        officeRefs={officeRefs}
        index={i}
        edit={edit}
        handleTransform={handleTransform}
        handleDragMove={handleDragMove}
      />
    ));
  const renderDesks = () =>
    desks.map((d, i) => (
      <DeskComponent
        key={i}
        desk={d}
        deskRefs={deskRefs}
        index={i}
        edit={edit}
        radius={radius ?? 13}
        deskEdit={deskEditing}
        onClick={(e) => {
          handleDeskClick(i, e);
        }}
        handleTransform={handleTransform}
        handleDragMove={handleDragMove}
      />
    ));

  const handleWheel = useCallback((e) => {
    e.evt.preventDefault();

    const scaleBy = 0.98;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();

    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };

    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    setStageScale(newScale);
    setStageX(
      -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale
    );
    setStageY(
      -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale
    );
    setScaleChanged(true);
  }, []);

  function getDistance(p1, p2) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  }

  function getCenter(p1, p2) {
    return {
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2,
    };
  }

  const handlePinchZoom = useCallback(
    (e) => {
      e.evt.preventDefault();
      var touch1 = e.evt.touches[0];
      var touch2 = e.evt.touches[1];
      var stage = stageRef.current;

      if (touch1 && touch2) {
        if (stage.isDragging()) {
          stage.stopDrag();
        }

        var p1 = {
          x: touch1.clientX,
          y: touch1.clientY,
        };
        var p2 = {
          x: touch2.clientX,
          y: touch2.clientY,
        };

        if (!lastPinch.center) {
          setLastPinch((prev) => {
            return { ...prev, center: getCenter(p1, p2) };
          });
          return;
        }
        var newCenter = getCenter(p1, p2);

        var dist = getDistance(p1, p2);

        if (!lastPinch.dist) {
          lastPinch.dist = dist;
          setLastPinch((prev) => {
            return { ...prev, dist: dist };
          });
        }

        var pointTo = {
          x: (newCenter.x - stage.x()) / stage.scaleX(),
          y: (newCenter.y - stage.y()) / stage.scaleX(),
        };

        var scale = stage.scaleX() * (dist / lastPinch.dist);

        setStageScale(scale);

        var dx = newCenter.x - lastPinch.center.x;
        var dy = newCenter.y - lastPinch.center.y;

        var newPos = {
          x: newCenter.x - pointTo.x * scale + dx,
          y: newCenter.y - pointTo.y * scale + dy,
        };

        stage.position(newPos);

        setLastPinch({ dist: dist, center: newCenter });

        setStageX(newPos.x);
        setStageY(newPos.y);
        setScaleChanged(true);
      }
    },
    [lastPinch]
  );

  const handleTransform = (index) => {
    const shape = officeRefs.current[index];
    if (!shape) return;
    const node = {
      x: shape.x(),
      y: shape.y(),
      width: shape.width() * shape.scaleX(),
      height: shape.height() * shape.scaleY(),
    };
    const isOut =
      node.x < 0 ||
      node.y < 0 ||
      (node.x + node.width) * stageScale > image?.width * stageScale ||
      (node.y + node.height) * stageScale > image?.height * stageScale;
    if (isOut) {
      shape.setAttrs(oldAttrs);
    } else {
      oldAttrs = { ...shape.getAttrs() };
    }
  };

  const handleDragMove = (circle, index) => {
    if (circle) {
      const shape = deskRefs.current[index];
      const newDesks = [...desks];
      if (shape) {
        newDesks[index] = {
          ...newDesks[index],
          x: shape.x(),
          y: shape.y(),
        };
      }
      setNewDesks(newDesks);
    }
    if (!officeRefs.current[index]) return;
    const shape = officeRefs.current[index];
    if (!shape) return;

    const node = {
      x: shape.x(),
      y: shape.y(),
      width: shape.width() * shape.scaleX(),
      height: shape.height() * shape.scaleY(),
    };

    if (node.x < 0) shape.x(0);
    if (node.y < 0) shape.y(0);
    if ((node.x + node.width) * stageScale > image?.width * stageScale) {
      shape.x(image?.width - node.width);
    }
    if ((node.y + node.height) * stageScale > image?.height * stageScale) {
      shape.y(image?.height - node.height);
    }
  };

  const resetStage = () => {
    setStageScale((image.width / window.innerWidth) * mobile ? 2 : 1);
    setStageX(rotation ? -window.innerWidth / 2 : 0);
    setStageY(0);
    stageRef.current.position({ x: 0, y: 0 });
    setScaleChanged(false);
  };

  return (
    <Stack
      verticalAlign="center"
      verticalFill
      id="container"
      style={{
        margin: "20px",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
        id="test"
      >
        <Stage
          style={{
            width: getWidth(),
            height: "100%",
          }}
          ref={stageRef}
          size={stageSize}
          scale={{ x: stageScale, y: stageScale }}
          position={{ x: stageX, y: stageY }}
          offsetX={
            rotation
              ? -image?.height - (stageSize?.width - image?.height) / 2
              : 0
          }
          draggable={!drawing && !deskEditing && !editing}
          onDragStart={() => {
            setScaleChanged(true);
          }}
          onTouchStart={handleTouchStart}
          onDragEnd={
            !drawing && !deskEditing && !allowDrawing && !officeEditing
              ? (e) => {
                  let x = e.target.x();
                  let y = e.target.y();
                  setStageX(x);
                  setStageY(y);
                }
              : null
          }
          onClick={handleOnClick}
          onTouchMove={handlePinchZoom}
          onWheel={handleWheel}
          onMouseDown={edit ? handleMouseDown : null}
          onMouseUp={edit ? handleMouseUp : null}
          onMouseMove={edit ? handleMouseMove : null}
        >
          <Layer ref={layerRef} rotation={rotation ? 90 : 0}>
            <KonvaImage x={0} y={0} image={image} />
            {renderOffices()}
            {renderDesks()}
            <Transformer ref={transformerRef} />
          </Layer>
        </Stage>
        {scaleChanged && (
          <IconButton
            iconProps={{
              iconName: "Refresh",
              styles: { root: { fontSize: 20 } },
            }}
            title="Reset Zoom"
            ariaLabel="Reset Zoom"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 1,
              height: "35px",
              width: "35px",
            }}
            onClick={resetStage}
          />
        )}
      </div>
    </Stack>
  );
};

export default FloorplanComponent;
