import { InteractionType } from "@azure/msal-browser";
import { useMsalAuthentication } from "@azure/msal-react";
import { Stack, Text } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import Loading from "../components/General/Loading";
import { useTitle } from "../helpers/useTitle";

const AuthPage = () => {
  const [content, setContent] = useState(<Loading />);
  useTitle("Authentication page");

  let { login, response, error } = useMsalAuthentication(
    InteractionType.Redirect
  );

  useEffect(() => {
    if (error) {
      localStorage.clear();
      sessionStorage.clear();
      setContent(
        <Stack horizontalAlign="center" verticalAlign="center" verticalFill>
          <Text variant="mediumPlus">There was an issue logging you in</Text>
          <Text
            onClick={() => {
              login(InteractionType.Redirect);
            }}
            variant="mediumPlus"
            className="ActionLink"
          >
            Click here to retry
          </Text>
        </Stack>
      );
    }
  }, [error, login]);

  return content;
};

export default AuthPage;
