import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import EditOfficeFloorplanComponent from "../components/FloorplanEditor/EditOfficeFloorplanComponent";
import EditParkingFloorplanComponent from "../components/FloorplanEditor/EditParkingFloorplanComponent";
import Loading from "../components/General/Loading";
import EmptyMessage from "../components/General/Messages/EmptyMessage";
import { GetLocation } from "../services/LocationService";
import { useTitle } from "../helpers/useTitle";

const FloorplanPage = () => {
  const { id } = useParams();
  const previousUrl = useLocation();
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState();
  const { t, i18n } = useTranslation();
  let baseUrl = previousUrl.pathname.includes("parkingmanagement");
  useTitle(baseUrl ? "Parking floorplan" : "Desk floorplan");

  const getLocation = async () => {
    setLoading(true);
    let data = await GetLocation(id);
    setLocation(data);
    setLoading(false);
  };

  useEffect(() => {
    getLocation();
  }, []);

  if (loading) return <Loading />;

  if (!location)
    return (
      <EmptyMessage icon="TextDocument" text={t("emptyMessages.notFound")} />
    );
  if (baseUrl) return <EditParkingFloorplanComponent location={location} />;
  else return <EditOfficeFloorplanComponent location={location} />;
};

export default FloorplanPage;
