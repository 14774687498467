import { DetailsList, IconButton, SelectionMode, Stack } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ActionBarComponent from "../components/General/ActionBarComponent/ActionBarComponent";
import { isLoading } from "../components/General/Loading";
import Tooltip from "../components/General/Tooltip";
import AdminRoleModal from "../components/Modals/AdminRoleModal";
import ConfirmationDialog from "../components/Modals/ConfirmationDialog";
import {
  CreateRoleAssignments,
  DeleteRoleAssignment,
  GetRoleAssignments,
  GetRoles,
} from "../services/RoleService";
import { GetCurrentUserRoles } from "../services/UserService";
import CustomMessageBar from "../components/General/Messages/CustomMessageBar";
import { useTitle } from "../helpers/useTitle";

const AdminRolePage = (props) => {
  const history = useHistory();
  const [roles, setRoles] = useState([]);
  const [roleAssignments, setRoleAssignments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openRoleAssignment, setOpenRoleAssignment] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { t, i18n } = useTranslation();
  useTitle("Application roles");

  const DeleteRoleAssign = async (roleAssignmentId) => {
    setLoading(true);
    setOpenDeleteModal(false);
    await DeleteRoleAssignment(roleAssignmentId).then(() => checkUserRoles());
    GetRoleAssignments().then((r) => {
      setRoleAssignments(r);
      setLoading(false);
    });
  };

  const roleAssignmentColumns = [
    {
      key: "userName",
      fieldName: "userName",
      name: t("common.user"),
      minWidth: 200,
      maxWidth: 350,
      onRender: (item) => (
        <Stack verticalAlign="center" verticalFill>
          {item?.user?.displayName}
        </Stack>
      ),
    },
    {
      key: "roleName",
      fieldName: "roleName",
      name: t("adminRole.role"),
      onRender: (item) => (
        <Stack verticalAlign="center" verticalFill>
          {item?.role?.name}
        </Stack>
      ),
    },
    {
      key: "delete",
      name: t("common.delete"),
      onRender: (item) => (
        <IconButton
          onClick={() => {
            setOpenDeleteModal(true);
            setSelectedItem(item);
          }}
          iconProps={{ iconName: "Trash" }}
        />
      ),
    },
  ];

  const GetData = async () => {
    setLoading(true);
    let promises = [];
    promises.push(GetRoles().then((r) => setRoles(r)));
    promises.push(GetRoleAssignments().then((r) => setRoleAssignments(r)));
    await Promise.all(promises);
    setLoading(false);
  };

  useEffect(() => {
    checkUserRoles();
  }, []);

  const checkUserRoles = () => {
    setLoading(true);
    GetCurrentUserRoles().then((r) => {
      if (r.filter((r) => r.role.name === "Admin").length === 0) {
        setLoading(false);
        history.push("/");
      } else {
        GetData();
      }
    });
  };

  const handleRoleAssign = async (roleAssignment) => {
    setOpenRoleAssignment(false);
    setLoading(true);

    let assignedRole = await CreateRoleAssignments(roleAssignment);
    if (assignedRole.response) {
      setErrorMessage(assignedRole.response.data);
      setShowErrorMessage(true);
    }

    await GetData();
    setLoading(false);
  };

  return (
    <>
      <ActionBarComponent
        id="action-bar"
        actions={[
          {
            key: "roles",
            text: t("adminRole.addRole"),
            icon: "Group",
            primary: true,
            action: () => setOpenRoleAssignment(true),
            disabled: false,
          },
        ]}
      />
      {showErrorMessage && (
        <CustomMessageBar
          message={errorMessage}
          visible={showErrorMessage}
          error
          setShowSuccessMessage={() => setShowErrorMessage(false)}
        />
      )}
      <ConfirmationDialog
        isOpen={openDeleteModal}
        modalText={t("common.delete")}
        onDismiss={() => setOpenDeleteModal(false)}
        onDelete={() => DeleteRoleAssign(selectedItem.id)}
        text={"deleteMessage.role"}
      />
      <Stack verticalFill className="page" style={{ marginRight: "1%" }}>
        <Tooltip
          label={"adminRole.application"}
          fontSize={16}
          style={{
            height: "20px",
            marginLeft: "3px",
            width: "20px",
          }}
        />
        {isLoading(
          loading,
          <DetailsList
            compact
            items={roleAssignments}
            columns={roleAssignmentColumns}
            selectionMode={SelectionMode.none}
          />
        )}
        <AdminRoleModal
          isOpen={openRoleAssignment}
          onDismiss={() => setOpenRoleAssignment(false)}
          roles={roles}
          onSubmit={handleRoleAssign}
        />
      </Stack>
    </>
  );
};

export default AdminRolePage;
