import fetchApi from "./Axios";

export async function getData(endpoint, queryParams = {}) {
  let jsonData = [];
  try {
    const response = await fetchApi.get(endpoint,
      { params: queryParams }
    );
    if (![200, 201].includes(response.status)) throw await response.text();
    jsonData = await response.data;
  } catch (error) {
    //TODO Error handling
    console.log(error);
  }
  return jsonData;
}

export async function postData(endpoint, postData) {
  let jsonData = [];
  try {
    const { data } = await fetchApi.post(endpoint,
      postData
    );
    jsonData = await data;
  } catch (error) {
    //TODO Error handling
    return error;
  }
  return jsonData;
}

export async function deleteData(endpoint, queryParams = {}) {
  let jsonData = [];
  try {
    const { data } = await fetchApi.delete(endpoint,
      { params: queryParams }
    );
    jsonData = await data;
  } catch (error) {
    //TODO Error handling
    console.log(error);
  }
  return jsonData;
}

export async function putData(endpoint, putData, queryParams = {}) {
  let jsonData = [];
  try {
    const { data } = await fetchApi.put(endpoint,
      putData,
      { params: queryParams }
    );
    jsonData = await data;
  } catch (error) {
    //TODO Error handling
    return error;
  }
  return jsonData;
}

export async function getDataPromise(endpoint, queryParams = {}) {
  return fetchApi.get(endpoint,
    { params: queryParams }
  );
}

export async function postDataPromise(endpoint, postData) {
  return fetchApi.post(endpoint,
    postData
  );
}

export async function deleteDataPromise(endpoint, queryParams = {}) {
  return fetchApi.delete(endpoint,
    { params: queryParams }
  );
}

export async function putDataPromise(endpoint, data) {
  return fetchApi.put(endpoint,
    data
  );
}

export async function getFile(endpoint, queryParams = {}) {
  return fetchApi.get(endpoint,
    {
      responseType: "blob",
      params: queryParams,
    }
  );
}
