import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export const resources = {
  en: {
    languageName: "English",
    translation: {
      sidebar: {
        home: "Upcoming reservations",
        deskReservation: "New Desk Reservation",
        parkingReservation: "New Parking Reservation",
        viewRemove: "View or remove reservations",
        config: "Configuration page",
        deskManage: "Desk Management",
        teamManage: "Team Management",
        permissions: "Permissions",
        assignDesk: "Assign desk",
        manage: "Manage floorplans",
        parkManage: "Parking Management",
        parkPermission: "Parking permissions",
        manageReservation: "Manage parking reservations",
        manageParkingFloorplans: "Manage parking floorplans",
        permanent: "Permanent parking",
        report: "Report",
        roles: "Application Roles",
        clearMemory: "Clear Memory",
        signOut: "Sign Out",
        viewRemoveDesk: "View or remove desk reservations",
        viewRemoveParking: "View or remove parking reservations",
      },
      nomatch: {
        message: "Nothing found here please navigate elsewhere",
      },
      common: {
        submit: "Submit",
        create: "Create",
        close: "Close",
        cancel: "Cancel",
        done: "Done",
        reset: "Reset",
        edit: "Edit",
        delete: "Delete",
        deleteAll: "Delete all",
        location: "Location",
        selectLocation: "Select Location",
        user: "User",
        period: "Period",
        selectPeriod: "Select Period",
        name: "Name",
        userName: "User Name",
        licensePlate: "License Plate(s)",
        save: "Save",
        fromDate: "From Date",
        selectDate: "Click to select date",
        parkingSpot: "Parking Spot",
        selectParkingSpot: "Select Parking Spot",
        parkingSpots: "Parking spots",
        assignedSpots: "Assigned parking spots",
        selectSpot: "Select Spot",
        spotNumber: "Spot No.",
        desk: "Desk",
        desks: "Desks",
        morning: "Morning",
        afternoon: "Afternoon",
        permanent: "Permanent",
        reserve: "Reserve",
        apply: "Apply",
        select: "Select",
        search: "Search",
        office: "Office",
        rename: "Rename",
        move: "Move",
        on: "on",
        of: "of",
        for: "for",
        view: "View",
        from: "from",
        upn: "User Principal Name",
        assign: "Assign",
        reservation: "reservation",
        reservations: "Reservations",
        no: "No",
        activate: "Activate",
        deactivate: "Deactivate",
        processing: "Processing",
      },
      deleteMessage: {
        deleteMessage: "Are you sure you want to delete ",
        reservation: "this reservation",
        group: "this group",
        office: "this office",
        user: "this user",
        role: "this role",
        team: "this team",
        parking: "this parking permission",
        assign: "this assigned parking spot",
        confirmDelete: "Confirm deletion",
        confirmSubtext:
          "Your actions will result in deletion all of these records.",
      },
      config: {
        information: "Company information",
        company: "Company color",
        days: "days",
        booking: "Booking Rules",
        deskRule: "How much in advance can users make desk bookings?",
        parkingRule: "How much in advance can users make parking bookings?",
        color: "color",
        logo: "Logo",
        removeLogo: "Delete logo",
        resetColor: "Reset color",
      },
      adminRole: {
        role: "Role",
        addRole: "Add role",
        application: "Application Roles",
        adminRole: "Admin role - can assign roles to other users",
        permissionManager:
          "Permission manager - can manage, has access to reports, can create new floorplans",
      },
      report: {
        missing: "Report wasn't found, please contact the system administrator",
      },
      period: {
        morning: "only morning (till 12:00PM)",
        afternoon: "only afternoon (from 12:30PM)",
        halfDay: "half day",
        oneDay: "all day",
        twoDays: "2 working days",
        threeDays: "3 working days",
        fourDays: "4 working days",
        fiveDays: "5 working days",
      },
      management: {
        createGroup: "Create Group",
        groupName: "Group name",
        allUsers: "All Users",
        users: "Users",
        selectUser: "Select user",
        groups: "Groups",
        selectGroup: "Select group",
        offices: "Offices",
        selectOffice: "Select office",
        officeAccess: "Office Access",
        assignToGroup: "Assign user to group",
        addGroupToOffice: "Add group to office",
        addOfficePerm: "Add office permission",
        addOfficeAcc: "Add office access",
        permissions: "Permissions",
        permission: "Permission",
        addPermission: "Add permission",
        selectPermission: "Select permission",
        addPermissionToGroup: "Add permission to group",
        addUser: "Add user",
        addGroup: "Add new group",
        editGroup: "Edit group",
        addParkPerm: "Add parking permission",
        userWithPermission: "Users with parking permissions",
      },
      floorplan: {
        floorplans: "Floorplans",
        deskFloorplans: "Desk Floorplans",
        parkingFloorplans: "Parking Floorplans",
        create: "Create Floorplan",
        edit: "Edit Floorplan",
        office: "Add Office",
        desk: "Add Desk",
        officesDesks: "Offices & Desks",
        upload: "Upload image",
        uploaded: "File uploaded",
        reservedBy: "Reserved by",
        createOffice: "Create Office",
        editOffice: "Edit Office",
        editDesk: "Edit Desk",
        addParking: "Add Parking",
        createParking: "Create Parking",
        createDesk: "Create desks",
        noFloorplan: "This Location doesn't have an assigned floorplan",
        editDeskRadius: "Change desk radius",
      },
      home: {
        upcoming: "Upcoming Reservations",
        addDeskReservation: "Add New Desk Reservation",
        addParkReservation: "Add New Parking Reservation",
        in: "Reservation in",
      },
      permanent: {
        assignedSpots: "Users with Assigned spots",
        assign: "Assign spot",
      },
      successMessages: {
        logout: "You have been successfully logged out.",
        delete: "Successfully deleted a user.",
        save: "Successfully updated a user's licence plate.",
        add: "Successfully added a user.",
        saved: "Saved.",
      },
      errorMessages: {
        message: "Unexpected Error.",
      },
      emptyMessages: {
        login: "Click here to Log in",
        noOffice: "No offices found",
        noDesk: "No desks found",
        noParkingSpots: "No parking spots found",
        makeReservation: "To make a reservation, please select location first.",
        makeReservationAssign:
          "To make a reservation, please select location and user first.",
        makeParkReservation:
          "To make parking reservation, please select user and location first.",
        upcomingRes: "It looks like you don't have any upcoming reservations.",
        notFound: "Location not found.",
        selectUser: "Please, first select user.",
        selectLocation: "Please select a location.",
        viewDeskReservation:
          "To view a desk reservation, please select location or user first.",
        viewParkingReservation:
          "To view a parking reservation, please select location or user first.",
        noUsers: "It looks like you don't have any users.",
        noGroups: "It looks like you don't have any groups added.",
        noTeams: "It looks like you don't have any teams.",
        noTeam: "Team not found",
        noOffices: "It looks like you don't have any offices.",
        noPermissions: "It looks like you don't have any permissions",
        noLocations: "It looks like you don't have any locations.",
        noUsersWithPermission:
          "It looks like you don't have any users with parking permissions.",
        onDateWholeDay: "on your date for whole day.",
        userHasReservation: " already has reservation/s in this time range",
        alreadyHasReservation:
          "You already have reservation/s in this time range.",
        noFloorplan: "This location does not have a floorplan.",
        permanentParking:
          "It looks like you don’t have any users with assigned parking spots. ",
        noLocationPermission:
          "You don't have permission for any location, please contact your system administrator.",
      },
      tooltip: {
        office:
          "After naming your office, left click and drag. If necessary, you can move and/or resize your office. When you are satisfied with the shape of your office click Done.",
        desk: "Click and place your desk to desired position.",
      },
      deskManagement: {
        deskPermission: `Lets you control user's interaction with this system. Firstly, you
        have to create a group, then you can add users to the group.
            Secondly, you need to assign an office access to the specific group.
        Groups can have access to a multiple offices.`,
        assignDesk: `It's a feature that lets you assign a desk to a specific user.
            You'll just pick a user that you want to assign a desk to, location,
            period and date. After that you can click assign desk and you
            successfully assigned a desk.`,
        manageFloorplans: `If you don't have any floorplans yet, you have to click the create
            floorplan button. A pop up will appear asking you for a name and for
            image. After you are done creating a floorplan, you will be
            transferred to a screen where you need to define your office
            sections. Then you need to add desks to the office. You'll have list
            of all your offices and desks on your right side of the screen. From
            there you can edit your desks or offices any time.`,
        viewOrRemove: `On this page you can view and remove reservations for a specified user.`,
      },
      parkingManagement: {
        parkPermission: `In this tab you’ll find a list of all users that have parking
            permissions. In this list you can find all your users license plate
            numbers. One user can have multiple license plate numbers entered.
            On this screen you can also add parking permission.`,
        manageReservation: `This screen is dedicated to managing parking reservations. You can
            select a user that you want to manage parking reservations for,
            location and period. A calendar will appear. In this calendar you’ll
            see if the selected user has parking reservation or not.`,
        manageParkingFloorplans: `If you don't have any floorplans yet, you have to 
            click the create floorplan button. A pop up will appear asking you for a 
            name and for image. After you are done creating a floorplan, you will be 
            transferred to a screen with a list of all your floorplans. From there you can 
            define your parking spots.`,
        permanentParking: `On this screen you’ll see all users that have assigned parking
            spots. From here you can assign a new spot or edit an existing
            record.`,
        viewOrRemove: `On this page you can view and remove reservations for a specified user.`,
      },
      teams: {
        teams: "Teams",
        team: "Team",
        createTeam: "Create team",
        addTeam: "Add new team",
        teamName: "Team name",
        addMember: "Add team member",
        editTeam: "Edit team",
        role: "Role",
        teamLeader: "Team leader",
        teamMember: "Team member",
        backToTeams: "Back to teams",
        noUsers: "It looks like this team doesn't have any users.",
      },
    },
  },
  sk: {
    languageName: "Slovak",
    translation: {
      sidebar: {
        home: "Nadchádzajúce rezervácie",
        deskReservation: "Nová rezervácia stola",
        parkingReservation: "Nová rezervácia parkovania",
        viewRemove: "Zobraziť alebo odstrániť rezervácie",
        config: "Konfiguračná stránka",
        deskManage: "Správa stolov",
        teamManage: "Správa tímov",
        permissions: "Oprávnenia",
        assignDesk: "Priradiť stôl",
        manage: "Spravovanie pôdorysov",
        parkManage: "Spravovanie parkovania",
        parkPermission: "Parkovacie práva",
        manageReservation: "Spravovanie parkovacích rezervacií",
        manageParkingFloorplans: "Spravovanie parkovacích pôdorysov",
        permanent: "Trvalé parkovanie",
        report: "Správa",
        roles: "Aplikačné roly",
        clearMemory: "Vyčistiť pamäť",
        signOut: "Odhlásiť sa",
        viewRemoveDesk: "Zobraziť alebo odstrániť rezervácie stolov",
        viewRemoveParking:
          "Zobraziť alebo odstrániť rezervácie parkovacích miest",
      },
      nomatch: {
        message: "Nič sme tu nenašli, prosím prejdite inam",
      },
      common: {
        submit: "Odoslať",
        create: "Vytvoriť",
        close: "Zavrieť",
        cancel: "Zrušiť",
        done: "Hotovo",
        edit: "Upraviť",
        reset: "Reset",
        delete: "Vymazať",
        deleteAll: "Všetko vymazať",
        location: "Poloha",
        selectLocation: "Vybrať Polohu",
        user: "Použivateľ",
        period: "Obdobie",
        selectPeriod: "Vyberte Obdobie",
        name: "Meno",
        userName: "Použivateľské Meno",
        licensePlate: "ŠPZ",
        save: "Uložiť",
        fromDate: "Od Dátumu",
        selectDate: "Vyberte dátum",
        parkingSpot: "Parkovacie miesto",
        selectParkingSpot: "Vybrať parkovacie miesto",
        parkingSpots: "Parkovacie miesta",
        assignedSpots: "Pridelené parkovacie miesta",
        selectSpot: "Vybrať miesto",
        spotNumber: "Číslo Miesta",
        desk: "Stôl",
        desks: "Stoly",
        morning: "Dopoludnie",
        afternoon: "Popoludnie",
        permanent: "Trvalý",
        reserve: "Rezervovať",
        apply: "Uplatniť",
        select: "Vybrať",
        search: "Vyhľadať",
        office: "Kancelária",
        rename: "Premenovať",
        move: "Presunúť",
        on: "dňa",
        of: "",
        for: "na",
        view: "Zobraziť",
        from: "z",
        upn: "Identifikátor",
        assign: "Prideliť",
        reservation: "rezervácia",
        reservations: "Rezervácie",
        no: "Žiadne",
        activate: "Aktivovať",
        deactivate: "Deaktivovať",
        processing: "Spracovanie",
      },
      deleteMessage: {
        deleteMessage: "Ste si istý, že si chcete vymazať ",
        reservation: "túto rezerváciu",
        group: "túto skupinu",
        office: "túto kanceláriu",
        user: "tohto používateľa",
        role: "túto rolu",
        team: "tento tím",
        parking: "toto parkovacie právo",
        assign: "toto parkovacie miesto",
        confirmDelete: "Potvrďte vymazanie",
        confirmSubtext:
          "Vaše akcie budú mať za následok vymazanie všetkých týchto záznamov.",
      },
      config: {
        information: "Informácie o spoločnosti",
        company: "Firemná farba",
        days: "dní",
        booking: "Pravidlá rezervácie",
        deskRule: "Ako dlho vopred môžu používatelia rezervovať stôl?",
        parkingRule:
          "Ako dlho vopred môžu používatelia rezervovať parkovacie miesto?",
        color: "farbu",
        logo: "Logo",
        removeLogo: "Vymazať logo",
        resetColor: "Resetnuť farbu",
      },
      adminRole: {
        role: "Rola",
        addRole: "Pridať rolu",
        application: "Aplikačné roly",
        adminRole: "Administrátor - môže prideľovať roly iným používateľom",
        permissionManager:
          "Správca - môže upravovať a vytvárať pôdorysy, vytvárať kancelárie a stoly, má prístup k reportom",
      },
      period: {
        morning: "len dopoludnie (do 12:00)",
        afternoon: "len popoludnie (od 12:30)",
        halfDay: "pól dňa",
        oneDay: "celý deň",
        twoDays: "2 pracovné dni",
        threeDays: "3 pracovné dni",
        fourDays: "4 pracovné dni",
        fiveDays: "5 pracovné dni",
      },
      management: {
        createGroup: "Vytvoriť Skupinu",
        allUsers: "Všetci používatelia",
        groupName: "Názov skupiny",
        groups: "Skupiny",
        offices: "Kancelárie",
        assignToGroup: "Priradiť používateľa do skupiny",
        users: "Používatelia",
        selectUser: "Vybrať používateľa",
        selectGroup: "Vybrať skupinu",
        selectOffice: "Vybrať kanceláriu",
        officeAccess: "Kancelárske práva",
        addGroupToOffice: "Pridať skupinu ku kancelárií",
        addOfficePerm: "Pridať kancelárske práva",
        addOfficeAcc: "Pridať prístup do kancelárie",
        permissions: "Oprávnenia",
        permission: "Oprávnenie",
        addPermission: "Pridať oprávnenie",
        selectPermission: "Vybrať oprávnenie",
        addPermissionToGroup: "Pridať oprávnenie do skupiny",
        addUser: "Pridať používateľa",
        addGroup: "Pridať novú skupinu",
        editGroup: "Upraviť skupinu",
        addParkPerm: "Pridať parkovacie práva",
        userWithPermission: "Používatelia s parkovacím právami",
      },
      floorplan: {
        floorplans: "Pôdorysy",
        deskFloorplans: "Pôdorysy kancelárií",
        parkingFloorplans: "Parkovacie Pôdorysy",
        create: "Vytvoriť pôdorys",
        edit: "Upraviť pôdorys",
        office: "Pridať Kanceláriu",
        desk: "Pridať Stôl",
        officesDesks: "Kancelárie & Stoly",
        upload: "Nahrať obrázok",
        uploaded: "Súbor nahratý",
        reservedBy: "Zarezervoval",
        createOffice: "Vytvoriť Kanceláriu",
        renameOffice: "Premenovať Kanceláriu",
        editOffice: "Upraviť Kanceláriu",
        editDesk: "Upraviť Stôl",
        addParking: "Pridať Parkovacie Miesto",
        createParking: "Vytvoriť Parkovacie Miesto",
        createDesk: "Vytvoriť stoly",
        noFloorplan: "Táto lokalita nemá pridelený pôdorys",
        editDeskRadius: "Zmeniť velkosť stolov",
      },
      home: {
        upcoming: "Nadchádzajúce rezervácie",
        addDeskReservation: "Rezervovať stôl",
        addParkReservation: "Rezervovať parkovacie miesto",
        in: "Rezervácia v",
      },
      permanent: {
        assignedSpots: "Používatelia s priradenými miestami",
        assign: "Priradiť miesto",
      },
      successMessages: {
        logout: "Boli ste úspešne odhlásení.",
        delete: "Používateľ bol úspešne odstránený.",
        save: "Poznávacia značka používateľa bola úspešne aktualizovaná.",
        add: "Používateľ bol úspešne pridaný.",
        saved: "Úspešne uložené.",
      },
      errorMessages: {
        message: "Nastala neočakávaná chyba.",
      },
      report: {
        missing:
          "Report sa nenašiel, prosím kontaktujte administrátora systému",
      },
      emptyMessages: {
        login: "Kliknite sem a prihláste sa",
        noOffice: "Nenašli sa žiadne kancelárie",
        noPermissions: "Nenašli sa žiadne oprávnenia",
        noDesk: "Nenašli sa žiadne stoly",
        noParkingSpots: "Nenašli sa žiadne parkovacie miesta",
        makeReservation:
          "Ak chcete vytvoriť rezerváciu, najprv vyberte miesto.",
        makeReservationAssign:
          "Ak chcete vytvoriť rezerváciu, najprv vyberte miesto a užívateľa.",
        makeParkReservation:
          "Ak chcete rezervovať parkovacie miesto, najprv vyberte používateľa a miesto.",
        upcomingRes: "Zdá sa, že nemáte žiadne nadchádzajúce rezervácie.",
        notFound: "Poloha sa nenašla.",
        selectUser: "Najprv vyberte používateľa.",
        selectLocation: "Prosím, vyberte lokáciu.",
        viewDeskReservation:
          "Ak chcete zobraziť rezervácie stolov, najprv vyberte polohu alebo používateľa.",
        viewParkingReservation:
          "Ak chcete zobraziť rezervácie parkovacích miest, najprv vyberte polohu alebo používateľa.",
        noUsers: "Zdá sa, že nemáte žiadnych používateľov.",
        noGroups: "Zdá sa, že nemáte pridané žiadne skupiny.",
        noTeams: "Zdá sa, že nemáte pridané žiadne tímy.",
        noTeam: "Tím sa nenašiel",
        noOffices: "Zdá sa, že nemáte žiadne kancelárie.",
        noLocations: "Zdá sa, že nemáte žiadne lokácie.",
        noUsersWithPermission:
          "Zdá sa, že nemáte žiadnych používateľov s parkovacími právami.",
        onDateWholeDay: "na vybranom dátume na celý deň",
        userHasReservation: " už má rezerváciu v tomto časovom rozsahu.",
        alreadyHasReservation: "V tomto časovom rozmedzí už máte rezerváciu.",
        noFloorplan: "Táto lokácia nemá pôdorys.",
        permanentParking:
          "Zdá sa, že nemáte žiadnych používateľov s priradenými parkovacími miestami. ",
        noLocationPermission:
          "Nemáte žiadne lokácie, prosím kontaktujte svojho systémového administrátora.",
      },
      tooltip: {
        office:
          "Po pomenovaní kancelárie kliknite ľavým tlačidlom myši a potiahnite. V prípade potreby môžete svoju kanceláriu premiestniť alebo zmeniť veľkosť. Keď ste spokojní s vašou kanceláriou, kliknite na Hotovo.",
        desk: "Kliknite a umiestnite stôl na požadovanú pozíciu.",
      },
      deskManagement: {
        deskPermission: `Umožňuje vám ovládať interakciu používateľa s týmto systémom. Ako prvé
            musíte vytvoriť skupinu, potom môžete pridať používateľov do skupiny.
            Po druhé, musíte konkrétnej skupine prideliť kancelársky prístup.
            Skupiny môžu mať prístup do viacerých kancelárií.`,
        assignDesk: `Je to funkcia, ktorá vám umožňuje priradiť pracovný stôl konkrétnemu používateľovi.
            Stačí si vybrať používateľa, ktorému chcete priradiť pracovný stôl, lokáciu,
            obdobie a dátum. Potom môžete kliknúť na priradiť stôl, tým ste úspešne pridelili stôl.`,
        manageFloorplans: `Ak ešte nemáte žiadne pôdorysy, musíte kliknúť na vytvoriť
            pôdorys tlačítko. Zobrazí sa kontextové okno s výzvou na zadanie mena a
            obrázoku. Po dokončení vytvárania pôdorysu budete
            presmerovaný na obrazovku, kde potrebujete definovať sekcie vaších kancelárií. 
            Potom musíte do kancelárie pridať stoly. Budete mať zoznam
            všetkých vašich kancelárií a stolov na pravej strane obrazovky. Odkiaľ
            môžete kedykoľvek upravovať svoje stoly alebo kancelárie.`,
        viewOrRemove: `Na tejto stránke môžete zobraziť a odstrániť rezervácie pre konkrétneho používateľa.`,
      },
      parkingManagement: {
        parkPermission: `Na tejto stránke nájdete zoznam všetkých používateľov, ktorí majú parkovanie
            práva. V tomto zozname nájdete všetkých používateľov ŠPZ
            čísla. Jeden užívateľ môže mať zadaných viacero ŠPZ.
            Na tejto stránke môžete tiež pridať práva na parkovanie.`,
        manageReservation: `Táto stránka je určená na správu parkovacích rezervácií. Môžete
            vybrať používateľa, pre ktorého chcete spravovať parkovacie rezervácie,
            miesto a obdobie. Zobrazí sa kalendár. V tomto kalendári uvidíte,
            či má vybraný používateľ rezervované parkovanie alebo nie.`,
        manageParkingFloorplans: `Ak ešte nemáte pôdorys, musíte
            kliknite na tlačidlo vytvoriť pôdorys. Objaví sa vám okno so žiadosťou o a
            meno a obrázok. Po dokončení vytvárania pôdorysu budete
            presmerovaný na obrazovku so zoznamom všetkých vašich pôdorysov. Po otvorení pôdorysu
            si môžete začat vytvárať parkovacie miesta.`,
        permanentParking: `Na tejto stránke uvidíte všetkých používateľov, ktorí majú pridelené parkovacie
            miesto. Tu môžete priradiť nové miesto alebo upraviť existujúce
            záznam.`,
        viewOrRemove: `Na tejto stránke môžete zobraziť a odstrániť rezervácie pre konkrétneho používateľa.`,
      },
      teams: {
        teams: "Tímy",
        team: "Tím",
        createTeam: "Vytvoriť tím",
        addTeam: "Pridať nový tím",
        teamName: "Meno tímu",
        addMember: "Pridať člena tímu",
        editTeam: "Upraviť tím",
        role: "Rola",
        teamLeader: "Vedúci tímu",
        teamMember: "Člen tímu",
        backToTeams: "Back to teams",
        noUsers: "Zdá sa, že tento tím nemá žiadnych používateľov.",
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "sk",
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
