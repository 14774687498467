import { useTranslation } from "react-i18next";
import { DefaultButton, Dropdown, Modal, Stack } from "@fluentui/react";
import { useEffect, useState } from "react";
import { defaultModalInnerStyle, defaultStackStyle } from "../../helpers/data";
import UserComboBox from "../General/Inputs/UserComboBox";

const AddMemberToTeamModal = ({ onDismiss, onSubmit, isOpen }) => {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(0);
  const { t } = useTranslation();

  const handleSubmit = () => {
    onDismiss();
    onSubmit(user, role);
  };

  useEffect(() => {
    setUser(null);
    setRole(0);
  }, [isOpen]);

  const dropdownOptions = [
    {
      key: 1,
      text: t("teams.teamLeader"),
    },
    {
      key: 0,
      text: t("teams.teamMember"),
    },
  ];

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Stack className={defaultModalInnerStyle}>
        <h4 style={{ marginTop: 10, marginBottom: 5 }}>
          {t("teams.addMember")}
        </h4>
        <Stack verticalFill grow>
          <UserComboBox
            handleSelect={(users) => {
              setUser(users ? users[0] : null);
            }}
          />
          <Dropdown
            label={t("teams.role")}
            options={dropdownOptions}
            selectedKey={role}
            onChange={(e, item) => setRole(item.key)}
          />
        </Stack>
        <Stack
          horizontal
          horizontalAlign="end"
          tokens={defaultStackStyle}
          style={{ marginTop: 20 }}
        >
          <DefaultButton primary onClick={handleSubmit} disabled={!user}>
            {t("management.addUser")}
          </DefaultButton>
          <DefaultButton onClick={onDismiss}>{t("common.close")}</DefaultButton>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default AddMemberToTeamModal;
