import { IconButton, MessageBar, Stack } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import Calendar from "../components/General/Calendar/Calendar";
import DeskReservation from "../components/General/DeskReservation";
import Header from "../components/General/Header";
import EmptyMessage from "../components/General/Messages/EmptyMessage";

const MobileReservationPage = ({
  deskReservation,
  selectedLocation,
  selectedPeriod,
  forUser,
  date,
  items,
  handleSelection,
  handleMobilePopup,
  handleReservation,
  handleReservationError,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Stack horizontal horizontalAlign="space-between">
        <Header
          text={t(
            deskReservation
              ? "sidebar.deskReservation"
              : "sidebar.parkingReservation"
          )}
        />
        <IconButton
          iconProps={{
            iconName: "Filter",
            styles: { root: { color: "black" } },
          }}
          onClick={handleMobilePopup}
        />
      </Stack>
      {deskReservation ? (
        <MessageBar>
          {t("home.in")} {selectedLocation ? selectedLocation.text : ""}{" "}
          {t("common.on")} {new Date(date).toLocaleDateString(i18n.language)}{" "}
          {t("common.for")} {t("period." + selectedPeriod.key)}.
        </MessageBar>
      ) : (
        <MessageBar>
          {t("home.in")} {selectedLocation?.text} {t("common.for")}{" "}
          {t("period." + selectedPeriod.key)}.
        </MessageBar>
      )}
      <Stack verticalFill>
        {selectedLocation ? (
          <>
            {deskReservation ? (
              <DeskReservation
                startDate={date}
                mobile
                period={selectedPeriod}
                location={selectedLocation}
                handleSelection={handleSelection}
                handleSubmit={handleReservation}
                forUser={forUser}
              />
            ) : (
              <Calendar
                yearAndMonth={date}
                onYearAndMonthChange={(yearAndMonth) => {
                  handleSelection(yearAndMonth);
                }}
                locationId={selectedLocation.key}
                onReservation={handleReservation}
                forUser={forUser}
                items={items}
                period={selectedPeriod}
                onError={handleReservationError}
              />
            )}
          </>
        ) : (
          <EmptyMessage
            text={t("emptyMessages.selectLocation")}
            icon="Nav2DMapView"
          />
        )}
      </Stack>
    </>
  );
};

export default MobileReservationPage;
