import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  DialogFooter,
  FontIcon,
  IconButton,
  Modal,
  PrimaryButton,
  SearchBox,
  SelectionMode,
  Stack,
  useTheme,
} from "@fluentui/react";
import {
  getFocusStyle,
  getTheme,
  mergeStyleSets,
} from "@fluentui/react/lib/Styling";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ActionBarComponent from "../components/General/ActionBarComponent/ActionBarComponent";
import Header from "../components/General/Header";
import BasicDropdown from "../components/General/Inputs/BasicDropdown";
import UserComboBox from "../components/General/Inputs/UserComboBox";
import { isLoading } from "../components/General/Loading";
import CustomMessageBar from "../components/General/Messages/CustomMessageBar";
import EmptyMessage from "../components/General/Messages/EmptyMessage";
import ConfirmationDialog from "../components/Modals/ConfirmationDialog";
import {
  assignedSpotColumns,
  defaultModalInnerStyle,
  defaultStackStyle,
} from "../helpers/data";
import { GetLocations, GetParkingSpots } from "../services/LocationService";
import {
  AddAssignedSpots,
  DeleteAssignedParkingSpots,
  GetAssignedParkingSpots,
} from "../services/ParkingService";
import { useTitle } from "../helpers/useTitle";

const theme = getTheme();
const { palette, semanticColors, fonts } = theme;

const classNames = mergeStyleSets({
  itemCell: [
    getFocusStyle(theme, { inset: -1 }),
    {
      minHeight: 15,
      padding: 5,
      boxSizing: "border-box",
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      display: "flex",
    },
  ],
  itemContent: {
    marginLeft: 5,
    overflow: "hidden",
    flexGrow: 1,
  },
  itemName: [
    fonts.medium,
    {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginTop: "5px",
    },
  ],
  itemIndex: {
    fontSize: fonts.smallPlus.fontSize,
    color: palette.neutralTertiary,
    marginBottom: 10,
  },
  chevron: {
    alignSelf: "center",
    marginLeft: 10,
    color: palette.neutralTertiary,
    fontSize: fonts.large.fontSize,
    flexShrink: 0,
  },
});

const PermanentParkingPage = () => {
  const [assignedSpots, setAssignedSpots] = useState([]);
  const [parkingSpots, setParkingSpots] = useState([]);
  const [newUser, setNewUser] = useState([]);
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState({});
  const [spot, setSpot] = useState({});
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [disableAssign, setDisableAssign] = useState(false);
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  useTitle("Permanent parking");

  const iconColor = theme?.palette?.themePrimary;

  const handleSelect = (users) => {
    setNewUser(users.length > 0 ? users[0] : null);
  };

  const getAssignedSpots = async () => {
    setLoading(true);
    let tempAssignedSpots = await GetAssignedParkingSpots();
    setAssignedSpots(tempAssignedSpots);
    setLoading(false);
  };

  const getParkingSpots = async () => {
    if (location.key) {
      let tempParkingSpots = await GetParkingSpots(location.key);
      let tempArray = [];
      for (let parkingSpot of tempParkingSpots) {
        tempArray.push({ text: parkingSpot.spotNumber, key: parkingSpot.id });
      }
      setParkingSpots(tempArray);
    }
  };

  const getLocations = async () => {
    let tempLocations = await GetLocations();
    let tempArray = [];
    for (let location of tempLocations.filter((l) => l.parkingAllowed)) {
      tempArray.push({ text: location.name, key: location.id });
    }
    setLocations(tempArray);
  };

  useEffect(() => {
    getAssignedSpots();
    getLocations();
  }, []);

  useEffect(() => {
    getParkingSpots();
  }, [location]);

  const insertAssignedSpots = async () => {
    setLoading(true);
    setLocation({});
    setSpot({});
    let assignedParkingSpot = await AddAssignedSpots({
      UserID: newUser.key,
      ParkingSpotID: spot.key,
    });
    if (assignedParkingSpot.response) {
      setErrorMessage(assignedParkingSpot.response.data);
      setShowMessage(true);
    }
    setReset(true);
    setReset(false);
    setDisableAssign(false);
    getAssignedSpots();
  };

  const deleteAssignedSpot = async (item) => {
    setOpenDeleteModal(false);
    await DeleteAssignedParkingSpots(item.id);
    setReset(true);
    setReset(false);
    getAssignedSpots();
  };

  const handleLocationsSelect = (item) => {
    setLocation(item);
    setSpot({});
  };

  const handleSpotSelect = (item) => {
    setSpot(item);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const filteredItems = assignedSpots.filter((item) => {
    const lowerCaseQuery = searchQuery?.toLowerCase();

    return (
      !searchQuery ||
      item.parkingSpot?.location?.name.toLowerCase().includes(lowerCaseQuery) ||
      item.parkingSpot?.spotNumber.toLowerCase().includes(lowerCaseQuery) ||
      item.user?.displayName.toLowerCase().includes(lowerCaseQuery)
    );
  });

  const renderAssignedSpots = (item, index, column) => {
    switch (column.key) {
      case "name":
        return (
          <div className={classNames.itemName}>
            {item.user?.displayName ?? item.userId}
          </div>
        );
      case "location":
        return (
          <div className={classNames.itemName}>
            {item.parkingSpot?.location?.name}
          </div>
        );
      case "spotNum":
        return (
          <div className={classNames.itemName}>
            {item.parkingSpot?.spotNumber}
          </div>
        );
      case "delete":
        return (
          <FontIcon
            style={{ color: iconColor }}
            iconName="Trash"
            className={"trash"}
            onClick={() => {
              setSelectedItem(item);
              setOpenDeleteModal(true);
            }}
          />
        );
      default:
        return String(item[column.fieldName]);
    }
  };

  return (
    <>
      <ActionBarComponent
        id="action-bar"
        actions={[
          {
            key: "assignSpot",
            text: t("permanent.assign"),
            icon: "",
            primary: true,
            action: openModal,
            disabled: false,
          },
        ]}
      />
      {showMessage && (
        <CustomMessageBar
          visible={setShowMessage}
          message={errorMessage}
          error
          setShowSuccessMessage={() => setShowMessage(false)}
        />
      )}
      <ConfirmationDialog
        modalText={t("common.delete")}
        isOpen={openDeleteModal}
        onDismiss={() => setOpenDeleteModal(false)}
        onDelete={() => deleteAssignedSpot(selectedItem)}
        text={"deleteMessage.assign"}
      />
      <Modal
        isOpen={isModalOpen}
        onDismiss={() => {
          setIsModalOpen(false);
          setDisableAssign(false);
        }}
      >
        <Stack tokens={defaultStackStyle} className={defaultModalInnerStyle}>
          <Stack horizontal horizontalAlign="space-between">
            <h5>{t("permanent.assign")}</h5>
            <IconButton
              iconProps={{ iconName: "Cancel" }}
              onClick={() => setIsModalOpen(false)}
              styles={{ root: { color: "black" } }}
            />
          </Stack>
          <UserComboBox
            disabledItems={[]}
            handleSelect={handleSelect}
            reset={reset}
          />
          <BasicDropdown
            options={locations}
            handleChange={handleLocationsSelect}
            label={t("common.location")}
            placeholder={t("common.selectLocation")}
          />
          <BasicDropdown
            label={t("common.parkingSpot")}
            handleChange={handleSpotSelect}
            options={parkingSpots}
            placeholder={t("common.selectSpot")}
          />
          <DialogFooter>
            <Stack tokens={defaultStackStyle} horizontal verticalAlign="end">
              <PrimaryButton
                text={t("permanent.assign")}
                disabled={!newUser || !spot.key || !location.key}
                onClick={() => {
                  setDisableAssign(true);
                  insertAssignedSpots();
                  setIsModalOpen(false);
                }}
              />
              <DefaultButton
                text={t("common.close")}
                onClick={() => {
                  setIsModalOpen(false);
                  setLocation({});
                  setSpot({});
                }}
              />
            </Stack>
          </DialogFooter>
        </Stack>
      </Modal>
      <Stack
        verticalFill
        className="page"
        style={{ height: "93vh", overflow: "auto" }}
      >
        <Stack horizontal horizontalAlign="space-between">
          <Header text={t("permanent.assignedSpots")} />
          <SearchBox
            underlined
            placeholder={t("common.search")}
            onChange={(e, newValue) => setSearchQuery(newValue)}
            onClear={() => setSearchQuery("")}
          />
        </Stack>
        {isLoading(
          loading,
          <>
            {filteredItems.length == 0 ? (
              <EmptyMessage
                text={t("emptyMessages.permanentParking")}
                icon="Car"
              />
            ) : (
              <DetailsList
                items={filteredItems}
                columns={assignedSpotColumns.map((oc) => {
                  return { ...oc, name: t(oc.name) };
                })}
                onRenderItemColumn={renderAssignedSpots}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.none}
              />
            )}
          </>
        )}
      </Stack>
    </>
  );
};

export default PermanentParkingPage;
