import {
  DefaultButton,
  DialogFooter,
  FontIcon,
  IconButton,
  Label,
  Modal,
  PrimaryButton,
  Stack,
  Text,
  useTheme,
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { sortByDate, sortDesks } from "../../helpers/Functions";
import {
  classNames,
  defaultModalInnerStyle,
  defaultModalStyle,
  defaultStackStyle,
  labelHeaderStyle,
} from "../../helpers/data";
import { isLoading } from "../General/Loading";

const DeleteModal = ({
  location,
  office,
  parkingSpot,
  loading,
  data,
  isOpen,
  onDismiss,
  onDelete,
  modalText,
  parking,
}) => {
  const [name, setName] = useState("");
  const [officesExpanded, setOfficesExpanded] = useState(false);
  const [desksExpanded, setDesksExpanded] = useState(false);
  const [parkingExpanded, setParkingExpanded] = useState(false);
  const [assignedParkingExpanded, setAssignedParkingExpanded] = useState(false);
  const [reservationsExpanded, setReservationsExpanded] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const { t, i18n } = useTranslation();
  const offices = data.offices;
  const hotDesks = data.hotDesks?.sort(sortDesks);
  const parkingSpots = data.parkingSpots;
  const assignedParking = data.assignedParkingSpots;
  const reservations = data.reservations?.sort(sortByDate);

  useEffect(() => {
    setOfficesExpanded(false);
    setDesksExpanded(false);
    setReservationsExpanded(false);
    setDisableButton(false);
  }, [data]);

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      containerClassName={defaultModalStyle}
    >
      <Stack
        style={{
          minHeight: "70vh",
          maxHeight: "70vh",
        }}
        tokens={defaultStackStyle}
        className={defaultModalInnerStyle}
      >
        {isLoading(
          loading,
          <>
            <Label className={labelHeaderStyle}>
              {modalText} {t("common.of")} {location?.name}
            </Label>
            <Text
              style={{ fontSize: 15, marginTop: "12px", paddingLeft: "5px" }}
            >
              {t("deleteMessage.confirmSubtext")}
            </Text>
            <DeleteItems
              location={location}
              items={offices}
              text={t("management.offices")}
              onClick={() => {
                setOfficesExpanded(!officesExpanded);
                setDesksExpanded(false);
                setParkingExpanded(false);
                setReservationsExpanded(false);
              }}
              expanded={officesExpanded}
              itemComponent={OfficeItem}
              t={t}
            />
            <DeleteItems
              office={office}
              location={location}
              items={hotDesks}
              text={t("common.desks")}
              onClick={() => {
                setOfficesExpanded(false);
                setDesksExpanded(!desksExpanded);
                setParkingExpanded(false);
                setAssignedParkingExpanded(false);
                setReservationsExpanded(false);
              }}
              expanded={desksExpanded}
              itemComponent={DeskItem}
              t={t}
            />
            <DeleteItems
              location={location}
              items={parkingSpots}
              text={t("common.parkingSpots")}
              onClick={() => {
                setOfficesExpanded(false);
                setDesksExpanded(false);
                setParkingExpanded(!parkingExpanded);
                setAssignedParkingExpanded(false);
                setReservationsExpanded(false);
              }}
              expanded={parkingExpanded}
              itemComponent={ParkingItem}
              t={t}
            />
            <DeleteItems
              location={location}
              parkingSpot={parkingSpot}
              items={assignedParking}
              text={t("common.assignedSpots")}
              onClick={() => {
                setOfficesExpanded(false);
                setDesksExpanded(false);
                setParkingExpanded(false);
                setAssignedParkingExpanded(!assignedParkingExpanded);
                setReservationsExpanded(false);
              }}
              expanded={assignedParkingExpanded}
              itemComponent={AssignedParkingItem}
              t={t}
            />
            <DeleteItems
              office={office}
              parkingSpot={parkingSpot}
              location={location}
              items={reservations}
              text={t("common.reservations")}
              onClick={() => {
                setOfficesExpanded(false);
                setDesksExpanded(false);
                setParkingExpanded(false);
                setAssignedParkingExpanded(false);
                setReservationsExpanded(!reservationsExpanded);
              }}
              expanded={reservationsExpanded}
              parking={!!parking}
              itemComponent={ReservationItem}
              t={t}
            />
          </>
        )}
      </Stack>
      <Stack style={{ margin: "5px" }}>
        {!loading && (
          <>
            <DialogFooter>
              <PrimaryButton
                disabled={disableButton}
                onClick={() => {
                  setDisableButton(true);
                  onDelete(name);
                }}
              >
                {t("common.deleteAll")}
              </PrimaryButton>
              <DefaultButton onClick={onDismiss}>
                {t("common.cancel")}
              </DefaultButton>
            </DialogFooter>
          </>
        )}
      </Stack>
    </Modal>
  );
};

const DeleteItems = ({
  location,
  office,
  parkingSpot,
  items,
  parking,
  onClick,
  expanded,
  text,
  itemComponent,
  t,
}) => {
  if (!items) {
    return "";
  }

  return (
    <>
      <Stack horizontal>
        <IconButton
          iconProps={{
            iconName: expanded ? "ChevronDown" : "ChevronRight",
            styles: { root: { fontSize: 14 } },
          }}
          style={{ width: "23px", height: "23px", marginRight: "2px" }}
          onClick={() => onClick()}
        />
        <Text
          onClick={() => onClick()}
          style={{ cursor: "pointer", fontSize: 16 }}
        >
          {text}
        </Text>
      </Stack>
      <Stack
        tokens={defaultStackStyle}
        style={{ height: "100%", overflow: "auto" }}
      >
        {items?.length !== 0
          ? expanded &&
            items.map((o, i) =>
              itemComponent({
                key: i,
                item: o,
                parking: parking,
                parkingSpot: parkingSpot,
                office: office,
                location: location,
              })
            )
          : expanded && (
              <Text style={{ marginLeft: "35px" }}>
                {t("common.no")} {text}
              </Text>
            )}
      </Stack>
    </>
  );
};

const OfficeItem = ({ location, item }) => {
  const theme = useTheme();
  const iconColor = theme?.palette?.themePrimary;

  return (
    <div className={classNames.deleteStack}>
      <Stack horizontal verticalAlign={"center"} tokens={defaultStackStyle}>
        <FontIcon
          iconName={"Nav2DMapView"}
          className={"icon"}
          style={{ color: iconColor }}
        />
        <Stack>
          <Text style={{ fontSize: 16 }}>{item?.name}</Text>
          <Text style={{ fontSize: 13, color: "gray" }}>{location.name}</Text>
        </Stack>
      </Stack>
    </div>
  );
};

const DeskItem = ({ location, office, item }) => {
  const theme = useTheme();
  const iconColor = theme?.palette?.themePrimary;
  const { t, i18n } = useTranslation();

  return (
    <div className={classNames.deleteStack}>
      <Stack horizontal verticalAlign={"center"} tokens={defaultStackStyle}>
        <FontIcon
          iconName={"System"}
          className={"icon"}
          style={{ color: iconColor }}
        />
        <Stack>
          <Text style={{ fontSize: 16 }}>
            {t("common.desk")} {item.deskNumber}
          </Text>
          <Text style={{ fontSize: 13, color: "gray" }}>
            {location.name} - {item.office ? item.office.name : office?.name}
          </Text>
        </Stack>
      </Stack>
    </div>
  );
};

const ParkingItem = ({ location, item }) => {
  const theme = useTheme();
  const iconColor = theme?.palette?.themePrimary;
  const { t, i18n } = useTranslation();

  return (
    <div className={classNames.deleteStack}>
      <Stack horizontal verticalAlign={"center"} tokens={defaultStackStyle}>
        <FontIcon
          iconName={"Car"}
          className={"icon"}
          style={{ color: iconColor }}
        />
        <Stack>
          <Text style={{ fontSize: 16 }}>
            {t("common.parkingSpot")}{" "}
            {item.parkingSpot ? item.parkingSpot.spotNumber : item.spotNumber}
          </Text>
          <Text style={{ fontSize: 13, color: "gray" }}>{location.name}</Text>
        </Stack>
      </Stack>
    </div>
  );
};

const AssignedParkingItem = ({ location, parkingSpot, item }) => {
  const theme = useTheme();
  const iconColor = theme?.palette?.themePrimary;
  const { t, i18n } = useTranslation();

  return (
    <div className={classNames.deleteStack}>
      <Stack horizontal verticalAlign={"center"} tokens={defaultStackStyle}>
        <FontIcon
          iconName={"Car"}
          className={"icon"}
          style={{ color: iconColor }}
        />
        <Stack>
          <Text style={{ fontSize: 16 }}>
            {item.user?.displayName} -{" "}
            {item.parkingSpot
              ? item.parkingSpot.spotNumber
              : parkingSpot?.spotNumber}
          </Text>
          <Text style={{ fontSize: 13, color: "gray" }}>{location.name}</Text>
        </Stack>
      </Stack>
    </div>
  );
};

const ReservationItem = ({ location, parking, parkingSpot, office, item }) => {
  const theme = useTheme();
  const iconColor = theme?.palette?.themePrimary;
  const { t, i18n } = useTranslation();
  return (
    <div className={classNames.deleteStack}>
      <Stack horizontal verticalAlign={"center"} tokens={defaultStackStyle}>
        <FontIcon
          iconName={parking ? "Car" : "System"}
          className={"icon"}
          style={{ color: iconColor }}
        />
        <Stack>
          <Text style={{ fontSize: 16 }}>
            {item.user?.displayName} -{" "}
            {new Date(item.startDate).toLocaleDateString(i18n.language)}
          </Text>
          {!parking ? (
            <Text style={{ fontSize: 13, color: "gray" }}>
              ({t("home.in")} {location.name} -{" "}
              {item.hotDesk?.office ? item.hotDesk?.office.name : office?.name}{" "}
              - {t("common.desk")} {item.hotDesk.deskNumber})
            </Text>
          ) : (
            <Text style={{ fontSize: 13, color: "gray" }}>
              {t("home.in")} {location.name} - {t("common.parkingSpot")}{" "}
              {item.parkingSpot
                ? item.parkingSpot.spotNumber
                : parkingSpot?.spotNumber}
            </Text>
          )}
        </Stack>
      </Stack>
    </div>
  );
};
export default DeleteModal;
