import { MessageBar, MessageBarType, Stack } from "@fluentui/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import DeskReservation from "../components/General/DeskReservation";
import Header from "../components/General/Header";
import BasicCalendar from "../components/General/Inputs/BasicCalendar";
import BasicDropdown from "../components/General/Inputs/BasicDropdown";
import { IsMobileContext } from "../components/General/Layout";
import MobileFilter from "../components/General/MobileFilter/MobileFilter";
import "../components/General/MobileFilter/MobileFilter.css";
import { AddDeskReservation, GetLocations } from "../services/LocationService";
import "../styles/custom.css";
import MobileReservationPage from "./MobileReservationPage";
import { useTitle } from "../helpers/useTitle";

function NewDeskReservation({ forUser, isAdmin }) {
  const history = useHistory();
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();
  const [selectedDesk, setSelectedDesk] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [period, setPeriod] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [lastTimeout, setLastTimeout] = useState(null);
  const [expanded, setExpanded] = useState(true);
  const isMobile = useContext(IsMobileContext);
  const { t, i18n } = useTranslation();
  useTitle("New desk reservation");

  let periods = [
    { key: "morning", text: t("period.morning") },
    { key: "afternoon", text: t("period.afternoon") },
    { key: "oneDay", text: t("period.oneDay") },
    { key: "twoDays", text: t("period.twoDays") },
    { key: "threeDays", text: t("period.threeDays") },
    { key: "fourDays", text: t("period.fourDays") },
    { key: "fiveDays", text: t("period.fiveDays") },
  ];

  if (isAdmin) periods.push({ key: "permanent", text: t("common.permanent") });

  const getLocations = async () => {
    let locations = await GetLocations();
    let tempArray = locations
      .filter((l) => l.hotdeskAllowed)
      .map((location) => {
        return { text: location.name, key: location.id, data: location };
      });
    setLocations(tempArray);
  };

  const handleMobilePopup = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    getLocations();
  }, []);

  const handleSubmit = (desk) => {
    AddDeskReservation(selectedLocation.key, {
      HotDeskId: desk.id,
      UserID: forUser ? forUser.key : null,
      StartDate: fromDate,
      EndDate: toDate,
      Period: period.key,
    })
      .then(() => history.push("/"))
      .catch(handleReservationError);
  };

  const handleReservationError = useCallback(
    (error) => {
      setErrorMessage(error.response.data);
      if (lastTimeout) {
        clearTimeout(lastTimeout);
      }
      setLastTimeout(
        setTimeout(() => {
          setErrorMessage("");
        }, 5000)
      );
    },
    [lastTimeout]
  );

  const closeMessage = useCallback(() => {
    clearTimeout(lastTimeout);
    setErrorMessage("");
  }, [lastTimeout]);

  const formatDate = (date) => {
    let dates = fromDate.split("-");
    return dates[2] + "." + dates[1] + "." + dates[0];
  };

  const containerStackToken = { childrenGap: 25 };

  return (
    <>
      {!isMobile ? (
        <Stack verticalFill className="page">
          <Header text={t("sidebar.deskReservation")} />
          {errorMessage && (
            <MessageBar
              messageBarType={MessageBarType.error}
              dismissButtonAriaLabel="Close"
              onDismiss={closeMessage}
            >
              {errorMessage}
            </MessageBar>
          )}
          {locations.length === 0 && (
            <MessageBar
              messageBarType={MessageBarType.warning}
              isMultiline={true}
            >
              {t("emptyMessages.noLocationPermission")}
            </MessageBar>
          )}
          <Stack horizontal verticalAlign="end" tokens={containerStackToken}>
            <BasicDropdown
              options={locations}
              handleChange={(item) => {
                setSelectedLocation(item);
              }}
              label={t("common.location")}
              placeholder={t("common.selectLocation")}
              disabled={locations.length === 0}
            />
            <BasicDropdown
              label={t("common.period")}
              options={periods}
              handleChange={setPeriod}
              placeholder={t("common.selectPeriod")}
              defaultItem={{ key: "oneDay", text: "all day" }}
              disabled={locations.length === 0}
            />
            <BasicCalendar
              text="From Date"
              handleSelect={setFromDate}
              disabled={locations.length === 0}
            />
          </Stack>
          <Stack verticalFill style={{ marginTop: 20 }}>
            <DeskReservation
              startDate={fromDate}
              period={period}
              location={selectedLocation}
              handleSelection={setSelectedDesk}
              handleSubmit={handleSubmit}
              forUser={forUser}
            />
          </Stack>
          <div style={{ float: "right", marginTop: 50 }}></div>
        </Stack>
      ) : (
        <Stack verticalFill className="page">
          <Stack
            verticalFill
            className={`mobile-popup-content ${expanded ? "expanded" : ""}`}
          >
            {expanded ? (
              <MobileFilter
                selectedLocation={selectedLocation}
                selectedPeriod={period}
                handleMobilePopup={handleMobilePopup}
                handleReset={() => {
                  setSelectedLocation(null);
                  setPeriod(null);
                  setFromDate(null);
                }}
                periods={periods}
                locations={locations}
                date={fromDate}
                setFromDate={(item) => setFromDate(item)}
                handlePeriodSelection={(item) => setPeriod(item)}
                handleLocationSelection={(item) => setSelectedLocation(item)}
              />
            ) : (
              <MobileReservationPage
                deskReservation
                selectedLocation={selectedLocation}
                selectedPeriod={period}
                handleMobilePopup={handleMobilePopup}
                handleReservationError={handleReservationError}
                handleSelection={(item) => setSelectedDesk(item)}
                date={fromDate}
                forUser={forUser}
                handleReservation={handleSubmit}
              />
            )}
          </Stack>
        </Stack>
      )}
    </>
  );
}

export default NewDeskReservation;
