import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
  ContextualMenu,
  Icon,
  Stack,
  StackItem,
  Text,
  useTheme,
} from "@fluentui/react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { defaultStackStyle } from "../../../helpers/data";
import { resources } from "../../../i18n";
import { GetConfigFile } from "../../../services/ConfigService";
import { GetCurrentUserRoles } from "../../../services/UserService";
import {
  IsMobileContext,
  PermissionContext,
  TeamLeaderContext,
} from "../Layout";
import "./Sidebar.css";

const Sidebar = () => {
  const isAuthenticated = useIsAuthenticated();
  const [expanded, setExpanded] = useState(true);
  const [roles, setRoles] = useState([]);
  const [showPersonMenu, setShowPersonMenu] = useState(false);
  const [showLangugeMenu, setShowLanguageMenu] = useState(false);
  const { instance, accounts, inProgress } = useMsal();
  const { pathname } = useLocation();
  const personRef = useRef(null);
  const languageRef = useRef(null);
  const [logo, setLogo] = useState();
  const isMobile = useContext(IsMobileContext);
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const iconColor = theme?.palette?.themePrimary;

  const isLeader = useContext(TeamLeaderContext);
  const version = process.env["REACT_APP_VERSION"];

  useEffect(() => {
    if (isMobile) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  }, [isMobile]);

  useEffect(() => {
    if (isMobile) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
    GetCurrentUserRoles().then((r) => setRoles(r));
    GetConfigFile("logo").then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: "image/png" })
      );
      setLogo(res.data?.size ? url : null);
    });
    i18n.changeLanguage(localStorage.getItem("language"));
  }, []);

  const personItems = [
    {
      key: "upn",
      text: accounts[0].username,
      disabled: true,
    },
    {
      key: "clearMemory",
      text: t("sidebar.clearMemory"),
      onClick: () => {
        localStorage.clear();
        sessionStorage.clear();
      },
    },
    {
      key: "signOut",
      text: t("sidebar.signOut"),
      onClick: () =>
        instance.logoutRedirect({ postLogoutRedirectUri: "/Logout" }),
    },
  ];

  const languageItems = Object.keys(resources).map((r) => {
    return {
      key: r,
      text: resources[r].languageName,
      onClick: () => {
        let language = i18n.changeLanguage(r);
        localStorage.setItem("language", r);
      },
    };
  });

  const hasRole = useCallback(
    (role) => {
      return roles.filter((r) => r.role.name == role).length > 0;
    },
    [roles]
  );

  const getUserMenu = [
    {
      key: "home",
      title: t("sidebar.home"),
      path: "/",
      iconName: "Home",
      subpages: [],
    },
    {
      key: "desk",
      title: t("sidebar.deskReservation"),
      path: "/deskreservation",
      iconName: "PC1",
      subpages: [],
    },
    {
      key: "parking",
      title: t("sidebar.parkingReservation"),
      path: "/parkingreservation",
      iconName: "Car",
      subpages: [],
    },
  ];

  const getAdminMenu = [
    {
      key: "applicationRoles",
      title: t("sidebar.roles"),
      path: "/applicationroles",
      iconName: "Group",
      subpages: [],
    },
  ];

  const getManageMenu = [
    {
      title: t("sidebar.teamManage"),
      iconName: "Group",
      path: "/teams",
      subpages: [],
    },
    {
      title: t("sidebar.permissions"),
      iconName: "PlayerSettings",
      path: "/permissions",
      subpages: [],
    },
    {
      title: t("sidebar.config"),
      path: "/config",
      iconName: "Settings",
      subpages: [],
    },
    {
      title: t("sidebar.deskManage"),
      path: "/deskmanagement",
      iconName: "PC1",
      leader: true,
      subpages: [
        {
          title: t("sidebar.viewRemove"),
          path: "/deskmanagement/view-remove",
          leader: true,
        },

        {
          title: t("sidebar.assignDesk"),
          path: "/deskmanagement/assigndesk",
          leader: true,
        },
        {
          title: t("sidebar.manage"),
          path: "/deskmanagement/floorplans",
        },
      ],
    },
    {
      title: t("sidebar.parkManage"),
      path: "/parkingmanagement",
      iconName: "Car",
      leader: true,
      subpages: [
        {
          title: t("sidebar.viewRemove"),
          path: "/parkingmanagement/view-remove",
          leader: true,
        },
        {
          title: t("sidebar.parkPermission"),
          path: "/parkingmanagement/parkingpermissions",
        },
        {
          title: t("sidebar.manageReservation"),
          path: "/parkingmanagement/manageparkingreservations",
          leader: true,
        },
        {
          title: t("sidebar.manageParkingFloorplans"),
          path: "/parkingmanagement/floorplans",
        },
        {
          title: t("sidebar.permanent"),
          path: "/parkingmanagement/permanentparking",
        },
      ],
    },
    {
      title: t("sidebar.report"),
      path: "/report",
      iconName: "PieSingle",
      subpages: [],
    },
  ];

  const getAccountMenu = {
    title: "Person",
    path: "/account",
    iconName: "Contact",
    subpages: [],
  };
  const getLanguageMenu = {
    title: "Language",
    path: "/language",
    iconName: "Globe",
    subpages: [],
  };

  const hidePersonMenu = () => {
    setShowPersonMenu(false);
  };

  const hideLanguageMenu = () => {
    setShowLanguageMenu(false);
  };

  const handleSidebarToggle = () => {
    setExpanded(!expanded);
  };

  const handleUnderline = (item) => {
    let end = pathname.split("/");
    if (isNaN(end[end.length - 1]) || end[1] === "") {
      return pathname === item.path
        ? { borderBottom: "1px solid " + iconColor }
        : {};
    } else {
      return pathname.slice(0, (end[end.length - 1].length + 1) * -1) ===
        item.path
        ? { borderBottom: "1px solid " + iconColor }
        : {};
    }
  };

  const MenuItemComponent = ({ item, level }) => {
    const hasChildren = item.subpages && item.subpages.length > 0;

    return (
      <>
        <Stack
          horizontalAlign={expanded ? "start" : "center"}
          style={{
            marginLeft: (level - 1) * 42 + 3,
            textDecoration: "none",
            ...handleUnderline(item),
          }}
          className={`menu-item`}
        >
          <NavLink style={{ textDecoration: "none" }} to={item.path}>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Icon style={{ color: iconColor }} iconName={item.iconName} />
              {expanded && (
                <Text variant="medium" className="menu-item-title">
                  {item.title}
                </Text>
              )}
            </Stack>
          </NavLink>
        </Stack>
        {expanded && hasChildren && (
          <Stack tokens={{ childrenGap: 15 }}>
            {item.subpages.map((subpage, subindex) => (
              <MenuItemComponent
                key={`level${level}i${subindex}`}
                item={subpage}
                level={level + 1}
              />
            ))}
          </Stack>
        )}
      </>
    );
  };

  const NavMain = () => {
    const parking = useContext(PermissionContext);
    return (
      <Stack tokens={{ childrenGap: 15 }}>
        {getUserMenu
          .filter((menuItem) => menuItem.key !== "parking" || parking)
          .map((menuItem, index) => (
            <MenuItemComponent item={menuItem} level={1} key={index} />
          ))}
      </Stack>
    );
  };

  const NavLeader = () => {
    return (
      <Stack tokens={defaultStackStyle}>
        {expanded ? (
          <Text variant="large" style={{ fontWeight: 500 }}>
            Manage
          </Text>
        ) : (
          ""
        )}
        {getManageMenu
          .filter((menuItem) =>
            menuItem.subpages.length > 1
              ? (menuItem.subpages = menuItem.subpages.filter(
                  (subPage) => subPage.leader,
                  menuItem.leader
                ))
              : menuItem.leader
          )
          .map((menuItem, index) => (
            <MenuItemComponent item={menuItem} level={1} key={index} />
          ))}
      </Stack>
    );
  };

  const NavManage = () => {
    return (
      <Stack tokens={{ childrenGap: 15 }}>
        {expanded ? (
          <Text variant="large" style={{ fontWeight: 500 }}>
            Manage
          </Text>
        ) : (
          ""
        )}
        {getManageMenu.map((menuItem, index) => (
          <MenuItemComponent item={menuItem} level={1} key={index} />
        ))}
      </Stack>
    );
  };

  const NavAdmin = () => {
    return (
      <Stack tokens={{ childrenGap: 15 }}>
        {expanded ? (
          <Text variant="large" style={{ fontWeight: 500 }}>
            Admin
          </Text>
        ) : (
          ""
        )}
        {getAdminMenu.map((menuItem, index) => (
          <MenuItemComponent item={menuItem} level={1} key={index} />
        ))}
      </Stack>
    );
  };

  const NavAccount = ({ item, level }) => {
    return (
      <Stack
        horizontalAlign={expanded ? "start" : "center"}
        style={{ marginLeft: (level - 1) * 42 + 3, textDecoration: "none" }}
        className="menu-item"
      >
        <div style={{ textDecoration: "none" }}>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Icon
              className="icon-color"
              style={{ width: 14, height: 21, color: iconColor }}
              iconName={item.iconName}
            />
            {expanded &&
              (isAuthenticated && accounts && accounts[0] ? (
                <Stack verticalFill verticalAlign="center">
                  <span
                    ref={personRef}
                    onClick={() => setShowPersonMenu(true)}
                    className="menu-item-title clickable"
                  >
                    {accounts[0].name}
                  </span>
                  <ContextualMenu
                    target={personRef}
                    items={personItems}
                    hidden={!showPersonMenu}
                    onItemClick={hidePersonMenu}
                    onDismiss={hidePersonMenu}
                  />
                </Stack>
              ) : (
                <Text variant="medium" className="menu-item-title">
                  Sign in
                </Text>
              ))}
          </Stack>
        </div>
      </Stack>
    );
  };

  const NavLanguage = ({ item, level }) => {
    return (
      <Stack
        horizontalAlign={expanded ? "start" : "center"}
        style={{ marginLeft: (level - 1) * 42 + 3, textDecoration: "none" }}
        className="menu-item"
      >
        <div style={{ textDecoration: "none" }}>
          <ContextualMenu
            target={languageRef}
            items={languageItems}
            hidden={!showLangugeMenu}
            onItemClick={hideLanguageMenu}
            onDismiss={hideLanguageMenu}
          />
          {expanded ? (
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Icon
                className="icon-color"
                style={{ width: 14, height: 21, color: iconColor }}
                iconName={item.iconName}
              />
              <span
                ref={languageRef}
                onClick={() => setShowLanguageMenu(true)}
                className="menu-item-title clickable"
              >
                {resources[i18n.language].languageName}
              </span>
            </Stack>
          ) : (
            <Stack>
              <span
                ref={languageRef}
                className="menu-item-title clickable"
                onClick={() => setShowLanguageMenu(true)}
              >
                {i18n.language.toUpperCase()}
              </span>
            </Stack>
          )}
        </div>
      </Stack>
    );
  };

  return (
    <Stack
      className={`sidebar ${expanded ? "expanded" : ""}`}
      horizontalAlign={expanded ? "start" : "center"}
    >
      <Stack horizontal={expanded} verticalAlign="center">
        <img
          draggable={false}
          src="./bookit_logo.png"
          alt="logo"
          className={expanded ? "image-logo" : "expanded-logo"}
        />
        {logo && (
          <img
            draggable={false}
            src={logo}
            alt="logo2"
            style={{ marginLeft: "15px" }}
            className={expanded ? "image-logo" : "expanded-logo"}
          />
        )}
      </Stack>
      <Icon
        style={{ marginLeft: 3, marginBottom: 10 }}
        className="clickable"
        iconName="GlobalNavButton"
        onClick={handleSidebarToggle}
      />
      <StackItem
        grow
        verticalAlign="start"
        style={{
          overflow: "auto",
          paddingRight: expanded ? (isMobile ? "20px" : "30px") : "0px",
        }}
      >
        <StackItem>
          <NavMain />
        </StackItem>
        {isLeader && !hasRole("Permission Manager") && (
          <>
            <div>
              <div className="section-divider" />
            </div>
            <NavLeader />
          </>
        )}
        {hasRole("Permission Manager") && (
          <>
            <div>
              <div className="section-divider" />
            </div>
            <NavManage />
          </>
        )}
        {hasRole("Admin") && (
          <>
            <div>
              <div className="section-divider" />
            </div>
            <NavAdmin />
          </>
        )}
      </StackItem>
      <Stack
        verticalAlign="end"
        tokens={{ childrenGap: 15 }}
        style={{ paddingTop: "15px" }}
      >
        <NavAccount item={getAccountMenu} level={1} />
        <NavLanguage item={getLanguageMenu} level={1} />
      </Stack>
      <div className="version">{version}</div>
    </Stack>
  );
};

export default Sidebar;
