import { DefaultButton, Stack } from "@fluentui/react";
import React from "react";
import "./ActionBarComponent.css";

const ActionBarComponent = (props) => {
    return (
        props.actions.length > 0 ? (
            <Stack className="action-bar" horizontal tokens={{ childrenGap: 15 }}>
                {props.actions.map((a, i) => (
                    <div key={i}>
                        <DefaultButton
                            text={a.text}
                            iconProps={a.icon ? { iconName: a.icon } : {}}
                            primary={a.primary}
                            onClick={a.action}
                            disabled={a.disabled}
                        />
                    </div>
                ))}
            </Stack>
        ) : <></>
    );
};

export default ActionBarComponent;