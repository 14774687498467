import {
  DefaultButton,
  DialogFooter,
  Label,
  Modal,
  PrimaryButton,
  Stack,
  TextField,
} from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  defaultModalInnerStyle,
  defaultModalStyle,
  defaultStackStyle,
  labelHeaderStyle,
} from "../../helpers/data";

const LocationModal = ({
  isOpen,
  onDismiss,
  onSubmit,
  parking,
  location,
  edit,
}) => {
  const [name, setName] = useState(location?.name ?? "");
  const [file, setFile] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const { t, i18n } = useTranslation();

  const handleSubmit = useCallback(async () => {
    setDisableButton(true);
    onDismiss();
    onSubmit(name, file);
    setDisableButton(false);
  }, [name, file]);

  const handleOnChange = (e) => {
    const files = e.target.files || [];
    if (files) {
      setFile(files[0]);
    }
  };

  useEffect(() => {
    setName(location?.name ?? "");
    setFile(null);
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      containerClassName={defaultModalStyle}
    >
      <Stack tokens={defaultStackStyle} className={defaultModalInnerStyle}>
        <Stack horizontal>
          <Label className={labelHeaderStyle}>
            {edit ? t("floorplan.edit") : t("floorplan.create")}
          </Label>
        </Stack>
        <TextField
          label={t("common.name")}
          required
          value={name}
          styles={{ root: { maxWidth: "200px" } }}
          onChange={(e, newValue) => setName(newValue ? newValue : "")}
        />
        <label className="file-upload" htmlFor="floorplanFile">
          {!file ? t("floorplan.upload") : t("floorplan.uploaded")}
        </label>
        <input
          id="floorplanFile"
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleOnChange}
        />
        <DialogFooter>
          <PrimaryButton
            disabled={
              name.length === 0 ||
              (!edit && !file && !parking) ||
              (edit && !file && location?.name === name) ||
              disableButton
            }
            onClick={handleSubmit}
          >
            {edit ? t("common.edit") : t("common.create")}
          </PrimaryButton>
          <DefaultButton onClick={onDismiss}>
            {t("common.cancel")}
          </DefaultButton>
        </DialogFooter>
      </Stack>
    </Modal>
  );
};

export default LocationModal;
