import {
  DefaultButton,
  Label,
  Modal,
  PrimaryButton,
  Slider,
  Stack,
} from "@fluentui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  defaultModalInnerStyle,
  defaultModalStyle,
  defaultStackStyle,
  labelHeaderStyle,
} from "../../helpers/data";

const DeskRadiusModal = ({
  isOpen,
  onSubmit,
  onChange,
  onDismiss,
  defaultValue,
}) => {
  const [radius, setRadius] = useState(defaultValue);
  const { t, i18n } = useTranslation();

  const handleChange = (r) => {
    setRadius(r);
    onChange(r);
  };

  return (
    <Modal isOpen={isOpen} containerClassName={defaultModalStyle} isModeless>
      <Stack
        className={defaultModalInnerStyle}
        verticalFill
        tokens={defaultStackStyle}
      >
        <Stack horizontal>
          <Label className={labelHeaderStyle}>
            {t("floorplan.editDeskRadius")}
          </Label>
        </Stack>
        <Slider
          min={5}
          max={100}
          defaultValue={defaultValue}
          onChange={handleChange}
        />
        <Stack
          horizontal
          verticalAlign="end"
          horizontalAlign="end"
          verticalFill
          tokens={defaultStackStyle}
        >
          <PrimaryButton onClick={() => onSubmit(radius)}>
            {t("common.save")}
          </PrimaryButton>
          <DefaultButton onClick={onDismiss}>
            {t("common.cancel")}
          </DefaultButton>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default DeskRadiusModal;
