import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  DialogFooter,
  FontIcon,
  Modal,
  PrimaryButton,
  SearchBox,
  SelectionMode,
  Stack,
  TextField,
  useTheme,
} from "@fluentui/react";
import {
  getFocusStyle,
  getTheme,
  mergeStyleSets,
} from "@fluentui/react/lib/Styling";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomMessageBar from "../components/General/Messages/CustomMessageBar";
import Header from "../components/General/Header";
import ActionBarComponent from "../components/General/ActionBarComponent/ActionBarComponent";
import UserComboBox from "../components/General/Inputs/UserComboBox";
import { isLoading } from "../components/General/Loading";
import EmptyMessage from "../components/General/Messages/EmptyMessage";
import ConfirmationDialog from "../components/Modals/ConfirmationDialog";
import {
  defaultModalInnerStyle,
  defaultStackStyle,
  parkingUsersColumns,
} from "../helpers/data";
import {
  AddParkingUser,
  DeleteParkingSpotUsers,
  GetParkingUsers,
  SaveParkingUser,
} from "../services/ParkingService";
import "../styles/custom.css";
import { useTitle } from "../helpers/useTitle";

const theme = getTheme();
const { palette, semanticColors, fonts } = theme;

const classNames = mergeStyleSets({
  itemCell: [
    getFocusStyle(theme, { inset: -1 }),
    {
      minHeight: 15,
      padding: 5,
      boxSizing: "border-box",
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      display: "flex",
    },
  ],
  itemContent: {
    marginLeft: 5,
    overflow: "hidden",
    flexGrow: 1,
  },
  itemName: [
    fonts.medium,
    {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginTop: "5px",
    },
  ],
  itemIndex: {
    fontSize: fonts.smallPlus.fontSize,
    color: palette.neutralTertiary,
    marginBottom: 10,
  },
  chevron: {
    alignSelf: "center",
    marginLeft: 10,
    color: palette.neutralTertiary,
    fontSize: fonts.large.fontSize,
    flexShrink: 0,
  },
});

const spacingToken = {
  childrenGap: 10,
  padding: "0 10 0 20",
};

const ParkingPermissionsPage = () => {
  const [parkingSpotUsers, setParkingSpotUsers] = useState([]);
  const [newUser, setNewUser] = useState([]);
  const [plateNum, setPlateNum] = useState("");
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [disableAdd, setDisableAdd] = useState(false);
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  useTitle("Parking permissions");

  const iconColor = theme?.palette?.themePrimary;

  const handleSelect = (users) => {
    setNewUser(users.length > 0 ? users[0] : null);
  };

  const getParkingSpotUsers = async () => {
    setLoading(true);
    let ParkingSpotUsers = await GetParkingUsers();
    let tempParkingSpotUsers = [];
    for (let parkingSpotUser of ParkingSpotUsers) {
      tempParkingSpotUsers.push({
        id: parkingSpotUser.id,
        PlateNum: parkingSpotUser.defaultPlateNum,
        userPrincipalName: parkingSpotUser.userId,
        user: parkingSpotUser.user,
      });
    }
    setParkingSpotUsers(tempParkingSpotUsers);
    setLoading(false);
  };

  useEffect(() => {
    getParkingSpotUsers();
  }, []);

  const filteredItems = parkingSpotUsers.filter((item) => {
    const lowerCaseQuery = searchQuery?.toLowerCase();

    return (
      !searchQuery ||
      item.PlateNum.toLowerCase().includes(lowerCaseQuery) ||
      item.user.userPrincipalName.toLowerCase().includes(lowerCaseQuery) ||
      item.user.displayName.toLowerCase().includes(lowerCaseQuery)
    );
  });

  const updateItem = async (id, newPlateNum) => {
    let users = [...parkingSpotUsers];
    let user = users.find((u) => u.id === id);

    user.PlateNum = newPlateNum;
    setParkingSpotUsers(users);
  };

  const insertParkingSpotUser = async () => {
    await AddParkingUser({
      UserID: newUser.key,
      DefaultPlateNum: plateNum,
    })
      .then(() => {
        setMessage({ text: t("successMessages.add"), error: false });
        setPlateNum("");
        setShowMessage(true);
      })
      .catch((e) => {
        setMessage({ text: e.response.data, error: true });
        setPlateNum("");
        setShowMessage(true);
      });
    setReset(true);
    setReset(false);
    setDisableAdd(false);
    getParkingSpotUsers();
  };

  const deleteParkingSpotUser = async (item) => {
    await DeleteParkingSpotUsers(item.id).then(() => {
      setOpenDeleteModal(false);
      setMessage({ text: t("successMessages.delete"), error: false });
      setShowMessage(true);
    });
    setReset(true);
    setReset(false);
    getParkingSpotUsers();
  };

  const saveParkingSpotUser = async (item) => {
    await SaveParkingUser(item.id, {
      defaultPlateNum: item.PlateNum,
    })
      .then(() => {
        setMessage({ text: t("successMessages.save"), error: false });
        setShowMessage(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const renderItemColumn = (item, index, column) => {
    switch (column.key) {
      case "name":
        return (
          <div className={classNames.itemName}>{item.user.displayName}</div>
        );
      case "upn":
        return (
          <div className={classNames.itemName}>
            {item.user.userPrincipalName}
          </div>
        );
      case "plateNum":
        return (
          <TextField
            value={item.PlateNum}
            styles={{ root: { maxWidth: "800px" } }}
            onChange={(event) => {
              updateItem(item.id, event.target.value);
            }}
          />
        );
      case "save":
        return (
          <FontIcon
            style={{ color: iconColor }}
            iconName="Save"
            className={"trash"}
            onClick={() => {
              saveParkingSpotUser(item);
            }}
          />
        );
      case "delete":
        return (
          <FontIcon
            style={{ color: iconColor }}
            iconName="Trash"
            className={"trash"}
            onClick={() => {
              setSelectedItem(item);
              setOpenDeleteModal(true);
            }}
          />
        );
      default:
        return String(item[column.fieldName]);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <ActionBarComponent
        id="action-bar"
        actions={[
          {
            key: "parking",
            text: t("management.addParkPerm"),
            icon: "",
            primary: true,
            action: openModal,
            disabled: false,
          },
        ]}
      />
      {showMessage && (
        <CustomMessageBar
          message={message.text}
          visible={showMessage}
          setShowSuccessMessage={() => setShowMessage(false)}
          error={message.error}
        />
      )}
      {openDeleteModal && (
        <ConfirmationDialog
          modalText={t("common.delete")}
          isOpen={openDeleteModal}
          onDismiss={() => setOpenDeleteModal(false)}
          onDelete={() => deleteParkingSpotUser(selectedItem)}
          text={"deleteMessage.parking"}
        />
      )}
      <Modal
        isOpen={isModalOpen}
        onDismiss={() => {
          setDisableAdd(false);
          setIsModalOpen(false);
        }}
      >
        <Stack tokens={defaultStackStyle} className={defaultModalInnerStyle}>
          <UserComboBox
            disabledItems={parkingSpotUsers}
            handleSelect={handleSelect}
            reset={reset}
          />
          <TextField
            label={t("common.licensePlate")}
            onChange={(event) => {
              setPlateNum(event.target.value);
            }}
            value={plateNum}
            styles={{ fieldGroup: { width: 300 } }}
          />
          <DialogFooter>
            <PrimaryButton
              text={t("management.addParkPerm")}
              disabled={!newUser || disableAdd}
              onClick={() => {
                setDisableAdd(true);
                insertParkingSpotUser();
                setIsModalOpen(false);
              }}
            />
            <DefaultButton
              text={t("common.close")}
              onClick={() => {
                setIsModalOpen(false);
                setPlateNum("");
              }}
            />
          </DialogFooter>
        </Stack>
      </Modal>
      <Stack
        style={{ height: "93vh", overflow: "auto" }}
        verticalFill
        className="page"
      >
        <Stack horizontal horizontalAlign="space-between">
          <Header text={t("management.userWithPermission")} />
          <SearchBox
            underlined
            onChange={(e, newValue) => setSearchQuery(newValue)}
            onClear={() => setSearchQuery("")}
            placeholder={t("common.search")}
          />
        </Stack>
        {isLoading(
          loading,
          parkingSpotUsers == 0 ? (
            <EmptyMessage
              text={t("emptyMessages.noUsersWithPermission")}
              icon="Car"
            />
          ) : (
            <Stack style={{ maxWidth: "100%" }}>
              <DetailsList
                items={filteredItems}
                columns={parkingUsersColumns.map((oc) => {
                  return { ...oc, name: t(oc.name) };
                })}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.justified}
                onRenderItemColumn={renderItemColumn}
              />
            </Stack>
          )
        )}
      </Stack>
    </>
  );
};

export default ParkingPermissionsPage;
