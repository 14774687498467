import { deleteData, getData, postData } from "./ApiService";

export const GetRoles = () => {
  return getData("roles");
};

export const GetRoleAssignments = () => {
  return getData("role-assignments");
};

export const CreateRoleAssignments = (roleAssignment) => {
  return postData("role-assignments", roleAssignment);
};

export const DeleteRoleAssignment = (id) => {
  return deleteData(`role-assignments/${id}`);
};
