import {
  DefaultButton,
  Dropdown,
  Modal,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  compactStackStyle,
  defaultModalInnerStyle,
  defaultStackStyle,
} from "../../helpers/data";
import UserComboBox from "../General/Inputs/UserComboBox";
import Tooltip from "../General/Tooltip";

const AdminRoleModal = ({ isOpen, onSubmit, onDismiss, roles }) => {
  const [user, setUser] = useState();
  const [role, setRole] = useState();
  const [disableAssign, setDisableAssign] = useState(false);
  const { t, i18n } = useTranslation();

  const onAssign = () => {
    setDisableAssign(true);
    onSubmit({ roleId: role, userId: user.key });
    setDisableAssign(false);
  };
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Stack
        verticalFill
        className={defaultModalInnerStyle}
        tokens={compactStackStyle}
      >
        <UserComboBox
          handleSelect={(users) => {
            setUser(users ? users[0] : null);
          }}
        />
        <Tooltip
          label={"adminRole.role"}
          fontSize={12}
          style={{
            height: "15px",
            marginLeft: "3px",
            width: "15px",
          }}
        />
        <Dropdown
          options={roles.map((r) => {
            return { text: r.name, key: r.id };
          })}
          onChange={(e, item) => setRole(item.key)}
        />
        <Stack horizontal horizontalAlign="end" tokens={defaultStackStyle}>
          <PrimaryButton onClick={onAssign} disabled={!role || !user}>
            {t("common.assign")}
          </PrimaryButton>
          <DefaultButton onClick={onDismiss}>
            {t("common.cancel")}
          </DefaultButton>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default AdminRoleModal;
