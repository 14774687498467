import { deleteData, getDataPromise, getFile, putData } from "./ApiService";

export const GetConfigs = () => {
  return getDataPromise("config");
};

export const GetConfig = (key) => {
  return getDataPromise("config/value", { key: key });
};

export const GetConfigFile = (key) => {
  return getFile("config/file", { key: key });
};

export const UpdateConfigFile = async (key, file) => {
  const formData = new FormData();

  formData.append("File", file);

  return putData(`config/file`, formData, { key: key });
};

export const UpdateConfigValue = async (key, value) => {
  return putData(`config/value`, { value: value }, { key: key });
};

export const DeleteConfigValue = async (key) => {
  return deleteData(`config`, { key: key });
};
