import { useTranslation } from "react-i18next";
import EmptyMessage from "../components/General/Messages/EmptyMessage";
import { useTitle } from "../helpers/useTitle";

const NoMatchPage = () => {
  const { t, i18n } = useTranslation();
  useTitle("Nothing found");
  return <EmptyMessage text={t("nomatch.message")} icon="Sad" />;
};
export default NoMatchPage;
