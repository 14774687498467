import { useHistory } from "react-router-dom";

const AxiosInterceptorsSetup = (fetchApi, getToken, history) => {
  fetchApi.interceptors.request.use(async (config) => {
    const token = await getToken();
    //TODO Handle null token
    if (!token) {
      return Promise.reject({ status: 401 });
    }
    if (!config) {
      config = {};
    }
    if (!config.headers) {
      config.headers = {};
    }
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  fetchApi.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = error.response.status;
      const content = error.response.data;
      const errorInfo = {
        url: error?.config?.url,
        errorId: content?.guruMeditationCode,
      };
      const state = { responseStatus: status, errorInfo: errorInfo };
      if (status) {
        switch (error.response.status) {
          case 500:
            history.push("/error");
            break;
          case 400:
          case 401:
          case 403:
          case 404:
            break;
        }
      }
      return Promise.reject(error);
    }
  );
};

export default AxiosInterceptorsSetup;
