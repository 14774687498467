import { useTheme } from "@fluentui/react";
import React from "react";

const Header = ({ text }) => {
  const theme = useTheme();

  const textColor = theme?.palette?.themePrimary;
  return (
    <h3 style={{ color: textColor, fontWeight: "400", fontSize: "x-large" }}>
      {text}
    </h3>
  );
};

export default Header;
