import {
  ContextualMenu,
  Icon,
  IconButton,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { compactStackStyle } from "../../../helpers/data";
import "./FloorplanSidebar.css";
import { sortDesks } from "../../../helpers/Functions";

const OfficeFloorplanSidebar = ({
  offices,
  desks,
  deskEditing,
  officeEditing,
  drawing,
  selectOffice,
  selectDesk,
  handleActivation,
  onEditOffice,
  onEditDesk,
  onDeleteOffice,
  onDeleteDesk,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="floorplan-sidebar">
      <Stack tokens={compactStackStyle}>
        {offices && offices.length > 0 ? (
          offices.map((o, i) => (
            <OfficeItem
              key={i}
              office={o}
              desks={desks}
              deskEditing={deskEditing}
              officeEditing={officeEditing}
              drawing={drawing}
              selectOffice={selectOffice}
              selectDesk={selectDesk}
              handleActivation={handleActivation}
              onEditOffice={onEditOffice}
              onEditDesk={onEditDesk}
              onDeleteOffice={onDeleteOffice}
              onDeleteDesk={onDeleteDesk}
            />
          ))
        ) : (
          <Text style={{ padding: 10 }}>{t("emptyMessages.noOffice")}</Text>
        )}
      </Stack>
    </div>
  );
};

const DeskItem = ({
  desk,
  selectDesk,
  index,
  deskEditing,
  officeEditing,
  handleActivation,
  drawing,
  onEditDesk,
  onDeleteDesk,
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const { t, i18n } = useTranslation();
  const deskRef = useRef();
  const menuItems = [
    {
      key: "edit",
      text: t("common.edit"),
      disabled: officeEditing || deskEditing || drawing,
      onClick: () => onEditDesk(desk),
    },
    {
      key: "active",
      text: t(desk.active ? "common.deactivate" : "common.activate"),
      disabled: officeEditing || deskEditing || drawing,
      onClick: () => handleActivation(desk),
    },
    {
      key: "delete",
      text: t("common.delete"),
      disabled: officeEditing || deskEditing || drawing,
      onClick: () => onDeleteDesk(desk),
    },
  ];
  return (
    <div
      className={"item"}
      ref={deskRef}
      onMouseEnter={() => selectDesk(desk, true)}
      onMouseLeave={() => selectDesk(desk, false)}
    >
      <Stack horizontal style={{ paddingLeft: "10px", paddingRight: "5px" }}>
        <StackItem grow>
          <Text>
            {desk.deskNumber !== null
              ? desk.deskNumber
              : `DESK${index < 9 ? `0${index + 1}` : index + 1}`}
          </Text>
        </StackItem>
        <StackItem align="end">
          <Icon
            iconName="More"
            onClick={() => {
              selectDesk(desk, !openMenu);
              setOpenMenu((prev) => !prev);
            }}
            className="icon-button"
          />
        </StackItem>
        <ContextualMenu
          items={menuItems}
          hidden={!openMenu}
          target={deskRef}
          onItemClick={() => setOpenMenu(true)}
          onDismiss={() => {
            selectDesk(desk, false);
            setOpenMenu(false);
          }}
        />
      </Stack>
    </div>
  );
};

const OfficeItem = ({
  office,
  desks,
  deskEditing,
  officeEditing,
  drawing,
  selectOffice,
  selectDesk,
  handleActivation,
  onEditOffice,
  onEditDesk,
  onDeleteOffice,
  onDeleteDesk,
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const officeRef = useRef();
  const { t, i18n } = useTranslation();
  const menuItems = [
    {
      key: "edit",
      text: t("common.edit"),
      disabled: officeEditing || deskEditing || drawing,
      onClick: () => onEditOffice(office),
    },
    {
      key: "delete",
      text: t("common.delete"),
      disabled: officeEditing || deskEditing || drawing,
      onClick: () => onDeleteOffice(office),
    },
  ];

  return (
    <Stack>
      <div
        className={"item"}
        ref={officeRef}
        onMouseEnter={() => selectOffice(office, true)}
        onMouseLeave={() => selectOffice(office, false)}
      >
        <Stack horizontal style={{ minWidth: "200px", paddingRight: "5px" }}>
          <IconButton
            iconProps={{
              iconName: expanded ? "ChevronDown" : "ChevronRight",
              styles: { root: { fontSize: 13 } },
            }}
            style={{ width: "22px", height: "22px", marginRight: "2px" }}
            onClick={() => setExpanded(!expanded)}
          />
          <StackItem
            onClick={() => {
              setExpanded(!expanded);
            }}
            grow
          >
            <Text>{office.name}</Text>
          </StackItem>
          <StackItem align="end">
            <Icon
              iconName="More"
              onClick={() => {
                selectOffice(office, !openMenu);
                setOpenMenu((prev) => !prev);
              }}
              className="icon-button"
            />
          </StackItem>
        </Stack>
        <ContextualMenu
          items={menuItems}
          hidden={!openMenu}
          target={officeRef}
          onItemClick={() => setOpenMenu(true)}
          onDismiss={() => {
            selectOffice(office, false);
            setOpenMenu(false);
          }}
        />
      </div>
      {expanded &&
        (desks && desks.filter((d) => d.officeId == office.id).length > 0 ? (
          <Stack>
            {desks
              .filter((d) => d.officeId == office.id)
              .sort(sortDesks)
              .map((d, i) => (
                <DeskItem
                  key={i}
                  index={i}
                  desk={d}
                  selectDesk={selectDesk}
                  deskEditing={deskEditing}
                  officeEditing={officeEditing}
                  drawing={drawing}
                  handleActivation={handleActivation}
                  onEditDesk={onEditDesk}
                  onDeleteDesk={onDeleteDesk}
                />
              ))}
          </Stack>
        ) : (
          <Text style={{ paddingLeft: 10, paddingRight: 10 }}>
            {t("emptyMessages.noDesk")}
          </Text>
        ))}
    </Stack>
  );
};

export default OfficeFloorplanSidebar;
