import {
  DefaultButton,
  DialogFooter,
  Modal,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { defaultModalInnerStyle, defaultStackStyle } from "../../helpers/data";
import UserComboBox from "../General/Inputs/UserComboBox";

const UserModal = ({
  items,
  isOpen,
  onDismiss,
  onSubmit,
  text,
  placeholder,
  label,
}) => {
  const [item, setItem] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const { t, i18n } = useTranslation();
  const handlePrimaryButtonClick = () => {
    setDisableButton(true);
    onSubmit(item);
    onDismiss();
    setDisableButton(false);
  };

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Stack tokens={defaultStackStyle} className={defaultModalInnerStyle}>
        <UserComboBox
          disabledItems={items}
          handleSelect={(i) => setItem({ userId: i[0]?.key })}
        />
        <DialogFooter>
          <PrimaryButton
            disabled={!item?.userId || disableButton}
            text={text}
            onClick={handlePrimaryButtonClick}
          />
          <DefaultButton text={t("common.close")} onClick={onDismiss} />
        </DialogFooter>
      </Stack>
    </Modal>
  );
};

export default UserModal;
