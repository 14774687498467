import {
  DefaultButton,
  IconButton,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Separator,
  Stack,
  StackItem,
} from "@fluentui/react";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { dropdownStyles } from "../../../helpers/data";
import Header from "../Header";
import BasicCalendar from "../Inputs/BasicCalendar";

const MobileFilter = ({
  selectedLocation,
  handleMobilePopup,
  selectedPeriod,
  periods,
  locations,
  handlePeriodSelection,
  handleLocationSelection,
  handleReset,
  setFromDate,
  date,
}) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  return (
    <>
      <Stack
        horizontalAlign="space-between"
        horizontal
        tokens={{ childrenGap: 10 }}
        verticalAlign="center"
      >
        <StackItem>
          <Stack horizontal tokens={{ childrenGap: 15 }}>
            <IconButton
              onClick={
                selectedLocation
                  ? () => {
                      handleMobilePopup();
                    }
                  : () => history.push("/")
              }
              iconProps={{ iconName: "Back" }}
            />
            <Header text={t("common.select")} style={{ marginBottom: 0 }} />
          </Stack>
        </StackItem>
        <StackItem>
          <DefaultButton
            text={t("common.reset")}
            onClick={handleReset}
            styles={{ root: { border: 0 } }}
          />
        </StackItem>
      </Stack>
      <StackItem>
        <Stack horizontalAlign="center" styles={{ root: { marginBottom: 10 } }}>
          {locations.length === 0 && (
            <MessageBar
              messageBarType={MessageBarType.warning}
              isMultiline={true}
            >
              {t("emptyMessages.noLocationPermission")}
            </MessageBar>
          )}
        </Stack>
      </StackItem>
      <StackItem>
        <Stack horizontalAlign="center">
          <Dropdown
            options={locations}
            onChange={(e, item) => handleLocationSelection(item)}
            label={t("common.location")}
            styles={dropdownStyles}
            placeholder={t("common.selectLocation")}
            selectedKey={selectedLocation?.key ?? null}
            disabled={locations.length === 0}
          />
        </Stack>
        <Separator />
      </StackItem>
      <StackItem>
        <Stack horizontalAlign="center">
          <Dropdown
            label={t("common.period")}
            options={periods}
            styles={dropdownStyles}
            onChange={(e, item) => handlePeriodSelection(item)}
            placeholder={t("common.selectPeriod")}
            selectedKey={selectedPeriod?.key ?? null}
            disabled={locations.length === 0}
          />
        </Stack>
        <Separator />
      </StackItem>
      {setFromDate && (
        <StackItem>
          <Stack horizontalAlign="center">
            <BasicCalendar
              text={t("common.fromDate")}
              handleSelect={setFromDate}
              disabled={locations.length === 0}
            />
          </Stack>
          <Separator />
        </StackItem>
      )}
      <Stack verticalFill verticalAlign="end">
        <PrimaryButton
          text="Apply"
          style={{ width: 90, marginLeft: 30, marginBottom: 30 }}
          onClick={handleMobilePopup}
          disabled={!selectedLocation || !selectedPeriod || !date}
        />
      </Stack>
    </>
  );
};

export default MobileFilter;
