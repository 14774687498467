import {
  getDataPromise,
  postDataPromise,
  deleteDataPromise,
  putDataPromise,
} from "./ApiService";

export const GetTeams = async () => {
  return getDataPromise("team");
};

export const CreateTeam = async (teamName) => {
  return postDataPromise("team", teamName);
};

export const UpdateTeam = async (id, teamName) => {
  return putDataPromise(`team/${id}`, teamName);
};

export const GetTeamById = async (id) => {
  return getDataPromise(`team/${id}`);
};

export const DeleteTeam = async (id) => {
  return deleteDataPromise(`team/${id}`);
};

export const GetTeamMembers = async (id) => {
  return getDataPromise(`team/${id}/members`);
};

export const AddMembersToTeam = async (id, members) => {
  return postDataPromise(`team/${id}/members`, members);
};

export const DeleteTeamMember = async (id, member) => {
  return deleteDataPromise(`team/${id}/member`, { userId: member });
};

export const MyTeamsLeader = async () => {
  return getDataPromise("team/leader/my");
};
