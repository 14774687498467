import {
  deleteData,
  deleteDataPromise,
  getData,
  postData,
  postDataPromise,
  putData,
} from "./ApiService";

export const GetParkingReservations = async () => {
  return getData("myparkingreservations");
};

export const GetParkingSpot = async (username) => {
  return getData("parkingspotusers/current", { userUpn: username });
};

export const GetParkingSpotById = async (locationId, spotId) => {
  return getData(`location/${locationId}/parkingspot`, { spotId: spotId });
};

export const GetAssignedParkingSpots = async () => {
  return getData("assignedparkingspots");
};

export const GetParkingUsers = async () => {
  return getData("parkingspotusers");
};

export const AddAssignedSpots = async (assignedSpot) => {
  return postData("assignedparkingspots", assignedSpot);
};

export const AddParkingUser = async (parkingUser) => {
  return postDataPromise("parkingspotusers", parkingUser);
};

export const DeleteParkingReservation = async (id) => {
  return deleteData(`parkingreservation/${id}`);
};

export const DeleteAssignedParkingSpots = async (itemId) => {
  return deleteData(`assignedparkingspots/${itemId}`);
};

export const DeleteParkingSpotUsers = async (itemId) => {
  return deleteData(`parkingspotUsers/${itemId}`);
};

export const DeleteParkingReservationPromise = async (itemReservationID) => {
  return deleteDataPromise(`parkingreservation/${itemReservationID}`);
};

export const SaveParkingUser = async (itemId, defaultPlateNum) => {
  return putData(`parkingspotUsers/${itemId}`, defaultPlateNum);
};

export const AddVacantAssignedParkingSpot = async (assignedSpot) => {
  return postDataPromise("vacantassignedparkingspot", assignedSpot);
};
