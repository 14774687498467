import {
  DefaultButton,
  DialogFooter,
  Dropdown,
  Label,
  Modal,
  PrimaryButton,
  Stack,
  TextField,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  defaultModalInnerStyle,
  defaultModalStyle,
  defaultStackStyle,
  labelHeaderStyle,
} from "../../helpers/data";
import BasicDropdown from "../General/Inputs/BasicDropdown";

const EditModal = ({
  isOpen,
  onDismiss,
  offices,
  onMove,
  onSubmit,
  modalText,
  selectedDesk,
}) => {
  const [name, setName] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [office, setOffice] = useState([]);
  const [convertedOffices, setConvertedOffices] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setOffice(null);
    setName("");
    setDisableButton(false);
  }, [isOpen]);

  useEffect(() => {
    if (offices != null) {
      setConvertedOffices(
        offices.map((o) => {
          return {
            key: o.id,
            text: o.name,
          };
        })
      );
    }
  }, [offices]);

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      containerClassName={defaultModalStyle}
    >
      <Stack tokens={defaultStackStyle} className={defaultModalInnerStyle}>
        <Stack horizontal>
          <Label className={labelHeaderStyle}>{modalText}</Label>
        </Stack>
        <TextField
          label="Name"
          required={selectedDesk == null}
          styles={{ root: { maxWidth: "200px" } }}
          onChange={(e, newValue) => setName(newValue ? newValue : "")}
        />
        {offices != null && (
          <BasicDropdown
            label={t("common.office")}
            placeholder={t("management.selectOffice")}
            handleChange={setOffice}
            options={convertedOffices}
            defaultItem={convertedOffices.find(
              (o) => o.key == selectedDesk.officeId
            )}
          />
        )}
        <DialogFooter>
          <PrimaryButton
            disabled={disableButton}
            onClick={() => {
              setDisableButton(true);
              onMove(name);
            }}
          >
            {t("common.move")}
          </PrimaryButton>
          <PrimaryButton
            disabled={
              (!name &&
                (!office ||
                  !selectedDesk ||
                  office.key == selectedDesk.officeId)) ||
              disableButton
            }
            onClick={() => {
              setDisableButton(true);
              onSubmit(name, office);
            }}
          >
            {t("common.save")}
          </PrimaryButton>
          <DefaultButton onClick={onDismiss}>
            {t("common.cancel")}
          </DefaultButton>
        </DialogFooter>
      </Stack>
    </Modal>
  );
};

export default EditModal;
