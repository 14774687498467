import {
  DialogFooter,
  Modal,
  PrimaryButton,
  ProgressIndicator,
  Stack,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import {
  defaultModalInnerSyle,
  defaultModalStyle,
  defaultStackStyle,
} from "../../helpers/data";
import { useTranslation } from "react-i18next";

const ProcessingComponent = ({ actions, onFinish }) => {
  const [finished, setFinished] = useState(0);
  const [failed, setFailed] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (actions.length > 0) {
      for (let action of actions) {
        action.then(() => setFinished((prev) => prev + 1));
      }
      Promise.all(actions).catch(() => setFailed(true));
    }
  }, [actions]);

  return (
    <Modal isOpen={true} containerClassName={defaultModalStyle}>
      <Stack tokens={defaultStackStyle} className={defaultModalInnerSyle}>
        <Stack styles={{ root: { minWidth: "600px" } }}>
          <ProgressIndicator
            label={
              !(failed || finished >= actions.length)
                ? t("common.processing")
                : t("common.done")
            }
            description={`${finished} ${t("common.from")} ${actions.length}`}
            percentComplete={finished / actions.length}
          />
          {failed && <span>"Failed"</span>}
        </Stack>
        <DialogFooter>
          <PrimaryButton
            disabled={!(failed || finished >= actions.length)}
            onClick={() => onFinish()}
          >
            {t("common.close")}
          </PrimaryButton>
        </DialogFooter>
      </Stack>
    </Modal>
  );
};

export default ProcessingComponent;
