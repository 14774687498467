import { Label } from "@fluentui/react";
import {
  NormalPeoplePicker,
  PeoplePickerItemSuggestion,
  ValidationState,
} from "@fluentui/react/lib/Pickers";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { GetUsers } from "../../../services/UserService";
import { useCallback, useContext, useEffect, useState } from "react";
import { RoleContext, TeamLeaderContext } from "../Layout";
import { MyTeamsLeader } from "../../../services/TeamService";

const UserComboBox = ({ disabledItems, handleSelect, reset }) => {
  const [isPickerDisabled, setIsPickerDisabled] = React.useState(false);
  const [peopleList, setPeopleList] = React.useState();
  const [selected, setSelected] = React.useState();
  const [teamsMembers, setTeamsMembers] = useState([]);
  const { t, i18n } = useTranslation();

  const picker = React.useRef(null);
  const isTeamLeader = useContext(TeamLeaderContext);
  const roles = useContext(RoleContext);

  const hasRole = useCallback(
    (role) => {
      return roles?.filter((r) => r.role.name === role).length > 0;
    },
    [roles]
  );

  useEffect(() => {
    if (isTeamLeader && !hasRole("Permission Manager")) {
      MyTeamsLeader().then((value) =>
        setTeamsMembers(value.data.map((v) => v.teamMembers).flat(1))
      );
    }
  }, []);

  const getUsers = async (filter) => {
    let users = await GetUsers(filter);
    let filtered = users
      .filter((user) =>
        teamsMembers.length > 1
          ? teamsMembers.some((member) => member.userId === user.userID)
          : true
      )
      .map((user) => {
        return {
          key: user.userPrincipalName,
          text: user.displayName,
          secondaryText: user.userPrincipalName,
        };
      });
    setPeopleList(filtered);
    return filtered;
  };

  React.useEffect(() => {
    picker.current.removeItems(peopleList);
    setSelected(null);
  }, [reset]);

  const onFilterChanged = async (filterText, currentPersonas, limitResults) => {
    if (filterText) {
      let filteredPersonas = await getUsers(filterText);
      filteredPersonas = filterPersonas(filteredPersonas);
      filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
      filteredPersonas = limitResults
        ? filteredPersonas.slice(0, limitResults)
        : filteredPersonas;
      return filteredPersonas;
    } else {
      return [];
    }
  };

  const filterPersonas = (people) => {
    return disabledItems
      ? people.filter((u) => {
          return disabledItems.filter((d) => u.key == d.userPrincipalName) == 0;
        })
      : people;
  };

  const onRenderSuggestionsItem = (personaProps, suggestionsProps) => (
    <PeoplePickerItemSuggestion
      personaProps={{
        ...personaProps,
        styles: { secondaryText: { maxWidth: "500px" } },
      }}
      suggestionsProps={suggestionsProps}
      styles={{ personaWrapper: { width: "100%" } }}
    />
  );

  return (
    <div style={{ marginTop: "10px" }}>
      <Label>{t("common.user")}</Label>
      <NormalPeoplePicker
        onResolveSuggestions={onFilterChanged}
        getTextFromItem={getTextFromItem}
        className={"ms-PeoplePicker"}
        key={"normal"}
        onValidateInput={validateInput}
        selectionAriaLabel={"Selected contacts"}
        removeButtonAriaLabel={"Remove"}
        inputProps={{
          "aria-label": "People Picker",
        }}
        componentRef={picker}
        onInputChange={onInputChange}
        onChange={(items) => {
          let selectedItem = items.length > 0 ? [items[items.length - 1]] : [];
          setSelected(selectedItem);
          handleSelect(selectedItem);
          return items;
        }}
        onRenderSuggestionsItem={onRenderSuggestionsItem}
        selectedItems={selected ? selected : []}
        resolveDelay={300}
        disabled={isPickerDisabled}
        styles={{
          root: {
            width: "50%",
            minWidth: "300px",
          },
        }}
        itemLimit={1}
      />
    </div>
  );
};

function doesTextStartWith(text, filterText) {
  return text.toLowerCase().indexOf(filterText.toLowerCase()) != -1;
}

function removeDuplicates(personas, possibleDupes) {
  return personas.filter(
    (persona) => !listContainsPersona(persona, possibleDupes)
  );
}

function listContainsPersona(persona, personas) {
  if (!personas || !personas.length || personas.length === 0) {
    return false;
  }
  return personas.filter((item) => item.text === persona.text).length > 0;
}

function getTextFromItem(persona) {
  return persona.text;
}

function validateInput(input) {
  if (input.indexOf("@") !== -1) {
    return ValidationState.valid;
  } else if (input.length > 1) {
    return ValidationState.warning;
  } else {
    return ValidationState.invalid;
  }
}
function onInputChange(input) {
  const outlookRegEx = /<.*>/g;
  const emailAddress = outlookRegEx.exec(input);
  if (emailAddress && emailAddress[0]) {
    return emailAddress[0].substring(1, emailAddress[0].length - 1);
  }
  return input;
}

export default UserComboBox;
