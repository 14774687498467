import { Stack } from "@fluentui/react";
import React, { createContext, useEffect, useState } from "react";
import { GetParkingSpot } from "../../services/ParkingService";
import Sidebar from "./Sidebar/Sidebar";
import { MyTeamsLeader } from "../../services/TeamService";
import { GetCurrentUserRoles } from "../../services/UserService";

export const PermissionContext = createContext();

export const TeamLeaderContext = createContext();

export const IsMobileContext = createContext();

export const RoleContext = createContext();

const Layout = (props, user) => {
  const [parking, setParking] = useState(
    localStorage.getItem("parkingPermission") ?? false
  );
  const [isMobile, setIsMobile] = useState(false);
  const [isTeamLeader, setIsTeamLeader] = useState(
    localStorage.getItem("isTeamLeader") ?? false
  );
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    isLeader();
    checkParking();
    hasRole();
  }, []);

  const isLeader = () => {
    MyTeamsLeader().then((value) => {
      setIsTeamLeader(value.data.length > 0);
      localStorage.setItem("isTeamLeader", value.data.length > 0);
    });
  };

  const hasRole = () => {
    GetCurrentUserRoles().then((value) => {
      setRoles(value);
    });
  };

  const checkParking = async () => {
    let parkingSpot = await GetParkingSpot(user.username);
    setParking(parkingSpot.length > 0);
    localStorage.setItem("parkingPermission", parkingSpot.length > 0);
  };

  useEffect(() => {
    const handleResize = () => {
      const isMobileView = window.matchMedia("(max-width: 1000px)").matches;
      setIsMobile(isMobileView);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Stack horizontal verticalFill>
      <PermissionContext.Provider value={parking}>
        <IsMobileContext.Provider value={isMobile}>
          <TeamLeaderContext.Provider value={isTeamLeader}>
            <RoleContext.Provider value={roles}>
              <Sidebar />
              <Stack className="main-window" verticalFill>
                {props.children}
              </Stack>
            </RoleContext.Provider>
          </TeamLeaderContext.Provider>
        </IsMobileContext.Provider>
      </PermissionContext.Provider>
    </Stack>
  );
};

export default Layout;
