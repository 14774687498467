import { useTranslation } from "react-i18next";
import EmptyMessage from "../components/General/Messages/EmptyMessage";
import { useTitle } from "../helpers/useTitle";

const ErrorPage = () => {
  const { t, i18n } = useTranslation();
  useTitle("Error");
  return <EmptyMessage text={t("errorMessages.message")} icon="Sad" />;
};

export default ErrorPage;
