import { FontIcon, Stack, useTheme } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { classNames } from "../../helpers/data";
import { DeleteReservation } from "../../services/DeskService";
import { DeleteParkingReservation } from "../../services/ParkingService";
import ConfirmationDialog from "../Modals/ConfirmationDialog";
import { DetailList } from "./DetailList";
import { isLoading } from "./Loading";
import EmptyMessage from "./Messages/EmptyMessage";
import ViewReservation from "./ViewReservation";

export const UpcomingReservations = ({ getItems, reset, getHeaderText }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewReservation, setViewReservation] = useState(false);
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const iconColor = theme?.palette?.themePrimary;

  const getReservations = async () => {
    setLoading(true);
    setItems([]);
    setItems(await getItems());
    setLoading(false);
  };

  useEffect(() => {
    getReservations();
  }, [reset]);

  useEffect(() => {
    setItems([...items]);
  }, [i18n.language]);

  const handleDeletion = (type, id) => {
    const promise =
      type == "desk" ? DeleteReservation(id) : DeleteParkingReservation(id);
    promise.then(() => {
      setDeleteModal(false);
      getReservations();
    });
  };

  const handleView = (location, spotId, type) => {
    setSelectedItem({
      location: location,
      spotId: spotId,
      type: type,
    });
  };

  const onRenderCell = (item, index, isScrolling) => {
    return (
      <Stack
        horizontal
        verticalAlign={"center"}
        className={classNames.itemCell}
        data-is-focusable={true}
      >
        <FontIcon
          iconName={item.type == "desk" ? "System" : "Car"}
          className={"icon"}
          style={{ color: iconColor }}
        />
        <div className={classNames.itemContent}>
          <div className={classNames.itemName}>
            {`${
              getHeaderText
                ? getHeaderText(item)
                : item.permanent
                ? t("common.permanent")
                : new Date(item.startDate).toLocaleDateString(i18n.language) +
                  (item.morning && !item.afternoon
                    ? " - " + t("common.morning")
                    : !item.morning && item.afternoon
                    ? " - " + t("common.afternoon")
                    : "")
            }`}
          </div>
          <div className={classNames.itemIndex}>
            {"(" +
              t("home.in") +
              " " +
              item.locationObj?.name +
              " - " +
              item.location +
              " - " +
              (item.type == "parking"
                ? t("common.parkingSpot") + " "
                : t("common.desk")) +
              " " +
              item.number +
              ")"}
          </div>
        </div>
        <Stack horizontal verticalAlign={"center"}>
          <FontIcon
            iconName="Trash"
            className={"trash"}
            onClick={() => {
              setDeleteModal(true);
              setSelectedItem(item);
            }}
          />
          <FontIcon
            iconName="View"
            className={"trash"}
            onClick={() => {
              setViewReservation(true);
              handleView(item.locationObj, item.spotId, item.type);
            }}
          />
        </Stack>
      </Stack>
    );
  };
  return (
    <Stack verticalFill>
      {isLoading(
        loading,
        items && items.length > 0 ? (
          <DetailList items={items} onRenderCell={onRenderCell} />
        ) : (
          <EmptyMessage
            text={t("emptyMessages.upcomingRes")}
            icon="TextDocument"
          />
        )
      )}
      {deleteModal && (
        <ConfirmationDialog
          isOpen={deleteModal}
          modalText={t("common.delete")}
          onDismiss={() => setDeleteModal(false)}
          text={"deleteMessage.reservation"}
          onDelete={() => {
            handleDeletion(selectedItem.type, selectedItem.id);
          }}
        />
      )}
      {selectedItem != null && viewReservation && (
        <ViewReservation
          location={selectedItem.location}
          hotdeskId={selectedItem.type == "desk" ? selectedItem.spotId : null}
          parkingSpotNumber={
            selectedItem.type == "parking" ? selectedItem.spotId : null
          }
          onDismiss={() => {
            setSelectedItem(null);
            setViewReservation(false);
          }}
        />
      )}
    </Stack>
  );
};

export default UpcomingReservations;
