import {
  DefaultButton,
  DialogFooter,
  Dropdown,
  Label,
  Modal,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  defaultModalInnerStyle,
  defaultModalStyle,
  defaultStackStyle,
  labelHeaderStyle,
} from "../../helpers/data";

const DeskModal = ({ isOpen, onDismiss, onSubmit, offices }) => {
  const [office, setOffice] = useState(null);
  const [disableCreate, setDisableCreate] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setOffice(null);
    setDisableCreate(false);
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      containerClassName={defaultModalStyle}
    >
      <Stack tokens={defaultStackStyle} className={defaultModalInnerStyle}>
        <Stack horizontal>
          <Label className={labelHeaderStyle}>
            {t("floorplan.createDesk")}
          </Label>
        </Stack>
        <Dropdown
          label={t("common.office")}
          required
          options={offices.map((o) => {
            return { text: o.name, key: o.id, data: o };
          })}
          styles={{ root: { maxWidth: "300px" } }}
          onChange={(e, selected) => {
            setOffice(selected?.data);
          }}
        />
        <DialogFooter>
          <PrimaryButton
            disabled={!office || disableCreate}
            onClick={() => {
              setDisableCreate(true);
              onSubmit(office);
            }}
          >
            {t("common.create")}
          </PrimaryButton>
          <DefaultButton onClick={onDismiss}>
            {t("common.cancel")}
          </DefaultButton>
        </DialogFooter>
      </Stack>
    </Modal>
  );
};

export default DeskModal;
