import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const ConfirmationDialog = ({
  isOpen,
  onDismiss,
  onDelete,
  modalText,
  text,
}) => {
  const [disableDelete, setDisableDelete] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setDisableDelete(false);
  }, [isOpen]);

  return (
    <Dialog
      hidden={!isOpen}
      onDismiss={onDismiss}
      dialogContentProps={{
        type: DialogType.normal,
        title: modalText,
        subText: t("deleteMessage.deleteMessage") + t(text) + "?",
      }}
    >
      <DialogFooter>
        <PrimaryButton
          disabled={disableDelete}
          onClick={() => {
            setDisableDelete(true);
            onDelete();
          }}
        >
          {t("common.delete")}
        </PrimaryButton>
        <DefaultButton onClick={onDismiss}>{t("common.cancel")}</DefaultButton>
      </DialogFooter>
    </Dialog>
  );
};

export default ConfirmationDialog;
