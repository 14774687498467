import { PrimaryButton, Stack } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";

const SubPageHeader = ({ headerText, buttonText, openModal, style }) => {
  const { t, i18n } = useTranslation();
  return (
    <Stack horizontal horizontalAlign="space-between" style={style}>
      <h5>{t(`management.${headerText}`)}</h5>
      <PrimaryButton text={t(`management.${buttonText}`)} onClick={openModal} />
    </Stack>
  );
};

export default SubPageHeader;
