import {
  deleteData,
  getData,
  postData,
  postDataPromise,
  putData,
} from "./ApiService";

export const AddOfficesToGroup = (groupid, offices) => {
  return postDataPromise(`groups/${groupid}/offices`, offices);
};

export const GetGroupUsers = async (selectedGroupId) => {
  return getData(`groups/${selectedGroupId}/users`);
};

export const GetGroupsForUser = async (userId) => {
  return getData(`user/${userId}/groups`);
};

export const GetGroupByOffice = async (selectedGroupId) => {
  return getData(`groups/${selectedGroupId}/offices`);
};

export const GetGroups = async () => {
  return getData("groups");
};

export const AddUsersToGroups = async (groupId, users) => {
  return postDataPromise(`groups/${groupId}/users`, users);
};

export const AddGroupPermissions = async (group) => {
  return postData("groups", group);
};

export const DeleteUserFromGroup = async (groupId, user) => {
  return deleteData(`groups/${groupId}/user`, {
    userId: user.userPrincipalName,
  });
};

export const DeleteOfficeFromGroup = async (groupId, officeId) => {
  return deleteData(`groups/${groupId}/office/${officeId}`);
};

export const DeleteGroup = async (selectedGroupKey) => {
  return deleteData(`groups/${selectedGroupKey}`);
};

export const UpdateGroup = async (groupId, group) => {
  return putData(`groups/${groupId}`, group);
};
