import { ContextualMenu, Icon, Stack, StackItem, Text } from "@fluentui/react";
import { useRef, useState } from "react";
import { compactStackStyle } from "../../../helpers/data";
import "./FloorplanSidebar.css";
import { useTranslation } from "react-i18next";

const ParkingFloorplanSidebar = ({
  parkingSpots,
  selectSpot,
  onEditParkingSpot,
  onDeleteParkingSpot,
  editing,
  drawing,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="floorplan-sidebar">
      <Stack
        verticalFill
        styles={{ root: { maxHeight: "90%" } }}
        tokens={compactStackStyle}
      >
        {parkingSpots && parkingSpots.length > 0 ? (
          parkingSpots.map((o, i) => (
            <ParkingSpotItem
              key={i}
              editing={editing}
              drawing={drawing}
              parkingSpot={o}
              selectSpot={selectSpot}
              onEditParkingSpot={onEditParkingSpot}
              onDeleteParkingSpot={onDeleteParkingSpot}
            />
          ))
        ) : (
          <Text style={{ padding: 10 }}>
            {t("emptyMessages.noParkingSpots")}
          </Text>
        )}
      </Stack>
    </div>
  );
};

const ParkingSpotItem = ({
  parkingSpot,
  editing,
  drawing,
  selectSpot,
  onEditParkingSpot,
  onDeleteParkingSpot,
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const officeRef = useRef();
  const { t, i18n } = useTranslation();
  const menuItems = [
    {
      key: "edit",
      text: t("common.edit"),
      disabled: editing || drawing,
      onClick: () => onEditParkingSpot(parkingSpot),
    },
    {
      key: "delete",
      text: t("common.delete"),
      disabled: editing || drawing,
      onClick: () => onDeleteParkingSpot(parkingSpot),
    },
  ];

  return (
    <Stack>
      <div
        className={"item"}
        ref={officeRef}
        onMouseEnter={() => selectSpot(parkingSpot)}
        onMouseLeave={() => selectSpot({})}
      >
        <Stack
          horizontal
          style={{ minWidth: "200px", paddingLeft: "5px", paddingRight: "5px" }}
        >
          <StackItem grow>
            <Text>{parkingSpot?.spotNumber}</Text>
          </StackItem>
          <StackItem align="end">
            <Icon
              iconName="More"
              onClick={() => {
                selectSpot(openMenu ? {} : parkingSpot);
                setOpenMenu((prev) => !prev);
              }}
              className="icon-button"
            />
          </StackItem>
        </Stack>
        <ContextualMenu
          items={menuItems}
          hidden={!openMenu}
          target={officeRef}
          onItemClick={() => setOpenMenu(true)}
          onDismiss={() => setOpenMenu(false)}
        />
      </div>
    </Stack>
  );
};

export default ParkingFloorplanSidebar;
