import { MessageBar, Stack } from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "reactjs-popup/dist/index.css";
import { GetDeskReservations } from "../../services/DeskService";
import ReservationFloorplanComponent from "../FloorplanEditor/ReservationFloorplanComponent";
import EmptyMessage from "./Messages/EmptyMessage";

function DeskReservation({
  startDate,
  period,
  mobile,
  location,
  handleSelection,
  handleSubmit,
  forUser,
}) {
  const [hasReservation, setHasReservation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation();

  const checkReservation = async () => {
    setHasReservation(true);
    setIsLoading(true);
    let parameter = { fromDate: startDate, period: period.key };
    if (forUser) parameter = { ...parameter, UserID: forUser.key };
    let reservations = await GetDeskReservations(location.key, parameter);
    setHasReservation(reservations.length > 0);
    setIsLoading(false);
  };

  useEffect(() => {
    if (startDate && period && location) {
      checkReservation();
    }
  }, [startDate, forUser, period, location]);

  return (
    <Stack verticalFill>
      {!location && (
        <EmptyMessage text={t("emptyMessages.makeReservation")} icon="List" />
      )}
      {hasReservation && !isLoading && (
        <MessageBar>
          {forUser
            ? forUser.text + t("emptyMessages.userHasReservation")
            : t("emptyMessages.alreadyHasReservation")}
        </MessageBar>
      )}
      {location && (
        <ReservationFloorplanComponent
          location={location.data}
          mobile={mobile}
          handleSelection={handleSelection}
          handleSubmit={handleSubmit}
          hasReservation={hasReservation}
          forUser={forUser}
          period={period}
          startDate={startDate}
        />
      )}
    </Stack>
  );
}

export default DeskReservation;
