import { DefaultButton, Modal, Stack, TextField } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { defaultModalInnerStyle } from "../../helpers/data";
import { useEffect, useState } from "react";

const TeamModal = ({
  isOpen,
  onSubmit,
  onDismiss,
  headlineText,
  submitButtonText,
}) => {
  const [teamName, setTeamName] = useState("");
  const { t } = useTranslation();

  const handleSubmit = () => {
    onDismiss();
    onSubmit(teamName);
  };

  useEffect(() => {
    setTeamName("");
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Stack className={defaultModalInnerStyle}>
        <h4 style={{ marginTop: 20, marginBottom: 20 }}>{headlineText}</h4>
        <TextField
          onChange={(e) => {
            setTeamName(e.target.value);
          }}
          value={teamName}
          label={t("teams.teamName")}
          required
        />
        <Stack
          horizontal
          horizontalAlign="end"
          tokens={{ childrenGap: 10 }}
          style={{ marginTop: 15 }}
        >
          <DefaultButton primary onClick={handleSubmit} disabled={!teamName}>
            {submitButtonText}
          </DefaultButton>
          <DefaultButton onClick={onDismiss}>{t("common.close")}</DefaultButton>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default TeamModal;
