import { Stack } from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EmptyMessage from "../components/General/Messages/EmptyMessage";
import { ReactConfigContext } from "../helpers/ReactConfigSetup";
import { useTitle } from "../helpers/useTitle";

const ReportPage = () => {
  const { t, i18n } = useTranslation();
  const [reportUrl, setReportUrl] = useState("");
  const reactConfig = useContext(ReactConfigContext);
  useTitle("Report");

  useEffect(() => {
    setReportUrl(reactConfig?.reportUrl);
  }, reactConfig);

  if (!reportUrl)
    return <EmptyMessage text={t("report.missing")} icon="Error" />;

  return (
    <Stack verticalFill verticalAlign="end">
      <iframe
        title="report"
        width="100%"
        height="100%"
        src={reportUrl}
        frameborder="0"
        allowFullScreen="true"
      ></iframe>
    </Stack>
  );
};

export default ReportPage;
