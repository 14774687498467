import { Stack } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/General/Header";
import BasicDropdown from "../components/General/Inputs/BasicDropdown";
import UserComboBox from "../components/General/Inputs/UserComboBox";
import EmptyMessage from "../components/General/Messages/EmptyMessage";
import UpcomingReservations from "../components/General/UpcomingReservations";
import { reservationToObject, sortByDate } from "../helpers/Functions";
import { defaultStackStyle } from "../helpers/data";
import { useTitle } from "../helpers/useTitle";
import {
  GetDeskReservations,
  GetLocations,
  GetParkingReservations,
} from "../services/LocationService";
import "../styles/custom.css";

const ViewRemoveReservationsPage = ({ parking }) => {
  const [user, setUser] = useState();
  const [location, setLocation] = useState();
  const [locations, setLocations] = useState([]);
  const [reset, setReset] = useState(false);
  const { t, i18n } = useTranslation();
  useTitle(
    parking
      ? "View or remove parking reservations"
      : "View or remove desk reservations"
  );

  const getLocations = async () => {
    let tempLocations = await GetLocations();
    setLocations(
      tempLocations
        .filter((l) => (parking ? l.parkingAllowed : l.hotdeskAllowed))
        .map((l) => {
          return { key: l.id, text: l.name };
        })
    );
  };

  const getReservations = useCallback(async () => {
    if (location || user) {
      let today = new Date();
      let parameter = {
        FromDate: today.toISOString().slice(0, 10),
        UserID: user?.key,
        locationId: location?.key,
      };
      let deskReservations = [];
      if (!parking) deskReservations = await GetDeskReservations(parameter);
      let parkingReservations = [];
      if (parking)
        parkingReservations = await GetParkingReservations(parameter);
      let tempItems = [];
      tempItems.push(
        ...deskReservations.map((r, i) => reservationToObject(r, i, "desk"))
      );
      tempItems.push(
        ...parkingReservations.map((r, i) =>
          reservationToObject(r, i + deskReservations.length, "parking")
        )
      );
      tempItems.sort(sortByDate);
      return tempItems;
    } else {
      return [];
    }
  }, [location, user]);

  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    setReset(!reset);
  }, [user, location]);

  const headertext = (item) => {
    return `${
      (item.user ? item.user.displayName + " - " : "") +
      (item.permanent
        ? "Permanent"
        : new Date(item.startDate).toLocaleDateString(i18n.language) +
          (item.morning && !item.afternoon
            ? " - Morning"
            : !item.morning && item.afternoon
            ? " - Afternoon"
            : ""))
    }`;
  };

  return (
    <Stack verticalFill className="page" tokens={defaultStackStyle}>
      <Header
        text={t(
          parking ? "sidebar.viewRemoveParking" : "sidebar.viewRemoveDesk"
        )}
      />
      <Stack tokens={{ childrenGap: 15 }} horizontal verticalAlign="end">
        <BasicDropdown
          label={t("common.location")}
          placeholder={t("common.selectLocation")}
          handleChange={setLocation}
          options={locations}
        />
        <UserComboBox
          handleSelect={(users) => {
            setUser(users.length > 0 ? users[0] : null);
          }}
        />
        <hr />
      </Stack>
      {location || user ? (
        <UpcomingReservations
          getItems={getReservations}
          reset={reset}
          getHeaderText={headertext}
        />
      ) : (
        <EmptyMessage
          text={t(
            parking
              ? "emptyMessages.viewParkingReservation"
              : "emptyMessages.viewDeskReservation"
          )}
          icon="List"
        />
      )}
    </Stack>
  );
};

export default ViewRemoveReservationsPage;
