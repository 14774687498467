import { getData } from "./ApiService";

export const GetUsers = async (filter = "") => {
  return getData("users", { filter: filter });
};

export const GetAllUsers = async () => {
  return getData("users/all");
};

export const GetCurrentUserRoles = async () => {
  return getData("users/roles");
};
