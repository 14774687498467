import {
  FontIcon,
  getTheme,
  Persona,
  PersonaSize,
  Pivot,
  PivotItem,
  SearchBox,
  Stack,
} from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ActionBarComponent from "../components/General/ActionBarComponent/ActionBarComponent";
import CustomMessageBar from "../components/General/Messages/CustomMessageBar";
import SubPage from "../components/General/SubPage";
import SubPageHeader from "../components/General/SubPageHeader";
import ConfirmationDialog from "../components/Modals/ConfirmationDialog";
import CreateGroupModal from "../components/Modals/CreateGroupModal";
import DeskPermissionModal from "../components/Modals/DeskPermissionModal";
import UserModal from "../components/Modals/UserModal";
import {
  deskUserColumns,
  deskUserColumnsWithDelete,
  groupColumns,
  groupPermissionColumns,
  largeStackStyle,
  officeColumns,
} from "../helpers/data";
import {
  AddOfficesToGroup,
  AddUsersToGroups,
  DeleteGroup,
  DeleteOfficeFromGroup,
  DeleteUserFromGroup,
  GetGroupByOffice,
  GetGroups,
  GetGroupsForUser,
  GetGroupUsers,
} from "../services/GroupService";
import { GetGroupsForOffices, GetOffices } from "../services/OfficeService";
import { GetAllUsers } from "../services/UserService";
import "../styles/custom.css";
import { useTitle } from "../helpers/useTitle";

const theme = getTheme();
const { palette, semanticColors, fonts } = theme;

const DeskPermissionPage = () => {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [officePermissions, setOfficePermissions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [offices, setOffices] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedPivot, setSelectedPivot] = useState("allUsers");
  const [usersGroups, setUsersGroups] = useState([]);
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [groupOffices, setGroupOffices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [groupUsers, setGroupUsers] = useState([]);
  const [createGroupModal, setCreateGroupModal] = useState(false);
  const [openEditGroupModal, setOpenEditGroupModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [groupUserModalIsOpen, setGroupUserModalIsOpen] = useState(false);
  const [deskPerModal, setDeskPerModal] = useState({ items: [] });
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const { t, i18n } = useTranslation();
  useTitle("Permissions");

  const getUsers = async () => {
    setLoading(true);
    setUsers(await GetAllUsers());
    setLoading(false);
  };
  const getGroups = async () => {
    setLoading(true);
    setGroups(await GetGroups());
    setLoading(false);
  };

  const getOffices = async () => {
    setLoading(true);
    setOffices(await GetOffices());
    setLoading(false);
  };

  const getGroupPivot = async (item) => {
    setLoading(true);
    let promises = [];
    promises.push(GetGroupUsers(item.id).then(setGroupUsers));
    promises.push(GetGroupByOffice(item.id).then(setOfficePermissions));
    return Promise.all(promises).then(() => setLoading(false));
  };

  const getGroupsForUser = async (item) => {
    setLoading(true);
    setUsersGroups(
      await GetGroupsForUser(encodeURIComponent(item.userPrincipalName))
    );
    setLoading(false);
  };

  const getGroupsForOffices = async (item) => {
    setLoading(true);
    setGroupOffices(await GetGroupsForOffices(item?.id));
    setLoading(false);
  };

  const dismissCreateGroupModal = () => {
    setCreateGroupModal(false);
  };

  const dismissEditGroupModal = () => {
    setOpenEditGroupModal(false);
  };

  const handleGroupCreate = async (e) => {
    if (e.response) {
      handleErrorMessage(e);
    }
    dismissCreateGroupModal();
    dismissEditGroupModal();
    getGroups();
  };

  useEffect(() => {
    getUsers();
  }, []);

  const deleteUserFromGroup = async (groupId, user) => {
    setLoading(true);
    await DeleteUserFromGroup(groupId, user);
    setOpenDeleteModal(false);
    if (selectedPivot == "selectedUser") {
      await getGroupsForUser(selectedUser);
    } else if (selectedPivot == "selectedGroup") {
      await getGroupPivot(selectedGroup);
    }
  };

  const deleteGroup = async (groupId) => {
    setLoading(true);
    await DeleteGroup(groupId);
    setOpenDeleteModal(false);
    groupId === selectedGroup.id && setSelectedGroup(null);
    getGroups();
    setLoading(false);
  };

  const deleteOfficeFromGroup = async (groupId, officeId) => {
    setLoading(true);
    await DeleteOfficeFromGroup(groupId, officeId);
    setOpenDeleteModal(false);
    if (selectedPivot == "selectedGroup") {
      await getGroupPivot(selectedGroup);
    } else if (selectedPivot == "selectedOffice") {
      await getGroupsForOffices(selectedOffice);
    }
    setLoading(false);
  };

  const addUserToGroup = async (group) => {
    setLoading(true);
    await AddUsersToGroups(group.id, [selectedUser])
      .then(() => getGroupsForUser(selectedUser))
      .catch(handleErrorMessage);
  };

  const addUserToSelectedGroup = async (user) => {
    setLoading(true);
    await AddUsersToGroups(selectedGroup.id, [user])
      .then(() => getGroupPivot(selectedGroup))
      .catch(handleErrorMessage);
  };

  const addOfficesToGroup = async (office) => {
    setLoading(true);
    await AddOfficesToGroup(selectedGroup.id, [office])
      .then(() => getGroupPivot(selectedGroup))
      .catch(handleErrorMessage);
  };

  const addGroupToOffice = async (group) => {
    setLoading(true);
    await AddOfficesToGroup(group.id, [selectedOffice])
      .then(() => getGroupsForOffices(selectedOffice))
      .catch(handleErrorMessage);
  };

  const handleDelete = () => {
    switch (selectedPivot) {
      case "selectedUser":
        deleteUserFromGroup(selectedItem.id, selectedUser);
        break;
      case "selectedGroup":
        selectedItem.deleteUser
          ? deleteUserFromGroup(selectedGroup.id, selectedItem)
          : deleteOfficeFromGroup(selectedGroup.id, selectedItem.id);
        break;
      case "selectedOffice":
        deleteOfficeFromGroup(selectedItem.id, selectedOffice.id);
        break;
      default:
        deleteGroup(selectedItem.id);
        break;
    }
  };

  const getDeleteMessage = useCallback(() => {
    switch (selectedPivot) {
      case "selectedOffice":
        return "deleteMessage.office";
      case "selectedUser":
        return "deleteMessage.group";
      case "selectedGroup":
        return "deleteMessage.office";
    }
  }, [selectedPivot]);

  const handleDeskPermissionModalData = useCallback(() => {
    let modalData = { modalOpen: true };
    switch (selectedPivot) {
      case "selectedUser":
        modalData = {
          ...modalData,
          onSubmit: addUserToGroup,
          text: "assignToGroup",
          items: groups,
          label: "groups",
          placeholder: "selectGroup",
        };
        break;
      case "selectedOffice":
        modalData = {
          ...modalData,
          onSubmit: addGroupToOffice,
          text: "addPermissionToGroup",
          items: groups.filter(
            (g) => !groupOffices.some((go) => g.id === go.id)
          ),
          label: "groups",
          placeholder: "selectGroup",
        };
        break;
      case "selectedGroup":
        modalData = {
          ...modalData,
          onSubmit: addOfficesToGroup,
          text: "addPermission",
          items: offices.filter(
            (o) => !officePermissions.some((op) => o.id === op.id)
          ),
          label: "permissions",
          placeholder: "selectPermission",
        };
        break;
    }
    setDeskPerModal(modalData);
  }, [offices, groups, officePermissions, groupOffices, selectedPivot]);

  const closeModal = () => {
    setDeskPerModal({ openModal: true, items: [] });
  };

  const handleErrorMessage = (e) => {
    setShowMessage(true);
    setMessage(e.response.data);
    setLoading(false);
  };

  const renderUsers = (item, index, column) => {
    const handleSelectUser = () => {
      setSelectedPivot("selectedUser");
      setSelectedUser(item);
      setSearchQuery("");
      getGroups();
      getGroupsForUser(item);
    };
    const fieldContent = item[column.key];
    switch (column.key) {
      case "delete":
        return (
          selectedPivot === "selectedGroup" && (
            <FontIcon
              iconName="Trash"
              className={"trash"}
              onClick={() => {
                setSelectedItem({
                  ...item,
                  deleteUser: true,
                  message: "deleteMessage.user",
                });
                setOpenDeleteModal(true);
              }}
            />
          )
        );
      case "view":
        return (
          <FontIcon
            iconName="More"
            className={"trash"}
            onClick={handleSelectUser}
          />
        );
      default:
        return (
          <Stack
            verticalFill
            verticalAlign="center"
            className="clickable"
            onClick={handleSelectUser}
          >
            {fieldContent}
          </Stack>
        );
    }
  };

  const renderGroups = (item, index, column) => {
    const handleSelectGroup = () => {
      setSelectedPivot("selectedGroup");
      setSelectedGroup(item);
      setSearchQuery("");
      getGroupPivot(item).then(getOffices);
    };
    const fieldContent = item[column.key];
    switch (column.key) {
      case "delete":
        return (
          <FontIcon
            iconName="Trash"
            className={"trash"}
            onClick={() => {
              setSelectedItem({ ...item, message: getDeleteMessage() });
              setOpenDeleteModal(true);
            }}
          />
        );
      case "view":
        return (
          <FontIcon
            iconName={selectedPivot == "selectedUser" ? "Forward" : "More"}
            className={"trash"}
            onClick={handleSelectGroup}
          />
        );
      case "edit":
        return (
          <FontIcon
            iconName={"Edit"}
            className={"trash"}
            onClick={() => {
              setSelectedGroup(item);
              setOpenEditGroupModal(true);
            }}
          />
        );
      default:
        return (
          <Stack
            verticalFill
            verticalAlign="center"
            className="clickable"
            onClick={handleSelectGroup}
          >
            {fieldContent}
          </Stack>
        );
    }
  };

  const renderOffices = (item, index, column) => {
    const handleSelectOffice = () => {
      setSelectedPivot("selectedOffice");
      setSelectedOffice(item);
      setSearchQuery("");
      getGroups();
      getGroupsForOffices(item);
    };
    let fieldContent = item[column.key];
    if (column.key == "name") {
      fieldContent = `${item.location?.name} - ${item.name}`;
    }
    switch (column.key) {
      case "view":
        return (
          <FontIcon
            iconName="More"
            className={"trash"}
            onClick={handleSelectOffice}
          />
        );
      case "delete":
        return (
          <FontIcon
            iconName="Trash"
            className={"trash"}
            onClick={() => {
              setSelectedItem({ ...item, message: getDeleteMessage() });
              setOpenDeleteModal(true);
            }}
          />
        );
      default:
        return (
          <Stack
            verticalFill
            verticalAlign="center"
            className="clickable"
            onClick={handleSelectOffice}
          >
            {fieldContent}
          </Stack>
        );
    }
  };

  return (
    <>
      <ConfirmationDialog
        modalText={t("common.delete")}
        isOpen={openDeleteModal}
        text={selectedItem?.message}
        onDismiss={() => setOpenDeleteModal(false)}
        onDelete={() => {
          handleDelete();
          setOpenDeleteModal(false);
        }}
      />
      <ActionBarComponent
        id="action-bar"
        actions={[
          {
            key: "group",
            text: t("management.createGroup"),
            icon: "",
            primary: true,
            action: () => setCreateGroupModal(true),
            disabled: false,
          },
        ]}
      />
      {showMessage && (
        <CustomMessageBar
          message={message}
          visible={showMessage}
          setShowSuccessMessage={() => setShowMessage(false)}
          error
        />
      )}
      <CreateGroupModal
        isOpen={createGroupModal}
        onDismiss={dismissCreateGroupModal}
        onSubmit={handleGroupCreate}
        title={t("management.addGroup")}
        acceptText={t("common.create")}
        create
      />
      <CreateGroupModal
        isOpen={openEditGroupModal}
        onDismiss={dismissEditGroupModal}
        onSubmit={handleGroupCreate}
        title={t("management.editGroup")}
        acceptText={t("common.edit")}
        selectedGroup={selectedGroup}
      />
      <DeskPermissionModal
        items={deskPerModal.items}
        isOpen={deskPerModal.modalOpen}
        onDismiss={closeModal}
        onSubmit={deskPerModal.onSubmit}
        text={t(`management.${deskPerModal.text}`)}
        label={t(`management.${deskPerModal.label}`)}
        placeholder={t(`management.${deskPerModal.placeholder}`)}
      />
      <Stack style={{ marginRight: "1%" }} verticalFill grow className="page">
        <Stack horizontalAlign="end">
          <SearchBox
            placeholder={t("common.search")}
            styles={{ root: { width: "20%" } }}
            underlined
            onChange={(e, newValue) => setSearchQuery(newValue)}
            value={searchQuery}
          />
        </Stack>
        <Pivot
          styles={{ itemContainer: { height: "95%", marginTop: "15px" } }}
          style={{ height: "100%" }}
          linkSize="large"
          selectedKey={selectedPivot.toString()}
          onLinkClick={(item) => {
            setSelectedPivot(item.props.itemKey);
            if (item.props.handleClick) item.props.handleClick();
          }}
        >
          <PivotItem
            handleClick={() => {
              setSearchQuery("");
              getUsers();
            }}
            style={{ height: "100%" }}
            headerText={t("management.allUsers")}
            itemKey="allUsers"
          >
            <SubPage
              items={users}
              columns={deskUserColumns}
              emptyMessageProps={{
                text: t("emptyMessages.noUsers"),
                icon: "Group",
              }}
              countText={t("management.users")}
              loading={loading}
              renderFunction={renderUsers}
              filter={searchQuery}
            />
          </PivotItem>
          <PivotItem
            handleClick={() => {
              setSearchQuery("");
              getGroups();
            }}
            style={{ height: "100%" }}
            headerText={t("management.groups")}
            itemKey="groups"
          >
            <SubPage
              items={groups}
              columns={groupColumns}
              emptyMessageProps={{
                text: t("emptyMessages.noGroups"),
                icon: "Group",
              }}
              countText={t("management.groups")}
              loading={loading}
              renderFunction={renderGroups}
              filter={searchQuery}
            />
          </PivotItem>
          <PivotItem
            handleClick={() => {
              setSearchQuery("");
              getOffices();
            }}
            style={{ height: "100%" }}
            headerText={t("management.permissions")}
            itemKey="offices"
          >
            <SubPage
              items={offices}
              columns={officeColumns}
              emptyMessageProps={{
                text: t("emptyMessages.noPermissions"),
                icon: "Nav2DMapView",
              }}
              countText={t("management.permissions")}
              loading={loading}
              renderFunction={renderOffices}
              filter={searchQuery}
            />
          </PivotItem>
          {selectedUser && (
            <PivotItem
              handleClick={() => {
                setSelectedPivot("selectedUser");
                setSearchQuery("");
                getGroupsForUser(selectedUser);
              }}
              style={{ height: "100%" }}
              headerText={selectedUser.displayName}
              itemKey="selectedUser"
            >
              <Stack verticalFill grow>
                <Persona
                  text={selectedUser.displayName}
                  secondaryText={selectedUser.userPrincipalName}
                  size={PersonaSize.size120}
                />
                <SubPageHeader
                  headerText={"groups"}
                  buttonText={"assignToGroup"}
                  style={{ marginTop: "20px" }}
                  openModal={() =>
                    getGroups().then(handleDeskPermissionModalData)
                  }
                />
                <SubPage
                  items={usersGroups}
                  columns={groupColumns}
                  emptyMessageProps={{
                    text: t("emptyMessages.noGroups"),
                    icon: "Group",
                  }}
                  countText={t("management.groups")}
                  loading={loading}
                  renderFunction={renderGroups}
                  filter={searchQuery}
                />
              </Stack>
            </PivotItem>
          )}
          {selectedGroup && (
            <PivotItem
              handleClick={() => {
                setSelectedPivot("selectedGroup");
                setSearchQuery("");
                getGroupPivot(selectedGroup);
              }}
              style={{ height: "100%" }}
              headerText={selectedGroup.name}
              itemKey="selectedGroup"
            >
              <UserModal
                items={groupUsers}
                isOpen={groupUserModalIsOpen}
                onDismiss={() => setGroupUserModalIsOpen(false)}
                onSubmit={addUserToSelectedGroup}
                text={t("management.addUser")}
                label={t("management.users")}
                placeholder={t("management.selectUser")}
              />
              <Stack verticalFill grow>
                <Stack
                  tokens={largeStackStyle}
                  horizontal
                  horizontalAlign="space-between"
                >
                  <Stack grow style={{ maxWidth: "50%" }}>
                    <SubPageHeader
                      headerText={"users"}
                      buttonText={"addUser"}
                      openModal={() => setGroupUserModalIsOpen(true)}
                    />
                    <SubPage
                      items={groupUsers}
                      columns={deskUserColumnsWithDelete}
                      emptyMessageProps={{
                        text: t("emptyMessages.noUsers"),
                        icon: "Group",
                      }}
                      countText={t("management.users")}
                      loading={loading}
                      renderFunction={renderUsers}
                      filter={searchQuery}
                    />
                  </Stack>
                  <Stack grow style={{ maxWidth: "50%" }}>
                    <SubPageHeader
                      headerText={"permissions"}
                      buttonText={"addPermission"}
                      openModal={handleDeskPermissionModalData}
                    />
                    <SubPage
                      items={officePermissions}
                      columns={groupPermissionColumns}
                      emptyMessageProps={{
                        text: t("emptyMessages.noPermissions"),
                        icon: "Nav2DMapView",
                      }}
                      countText={t("management.permissions")}
                      loading={loading}
                      renderFunction={renderOffices}
                      filter={searchQuery}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </PivotItem>
          )}
          {selectedOffice && (
            <PivotItem
              handleClick={() => {
                setSelectedPivot("selectedOffice");
                setSearchQuery("");
                getGroupsForOffices(selectedOffice);
              }}
              style={{ height: "100%" }}
              headerText={selectedOffice.name}
              itemKey="selectedOffice"
            >
              <Stack verticalFill grow>
                <SubPageHeader
                  headerText={"groups"}
                  buttonText={"addGroup"}
                  openModal={handleDeskPermissionModalData}
                />
                <SubPage
                  items={groupOffices}
                  columns={groupColumns}
                  emptyMessageProps={{
                    text: t("emptyMessages.noGroups"),
                    icon: "Group",
                  }}
                  countText={t("management.groups")}
                  loading={loading}
                  renderFunction={renderGroups}
                  filter={searchQuery}
                />
              </Stack>
            </PivotItem>
          )}
        </Pivot>
      </Stack>
    </>
  );
};

export default DeskPermissionPage;
