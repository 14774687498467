import { getData, postData, postDataPromise, putData } from "./ApiService";

export const GetOffices = async (locationId, parameter) => {
  return getData(`location/${locationId}/offices/permissions`, parameter);
};

export const CreateLocation = async (location) => {
  return postData("locations", location);
};

export const GetDesksWithStatus = async (locationId, parameter) => {
  return getData(`location/${locationId}/hotdesks`, parameter);
};

export const GetAllDesks = async (locationId) => {
  return getData(`location/${locationId}/hotdesks/all`);
};

export const GetParkingSpots = async (locationId) => {
  return getData(`location/${locationId}/parkingspots`);
};

export const GetLocations = async () => {
  return getData("locations");
};

export const GetLocation = async (id) => {
  return getData(`locations/${id}`);
};

export const GetAvailableParkingSpots = async (selectedLocation, parameter) => {
  return getData(
    `location/${selectedLocation}/availableparkingspots`,
    parameter
  );
};

export const GetDeskReservations = async (parameter) => {
  return getData(`deskreservations`, parameter);
};

export const GetParkingReservations = async (parameter) => {
  return getData(`parkingreservations`, parameter);
};

export const AddParkingReservation = async (locationId, reservation) => {
  return postDataPromise(
    `location/${locationId}/parkingreservation`,
    reservation
  );
};

export const UpdateLocation = async (locationId, location) => {
  return putData(`location/${locationId}`, location);
};

export const AddDeskReservation = async (
  selectedLocationKey,
  deskReservation
) => {
  return postDataPromise(
    `location/${selectedLocationKey}/deskreservation`,
    deskReservation
  );
};
