import { useTranslation } from "react-i18next";
import EmptyMessage from "../components/General/Messages/EmptyMessage";
import { useTitle } from "../helpers/useTitle";

const NoPermissionPage = () => {
  const { t, i18n } = useTranslation();
  useTitle("No permissions");
  return (
    <EmptyMessage text={t("emptyMessages.noPermissions")} icon="Blocked" />
  );
};

export default NoPermissionPage;
