import { MessageBar, MessageBarType, Stack } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Calendar from "../components/General/Calendar/Calendar";
import Header from "../components/General/Header";
import BasicDropdown from "../components/General/Inputs/BasicDropdown";
import UserComboBox from "../components/General/Inputs/UserComboBox";
import EmptyMessage from "../components/General/Messages/EmptyMessage";
import { parkingSpotsToObject } from "../helpers/Functions";
import {
  GetAvailableParkingSpots,
  GetLocations,
  GetParkingSpots,
} from "../services/LocationService";
import "../styles/custom.css";
import { useTitle } from "../helpers/useTitle";

const ManageParkingReservationPage = () => {
  const today = new Date();

  const [user, setUser] = useState();
  const [items, setItems] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locations, setLocations] = useState([]);
  const [period, setPeriod] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [lastTimeout, setLastTimeout] = useState(null);
  const [selectedParkingSpot, setSelectedParkingSpot] = useState(null);
  const [parkingSpots, setParkingSpots] = useState([]);
  const [yearAndMonth, setYearAndMonth] = useState([
    today.getFullYear(),
    today.getMonth() + 1,
  ]);
  const { t, i18n } = useTranslation();
  useTitle("Manage parking reservations");

  const periods = [
    { key: "halfDay", text: t("period.halfDay") },
    { key: "allDay", text: t("period.oneDay") },
  ];

  const getLocations = async () => {
    let tempLocations = await GetLocations();
    let tempArray = tempLocations
      .filter((l) => l.parkingAllowed)
      .map((location) => {
        return { key: location.id, text: location.name };
      });
    setLocations(tempArray);
  };

  useEffect(() => {
    getLocations();
    getParkingSpots();
  }, []);

  useEffect(() => {
    getParkingSpots();
  }, [selectedLocation, period, user, selectedParkingSpot]);

  useEffect(() => {
    getSpots();
  }, [selectedLocation]);

  const getSpots = async () => {
    if (selectedLocation) {
      let tempParkingSpots = await GetParkingSpots(selectedLocation);
      let tempArray = tempParkingSpots.map((ps) => {
        return { key: ps.id, text: ps.spotNumber };
      });
      setParkingSpots(tempArray);
    }
  };

  const handleReservationError = useCallback(
    (error) => {
      setErrorMessage(error.response.data);
      if (lastTimeout) {
        clearTimeout(lastTimeout);
      }
      setLastTimeout(
        setTimeout(() => {
          setErrorMessage("");
        }, 4000)
      );
    },
    [lastTimeout]
  );

  const closeMessage = useCallback(() => {
    clearTimeout(lastTimeout);
    setErrorMessage("");
  }, [lastTimeout]);

  const getParkingSpots = async () => {
    if (selectedLocation) {
      let promises = [];
      let parameter = {};
      if (user != null)
        parameter = {
          ...parameter,
          UserID: user.key,
          parkingSpot: selectedParkingSpot ? selectedParkingSpot.key : null,
        };
      if (!period) return;
      let tempArray = [];
      if (period.key === "halfDay") {
        let morningParameters = { ...parameter, Period: "morning" };
        let afternoonParameters = { ...parameter, Period: "afternoon" };
        promises.push(
          GetAvailableParkingSpots(selectedLocation, morningParameters).then(
            (ps) =>
              tempArray.push(
                ...ps.map((r) => parkingSpotsToObject(r, "morning"))
              )
          )
        );
        promises.push(
          GetAvailableParkingSpots(selectedLocation, afternoonParameters).then(
            (ps) =>
              tempArray.push(
                ...ps.map((r) => parkingSpotsToObject(r, "afternoon"))
              )
          )
        );
        await Promise.all(promises);
      } else {
        parameter = { ...parameter, Period: period.key };
        await GetAvailableParkingSpots(selectedLocation, parameter).then((ps) =>
          tempArray.push(...ps.map((r) => parkingSpotsToObject(r)))
        );
      }
      setItems(tempArray);
    }
  };

  const handleReservation = () => {
    return getParkingSpots();
  };

  const handleLocationSelect = (item) => {
    setSelectedLocation(item.key);
  };

  const handleParkingSpotSelect = (item) => {
    setSelectedParkingSpot(item);
  };

  return (
    <Stack verticalFill className="page">
      <Header text={t("sidebar.parkingReservation")} />
      {errorMessage && (
        <MessageBar
          messageBarType={MessageBarType.error}
          dismissButtonAriaLabel="Close"
          onDismiss={closeMessage}
        >
          {errorMessage}
        </MessageBar>
      )}
      <Stack tokens={{ childrenGap: 15 }} horizontal verticalAlign="end">
        <UserComboBox
          handleSelect={(users) => {
            setUser(users ? users[0] : null);
          }}
        />
        <BasicDropdown
          label={t("common.period")}
          placeholder={t("common.selectPeriod")}
          handleChange={setPeriod}
          options={periods}
          defaultItem={{ key: "allDay", text: t("period.oneDay") }}
        />
        <BasicDropdown
          label={t("common.location")}
          placeholder={t("common.selectLocation")}
          handleChange={handleLocationSelect}
          options={locations}
        />
        <BasicDropdown
          label={t("common.parkingSpot")}
          placeholder={t("common.selectParkingSpot")}
          handleChange={handleParkingSpotSelect}
          clearButton
          onClear={() => {
            setSelectedParkingSpot(null);
          }}
          options={parkingSpots}
        />
      </Stack>
      {user && selectedLocation ? (
        <Calendar
          yearAndMonth={yearAndMonth}
          onYearAndMonthChange={(yearAndMonth) => {
            setYearAndMonth(yearAndMonth);
          }}
          locationId={selectedLocation}
          onReservation={handleReservation}
          forUser={user}
          items={items}
          period={period}
          onError={handleReservationError}
        />
      ) : (
        <EmptyMessage
          text={t("emptyMessages.makeParkReservation")}
          icon="List"
        />
      )}
    </Stack>
  );
};

export default ManageParkingReservationPage;
