import { deleteData, getData, putData } from "./ApiService";

export const GetDeskReservations = async (locationId, parameter) => {
  return getData(`checkReservations/${locationId}`, parameter);
};

export const GetReservations = async () => {
  return getData("mydeskreservations");
};

export const DeleteReservation = async (id) => {
  return deleteData(`reservation/${id}`);
};

export const GetHotDesk = async (id) => {
  return getData(`hotdesks/${id}`);
};

export const ActivateHotDesk = async (id) => {
  return putData(`hotdesks/${id}/activate`);
};

export const DeactivateHotDesk = async (id) => {
  return putData(`hotdesks/${id}/deactivate`);
};
