import {
  Callout,
  DialogFooter,
  DirectionalHint,
  Stack,
  Text,
} from "@fluentui/react";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  GetDesks,
  GetFloorplan,
  GetOffices,
} from "../../services/FloorplanService";
import { GetDesksWithStatus } from "../../services/LocationService";
import { isLoading } from "../General/Loading";
import FloorplanComponent from "./FloorplanComponent";

const ReservationFloorplanComponent = ({
  location,
  handleSelection,
  handleSubmit,
  hasReservation,
  period,
  startDate,
  forUser,
  mobile,
}) => {
  const [desks, setDesks] = useState([]);
  const [offices, setOffices] = useState([]);
  const [floorplan, setFloorplan] = useState();
  const [radius, setRadius] = useState(13);
  const [image, setImage] = useState();
  const [deskObjects, setDeskObjects] = useState([]);
  const [officeObjects, setOfficeObjects] = useState([]);
  const [originalImageScale, setOriginalImageScale] = useState(1);
  const [originalImage, setOriginalImage] = useState();
  const [mouseEvent, setMouseEvent] = useState();
  const [loading, setLoading] = useState(false);
  const [disableReserve, setDisableReserve] = useState(false);
  const officeRefs = useRef([]);
  const deskRefs = useRef([]);
  const transformerRef = useRef();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let tempOfficeObjects = offices
      .filter((o) => o.officeObject)
      .map((o) => {
        return {
          id: o.id,
          x: o.officeObject.x * originalImageScale,
          y: o.officeObject.y * originalImageScale,
          width: o.officeObject.width * originalImageScale,
          height: o.officeObject.height * originalImageScale,
          name: o.name,
          allowed: false,
          saved: true,
        };
      });
    setOfficeObjects(tempOfficeObjects);
  }, [offices, originalImageScale]);

  useEffect(() => {
    let tempDesks = desks
      .filter((d) => d.deskObject)
      .map((d) => {
        return {
          id: d.id,
          x: d.deskObject.x * originalImageScale,
          y: d.deskObject.y * originalImageScale,
          status:
            d.status === "Unavailable" || !hasReservation
              ? d.status
              : "Disabled",
          number: d.deskNumber,
          office: d.office,
          reservations: d.reservations,
          saved: true,
        };
      });
    setDeskObjects(tempDesks);
  }, [desks, originalImageScale]);

  const handleImageChange = useCallback(() => {
    setOffices([]);
    setOfficeObjects([]);
    setDesks([]);
    setDeskObjects([]);
    setLoading(true);
    Promise.all([
      GetFloorplan(location.id).then((res) => {
        setFloorplan(res.floorplan);
        const url = window.URL.createObjectURL(
          new Blob(res.file, { type: "image/png" })
        );
        const orUrl = window.URL.createObjectURL(
          new Blob(res.file, { type: "image/png" })
        );
        const orImage = new Image();
        orImage.src = orUrl;
        orImage.onload = () => {
          setOriginalImage(orImage);
        };
        const image = new Image();
        image.src = url;
        image.onload = () => {
          let width = Math.min(image?.width, window.innerWidth * 0.8);
          let imageScale = width / image.width;
          setOriginalImageScale(imageScale);
          image.height = image?.height * imageScale;
          image.width = width;
          setImage(image);
        };
      }),
      getDesksWithStatus(),
      getOffices(),
    ]).then((r) => setLoading(false));
  }, [location]);

  useEffect(() => {
    handleImageChange();
  }, [location]);

  useEffect(() => {
    if (floorplan && originalImageScale) {
      setRadius(
        floorplan?.radius
          ? Math.round(floorplan.radius * originalImageScale)
          : 13
      );
    } else {
      setRadius(13);
    }
  }, [floorplan, originalImageScale]);

  useEffect(() => {
    setLoading(true);
    getDesksWithStatus().then((r) => setLoading(false));
  }, [startDate, period]);

  const onResize = useCallback(() => {
    let width = Math.min(originalImage?.width, window.innerWidth * 0.8);
    let imageScale = width / originalImage.width;
    setOriginalImageScale(imageScale);
    image.height = originalImage?.height * imageScale;
    image.width = width;
    setImage(image);
  }, [image, originalImage]);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [image]);

  const handleOnClick = (desk, e) => {
    let event = e.evt;
    if (window.TouchEvent && event instanceof TouchEvent) {
      let touches = event.changedTouches,
        first = touches[0],
        type = "";
      switch (event.type) {
        case "touchstart":
          type = "mousedown";
          break;
        case "touchmove":
          type = "mousemove";
          break;
        case "touchend":
          type = "mouseup";
          break;
        default:
          return;
      }

      event = new MouseEvent(type, {
        screenX: first.screenX,
        screenY: first.screenY,
        clientX: first.clientX,
        clientY: first.clientY,
      });
    }
    setMouseEvent(null);
    switch (desk.status) {
      case "Available":
        let newDesks = deskObjects.map((d) =>
          d.status === "Selected" ? { ...d, status: "Available" } : d
        );
        setDeskObjects(
          newDesks.map((d, i) => {
            if (d.status === "Available" && d.id === desk.id) {
              return {
                ...d,
                status: "Selected",
              };
            }
            return d;
          })
        );
        handleSelection(desk);
        setMouseEvent({ e: event, desk: desk });
        break;
      case "Selected":
        let unselect = deskObjects.map((d) =>
          d.status === "Selected" ? { ...d, status: "Available" } : d
        );
        setDeskObjects(unselect);
        handleSelection(null);
        break;
      case "Unavailable":
        setMouseEvent({ e: event, desk: desk });
        break;
    }
  };

  const getOffices = async () => {
    let offices = await GetOffices(location.id);
    setOffices(offices);
  };

  const getDesksWithStatus = async () => {
    if (period && startDate) {
      setDesks(
        await GetDesksWithStatus(location.id, {
          startDate: startDate,
          userId: forUser,
          period: period.key,
        })
      );
    } else {
      setDesks(await GetDesks(location.id));
    }
  };

  const getPeriod = (reservation, morning, afternoon) => {
    const startDate = new Date(reservation.startDate).toLocaleDateString(i18n.language);
    let checkPeriod =
      period.key === "oneDay" ||
      period.key === "morning" ||
      period.key === "afternoon";

    if (morning && afternoon) {
      return checkPeriod ? t("period.oneDay") : startDate;
    } else {
      if (morning && !afternoon) {
        return checkPeriod
          ? t("common.morning")
          : `${startDate} - ${t("common.morning")}`;
      } else {
        return checkPeriod
          ? t("common.afternoon")
          : `${startDate} - ${t("common.afternoon")}`;
      }
    }
  };

  const dismissCallout = () => {
    setMouseEvent(null);
    setDeskObjects(
      deskObjects.map((d) =>
        d.status === "Selected" ? { ...d, status: "Available" } : d
      )
    );
    setDisableReserve(false);
  };

  return (
    <>
      {isLoading(
        loading,
        <>
          {mouseEvent && (
            <Callout
              styles={{ root: { padding: 15, minWidth: 250 } }}
              directionalHint={DirectionalHint.rightCenter}
              role="dialog"
              gapSpace={0}
              target={mouseEvent.e}
              onDismiss={dismissCallout}
              setInitialFocus
            >
              <Stack vertical>
                {mouseEvent.desk.status === "Unavailable" ? (
                  <>
                    <Text>
                      {t("common.desk") + " " + mouseEvent.desk.number}
                    </Text>
                    <Text>{mouseEvent.desk.office.name}</Text>
                    <Text>{t("floorplan.reservedBy")}:</Text>
                    {mouseEvent.desk.reservations.map((r, i) => (
                      <Text key={i} style={{ paddingLeft: 5 }}>
                        {r.user.displayName} -{" "}
                        {getPeriod(r, r.morning, r.afternoon)}
                      </Text>
                    ))}
                  </>
                ) : (
                  <>
                    <Text>
                      {t("common.desk") + " " + mouseEvent.desk.number}
                    </Text>
                    <Text>{mouseEvent.desk.office.name}</Text>
                    <DialogFooter>
                      <PrimaryButton
                        text={t("common.reserve")}
                        onClick={() => {
                          setDisableReserve(true);
                          handleSubmit(mouseEvent.desk);
                        }}
                        disabled={
                          !period ||
                          !location ||
                          !startDate ||
                          !mouseEvent.desk ||
                          hasReservation ||
                          disableReserve
                        }
                      />
                    </DialogFooter>
                  </>
                )}
              </Stack>
            </Callout>
          )}
          <FloorplanComponent
            offices={officeObjects}
            drawing={false}
            desks={deskObjects}
            image={image}
            mobile={mobile}
            edit={false}
            rotation={window.innerWidth * 0.8 < window.innerHeight}
            radius={radius}
            handleDeskSelection={handleOnClick}
            officeRefs={officeRefs}
            deskRefs={deskRefs}
            setNewDesks={setDeskObjects}
            transformerRef={transformerRef}
          />
        </>
      )}
    </>
  );
};

export default ReservationFloorplanComponent;
