import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import React, { useCallback, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { LoginConfigContext } from "../../..";
import AxiosInterceptorsSetup from "../../../helpers/AxiosInterceptorsSetup";

const AxiosInterceptorInjector = (props) => {
  const { instance, accounts, inProgress } = useMsal();
  const history = useHistory();
  const loginRequest = useContext(LoginConfigContext);

  const GetToken = useCallback(async () => {
    const accessTokenRequest = {
      scopes: [loginRequest.scope],
      account: accounts[0],
    };
    let accessToken = null;
    try {
      accessToken = await instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          // Call your API with token
          return accessToken;
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
          console.log(error);
        });
    } catch (error) {
      console.log("AquireTokenSilent failure");
      console.log(error);
    }

    //accessToken = await msalAuth.acquireTokenRedirect({...accessTokenRequest, account: msalAuth.getAccount()});

    return accessToken ? accessToken : null;
  }, [accounts, loginRequest]);

  useEffect(() => {
    AxiosInterceptorsSetup(props.api, GetToken, history);
  }, [accounts]);

  return <></>;
};

export default AxiosInterceptorInjector;
