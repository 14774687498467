import moment from "moment/moment";

export const sortByDate = (a, b) => {
  let aFromDate = moment(a.startDate).toDate().getTime();
  let bFromDate = moment(b.startDate).toDate().getTime();
  if (aFromDate > bFromDate) {
    return 1;
  } else if (aFromDate < bFromDate) {
    return -1;
  } else {
    return 0;
  }
};

export const sortDesks = (a, b) => {
  if (!isNaN(a.deskNumber) && !isNaN(b.deskNumber)) {
    return Number(a.deskNumber) - Number(b.deskNumber);
  } else {
    if (a.deskNumber > b.deskNumber) {
      return -1;
    } else if (a.deskNumber < b.deskNumber) {
      return 1;
    } else {
      return 0;
    }
  }
};

export const reservationToObject = (reservation, index, type) => {
  const parking = type === "parking";
  return {
    key: index,
    id: reservation.id,
    number: parking
      ? reservation.parkingSpot?.spotNumber
      : reservation.hotDesk?.deskNumber,
    spotId: parking ? reservation.parkingSpot.id : reservation.hotDesk?.id,
    locationObj: parking
      ? reservation.parkingSpot?.location
      : reservation.hotDesk?.office?.location,
    location: parking
      ? reservation.parkingSpot?.location?.name
      : reservation.hotDesk?.office?.name,
    startDate: reservation.startDate,
    morning: reservation.morning,
    afternoon: reservation.afternoon,
    permanent: reservation.permanent,
    toDate: reservation.endDate,
    user: reservation.user,
    type: type,
  };
};

export const parkingSpotsToObject = (parkingSpot, type) => {
  let ps = {
    date: parkingSpot.date,
    status: parkingSpot.status,
    parkingSpotID: parkingSpot.id,
    parkingSpot: parkingSpot.spotNumber,
    reservationID: parkingSpot.reservationID,
    reservations: parkingSpot.reservations,
    assignedParkingSpot: parkingSpot.assignedParkingSpot,
  };
  if (type) ps.period = type;
  return ps;
};
