import {
  DefaultButton,
  DialogFooter,
  Label,
  Modal,
  PrimaryButton,
  Stack,
  TextField,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  defaultModalInnerStyle,
  defaultModalStyle,
  defaultStackStyle,
  labelHeaderStyle,
} from "../../helpers/data";

const CustomModal = ({ isOpen, onDismiss, onSubmit, modalText }) => {
  const [name, setName] = useState("");
  const [disableCreate, setDisableCreate] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setName("");
    setDisableCreate(false);
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      containerClassName={defaultModalStyle}
    >
      <Stack tokens={defaultStackStyle} className={defaultModalInnerStyle}>
        <Stack horizontal>
          <Label className={labelHeaderStyle}>{modalText}</Label>
        </Stack>
        <TextField
          label={t("common.name")}
          required
          styles={{ root: { maxWidth: "200px" } }}
          onChange={(e, newValue) => setName(newValue ? newValue : "")}
        />
        <DialogFooter>
          <PrimaryButton
            disabled={name.length == 0 || disableCreate}
            onClick={() => {
              setDisableCreate(true);
              onSubmit(name);
            }}
          >
            {t("common.create")}
          </PrimaryButton>
          <DefaultButton onClick={onDismiss}>
            {t("common.cancel")}
          </DefaultButton>
        </DialogFooter>
      </Stack>
    </Modal>
  );
};

export default CustomModal;
