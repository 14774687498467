import {getData} from "./ApiService";

export const GetDeskLocationData = async (id) => {
    return getData(`related/desklocation/${id}`)
}

export const GetOfficeData = async (id) => {
    return getData(`related/office/${id}`)
}

export const GetDeskData = async (id) => {
    return getData(`related/desk/${id}`)
}

export const GetParkingLocationData = async (id) => {
    return getData(`related/parkinglocation/${id}`)
}

export const GetParkingSpotData = async (id) => {
    return getData(`related/parkingspot/${id}`)
}