import { ColorPicker, IconButton, Stack, TextField } from "@fluentui/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SettingsContext } from "../App";
import ActionBarComponent from "../components/General/ActionBarComponent/ActionBarComponent";
import Header from "../components/General/Header";
import { isLoading } from "../components/General/Loading";
import CustomMessageBar from "../components/General/Messages/CustomMessageBar";
import ConfirmationDialog from "../components/Modals/ConfirmationDialog";
import {
  DeleteConfigValue,
  GetConfig,
  GetConfigFile,
  UpdateConfigFile,
  UpdateConfigValue,
} from "../services/ConfigService";
import "../styles/custom.css";
import { useTitle } from "../helpers/useTitle";

const ConfigurationPage = () => {
  const [colorValue, setColorValue] = useState("");
  const [logo, setLogo] = useState();
  const [newLogo, setNewLogo] = useState();
  const [parkingDays, setParkingDays] = useState();
  const [deskDays, setDeskDays] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [configToReset, setConfigToReset] = useState(null);
  const settingsContext = useContext(SettingsContext);
  const { t, i18n } = useTranslation();
  useTitle("Configuration page");

  const GetConfigs = async () => {
    setLoading(true);
    let promises = [];
    promises.push(GetConfig("color").then((c) => setColorValue(c.data)));
    promises.push(GetConfig("parkingDays").then((c) => setParkingDays(c.data)));
    promises.push(GetConfig("deskDays").then((c) => setDeskDays(c.data)));
    promises.push(
      GetConfigFile("logo").then((res) => {
        if (res.data?.size) {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: "image/png" })
          );
          setLogo(url);
        }
      })
    );
    await Promise.all(promises);
    setLoading(false);
  };

  useEffect(() => {
    GetConfigs();
  }, []);

  const onSave = () => {
    let promises = [];
    promises.push(UpdateConfigValue("color", colorValue));
    promises.push(UpdateConfigValue("parkingDays", parkingDays));
    promises.push(UpdateConfigValue("deskDays", deskDays));
    if (newLogo) promises.push(UpdateConfigFile("logo", newLogo));
    if (settingsContext.setColor) settingsContext.setColor(colorValue);
    Promise.all(promises).then(() => {
      openSuccessMessage();
      GetConfigs();
    });
  };

  const handleOnFileChange = (e) => {
    const files = e.target.files || [];
    if (files) {
      setNewLogo(files[0]);
    }
  };

  const openSuccessMessage = () => {
    setSuccessMessage(t("successMessages.saved"));
    setShowSuccessMessage(true);
  };

  const handleParkingDays = (days) => {
    if (isNaN(days) || days === "" || days <= 0) {
      setMessage("Not a valid number");
      setDisableSave(true);
    } else {
      setMessage("");
      setDisableSave(false);
    }
    setParkingDays(days);
  };

  const handleDeskDays = (days) => {
    if (isNaN(days) || days === "" || days <= 0) {
      setMessage("Not a valid number");
      setDisableSave(true);
    } else {
      setMessage("");
      setDisableSave(false);
    }
    setDeskDays(days);
  };

  const colorPickerStyles = {
    panel: { padding: 12 },
    root: {
      maxWidth: 352,
      minWidth: 352,
    },
    colorRectangle: { height: 268 },
  };

  const handleDelete = () => {
    switch (configToReset.key) {
      case "Logo":
        handleLogoReset();
        break;
      case "Color":
        handleColorReset();
        break;
    }
  };

  const handleColorReset = () => {
    DeleteConfigValue("color");
    setColorValue("0078D4");
    if (settingsContext.setColor) settingsContext.setColor("0078D4");
    openSuccessMessage();
  };

  const handleLogoReset = () => {
    DeleteConfigValue("logo");
    setLogo();
    openSuccessMessage();
  };

  const updateColor = useCallback(
    (ev, colorObj) => setColorValue(colorObj.hex),
    []
  );
  return (
    <>
      <ConfirmationDialog
        modalText={t("common.delete")}
        isOpen={openDeleteModal}
        text={configToReset?.message}
        onDismiss={() => setOpenDeleteModal(false)}
        onDelete={() => {
          handleDelete();
          setOpenDeleteModal(false);
        }}
      />
      <ActionBarComponent
        id="action-bar"
        actions={[
          {
            key: "save",
            text: t("common.save"),
            icon: "",
            primary: true,
            action: onSave,
            disabled: disableSave,
          },
        ]}
      />
      {showSuccessMessage && (
        <CustomMessageBar
          message={successMessage}
          visible={showSuccessMessage}
          setShowSuccessMessage={() => setShowSuccessMessage(false)}
        />
      )}
      {isLoading(
        loading,
        <Stack
          style={{ marginTop: 30, overflow: "auto" }}
          tokens={{ childrenGap: 20 }}
          className="page">
          <Header text={t("config.information")} />
          <Stack horizontal verticalAlign={"baseline"}>
            <h5>Logo</h5>
            <IconButton
              iconProps={{
                iconName: "Trash",
                styles: { root: { fontSize: 13 } },
              }}
              title={t("config.removeLogo")}
              ariaLabel={t("config.removeLogo")}
              style={{
                height: "23px",
                width: "23px",
              }}
              onClick={() => {
                setConfigToReset({ key: "Logo", message: t("config.logo") });
                setOpenDeleteModal(true);
              }}
            />
          </Stack>
          {(logo || newLogo) && (
            <img
              src={newLogo ? URL.createObjectURL(newLogo) : logo}
              width={120}
              alt="logo"
            />
          )}
          <div style={{ maxWidth: "400px" }}>
            <label className="file-upload" htmlFor="logoFile">
              {!newLogo ? t("floorplan.upload") : t("floorplan.uploaded")}
            </label>
            <input
              id="logoFile"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleOnFileChange}
            />
          </div>
          <Stack horizontal verticalAlign={"baseline"}>
            <h5>{t("config.company")}</h5>
            <IconButton
              iconProps={{
                iconName: "Trash",
                styles: { root: { fontSize: 13 } },
              }}
              title={t("config.resetColor")}
              ariaLabel={t("config.resetColor")}
              style={{
                height: "23px",
                width: "23px",
              }}
              onClick={() => {
                setConfigToReset({ key: "Color", message: t("config.color") });
                setOpenDeleteModal(true);
              }}
            />
          </Stack>
          <Stack>
            <ColorPicker
              color={`#${colorValue}`}
              onChange={updateColor}
              alphaType={"none"}
              showPreview
              styles={colorPickerStyles}
              strings={{
                hueAriaLabel: "Hue",
              }}
            />
          </Stack>
          <h5>{t("config.booking")}</h5>
          <span>{t("config.deskRule")}</span>
          <Stack tokens={{ childrenGap: 5 }} horizontal verticalAlign="center">
            <TextField
              style={{ width: 50 }}
              value={deskDays}
              onChange={(e, newValue) => handleDeskDays(newValue)}
              suffix={t("config.days")}
              errorMessage={message}
            />
          </Stack>
          <span>{t("config.parkingRule")}</span>
          <Stack tokens={{ childrenGap: 5 }} horizontal verticalAlign="center">
            <TextField
              style={{ width: 50 }}
              value={parkingDays}
              onChange={(e, newValue) => handleParkingDays(newValue)}
              suffix={t("config.days")}
              errorMessage={message}
            />
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default ConfigurationPage;
