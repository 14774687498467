import { Stack, Text } from "@fluentui/react";
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LogoutPage = () => {
  const { t, i18n } = useTranslation();
  return (
    <Stack horizontalAlign="center" verticalAlign="center" verticalFill>
      <Text variant="mediumPlus">{t("successMessages.logout")}</Text>
      <Link to={"/"}>
        <Text variant="mediumPlus" className="ActionLink">
          {t("emptyMessages.login")}
        </Text>
      </Link>
    </Stack>
  );
};

export default LogoutPage;
