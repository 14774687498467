import React from "react";
import { Group, Rect, Text } from "react-konva";

const OfficeComponent = ({
  office,
  officeRefs,
  edit,
  index,
  handleTransform,
  handleDragMove,
}) => {
  const officeFill = edit
    ? "#3330FF30"
    : office.allowed
    ? "#cee2b350"
    : "#2222221a";
  return (
    <Group key={index}>
      <Rect
        ref={(node) => {
          officeRefs.current[index] = node;
        }}
        key={index}
        x={office.x}
        y={office.y}
        onTransform={() => handleTransform(index)}
        onDragMove={() => handleDragMove(false, index)}
        draggable={!office.saved}
        width={office.width}
        height={office.height}
        fill={
          office.view ? "#0000FF55" : office.selected ? "#65CE1377" : officeFill
        }
      />
      {false && (
        <Text
          x={office.x + 10}
          y={office.y + 10}
          text={office.name}
          fontSize={17}
          fill={"#111111"}
        />
      )}
    </Group>
  );
};

export default OfficeComponent;
