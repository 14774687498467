import {
  DetailsList,
  IconButton,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ActionBarComponent from "../components/General/ActionBarComponent/ActionBarComponent";
import Header from "../components/General/Header";
import Loading from "../components/General/Loading";
import EmptyMessage from "../components/General/Messages/EmptyMessage";
import DeleteModal from "../components/Modals/DeleteModal";
import LocationModal from "../components/Modals/LocationModal";
import { CreateFloorplan, DeleteLocation } from "../services/FloorplanService";
import { CreateLocation, GetLocations } from "../services/LocationService";
import {
  GetDeskLocationData,
  GetParkingLocationData,
} from "../services/RelatedDataService";
import ProcessingComponent from "../components/General/ProcessingComponent";
import { useTitle } from "../helpers/useTitle";

const FloorplansPage = ({ parking }) => {
  const history = useHistory();
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openCreateLocation, setOpenCreateLocation] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [relatedData, setRelatedData] = useState({});
  const [showProcess, setShowProcess] = useState(false);
  const [processActions, setProcessActions] = useState();
  const { t, i18n } = useTranslation();
  let baseUrl = parking
    ? "/parkingmanagement/floorplans"
    : "/deskmanagement/floorplans";
  useTitle(parking ? "Manage parking floorplans" : "Manage floorplans");

  const onDelete = async (id) => {
    setOpenDeleteModal(false);
    setProcessActions([DeleteLocation(id)]);
    setShowProcess(true);
  };

  const getDeskLocationData = async (id) => {
    setOpenDeleteModal(true);
    setLoading(true);
    setRelatedData(await GetDeskLocationData(id));
    setLoading(false);
  };

  const getParkingLocationData = async (id) => {
    setOpenDeleteModal(true);
    setLoading(true);
    setRelatedData(await GetParkingLocationData(id));
    setLoading(false);
  };

  const floorPlanColumns = [
    {
      name: t("common.name"),
      key: "name",
      fieldName: "name",
      onRender: (item) => (
        <Stack verticalAlign="center" verticalFill>
          <Text
            style={{ cursor: "pointer" }}
            onClick={() => history.push(`${baseUrl}/${item.id}`)}
            variant="mediumPlus"
          >
            {item.name}
          </Text>
        </Stack>
      ),
    },
    {
      name: t("common.edit"),
      key: "edit",
      onRender: (item) => (
        <IconButton
          onClick={() => history.push(`${baseUrl}/${item.id}`)}
          iconProps={{ iconName: "More" }}
        />
      ),
    },
    {
      name: t("common.delete"),
      key: "remove",
      onRender: (item) => (
        <IconButton
          onClick={() => {
            setSelectedItem(item);
            parking
              ? getParkingLocationData(item.id)
              : getDeskLocationData(item.id);
          }}
          iconProps={{ iconName: "Trash" }}
        />
      ),
    },
  ];

  const getLocations = async () => {
    setLoading(true);
    let data = await GetLocations();
    setLocations(
      data.filter(
        (d) => (d.hotdeskAllowed && !parking) || (d.parkingAllowed && parking)
      )
    );
    setLoading(false);
  };

  const handleCreateLocations = async (name, file) => {
    let location = await CreateLocation({
      name: name,
      hotdeskAllowed: !parking,
      parkingAllowed: parking,
    });
    if (file) {
      await CreateFloorplan(location.id, file);
    }
    setOpenCreateLocation(false);
    getLocations();
  };

  useEffect(() => {
    getLocations();
  }, []);

  if (loading && !openDeleteModal) {
    return (
      <Stack verticalFill verticalAlign="center">
        <Loading />
      </Stack>
    );
  }

  return (
    <Stack verticalFill>
      <ActionBarComponent
        actions={[
          {
            text: t("floorplan.create"),
            primary: true,
            action: () => setOpenCreateLocation(true),
          },
        ]}
      />
      {showProcess && (
        <ProcessingComponent
          actions={processActions}
          onFinish={() => {
            setShowProcess(false);
            getLocations();
          }}
        />
      )}
      <LocationModal
        isOpen={openCreateLocation}
        parking={parking}
        onDismiss={() => setOpenCreateLocation(false)}
        onSubmit={handleCreateLocations}
      />
      <DeleteModal
        isOpen={openDeleteModal}
        loading={loading}
        modalText={t("deleteMessage.confirmDelete")}
        onDismiss={() => setOpenDeleteModal(false)}
        onDelete={() => onDelete(selectedItem.id)}
        location={selectedItem}
        data={relatedData}
        parking={parking}
      />
      <Stack verticalFill className="page">
        <Header
          text={
            parking
              ? t("floorplan.parkingFloorplans")
              : t("floorplan.deskFloorplans")
          }
          style={{ paddingTop: "15px" }}
        />
        {locations.length !== 0 ? (
          <Stack
            styles={{
              root: { maxHeight: "88vh", overflow: "auto" },
            }}
          >
            <DetailsList
              selectionMode={SelectionMode.none}
              items={locations}
              columns={floorPlanColumns}
            />
          </Stack>
        ) : (
          <EmptyMessage text={t("emptyMessages.noLocations")} icon={"Cancel"} />
        )}
      </Stack>
    </Stack>
  );
};

export default FloorplansPage;
