import { deleteData, getData, postData, putData } from "./ApiService";

export const AddNewOffice = (id, office) => {
  return postData(`floorplan/${id}/offices`, office);
};

export const AddNewDesk = (id, desks) => {
  return postData(`floorplan/${id}/desks`, desks);
};

export const AddNewParkingSpot = (id, parkingSpot) => {
  return postData(`floorplan/${id}/parkingspots`, parkingSpot);
};

export const GetOffices = async (id) => {
  return getData(`floorplan/${id}/offices`);
};

export const GetDesks = async (id) => {
  return getData(`floorplan/${id}/desks`);
};

export const GetParkingSpots = async (id) => {
  return getData(`floorplan/${id}/parkingspots`);
};

export const CreateFloorplan = async (id, file) => {
  const formData = new FormData();

  formData.append("File", file);

  return postData(`floorplan/${id}`, formData);
};

export const GetFloorplan = async (id) => {
  var cache = JSON.parse(localStorage.getItem("floorplans"));
  if (Array.isArray(cache)) {
    let cachedFloorplan = cache.find((i) => i.locationId == id);
    if (cachedFloorplan) {
      let changedFloorplan = await CheckFloorplan(
        cachedFloorplan.locationId,
        cachedFloorplan.modified
      );
      if (!changedFloorplan.value) {
        return {floorplan: cachedFloorplan, file: b64toBlop(cachedFloorplan.floorplan)};
      }
      CacheFloorplan(cache, changedFloorplan.changedObject);
      return {floorplan: changedFloorplan.changedObject, file: b64toBlop(changedFloorplan.changedObject.floorplan)};
    }
  } else {
    cache = [];
  }
  let floorplan = await getData(`floorplan/${id}/object`);
  CacheFloorplan(cache, floorplan);
  return {floorplan: floorplan, file: b64toBlop(floorplan.floorplan)};
};

const CacheFloorplan = (cache, floorplan) => {
  cache = cache.filter((f) => f.id != floorplan.id);
  cache.push(floorplan);
  localStorage.setItem("floorplans", JSON.stringify(cache));
};

const b64toBlop = (b64Data, sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return byteArrays;
};

export const UpdateFloorplanFile = async (id, file) => {
  const formData = new FormData();

  formData.append("File", file);

  return putData(`floorplan/${id}/file`, formData);
};

export const UpdateFloorplan = async (id, floorplan) => {
  return putData(`floorplan/${id}`, floorplan);
};

export const UpdateHotDesk = async (id, desk) => {
  return putData(`floorplan/${id}/desks`, desk);
};

export const UpdateOffice = async (id, office) => {
  return putData(`floorplan/${id}/offices`, office);
};

export const UpdateParkingSpot = async (id, parkingSpot) => {
  return putData(`floorplan/${id}/parkingspots`, parkingSpot);
};

export const CheckFloorplan = async (id, modified) => {
  return getData(`floorplan/${id}/changed`, { modified: modified });
};

export const DeleteLocation = async (id) => {
  return deleteData(`floorplan/${id}`);
};

export const DeleteHotDesk = async (id) => {
  return deleteData(`floorplan/${id}/hotdesks`);
};

export const deleteOffice = async (id) => {
  return deleteData(`floorplan/${id}/offices`);
};

export const deleteParkingSpot = async (id) => {
  return deleteData(`floorplan/${id}/parking-spot`);
};
