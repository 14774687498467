import { MessageBar, MessageBarType, Stack, StackItem } from "@fluentui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AddNewDesk,
  AddNewOffice,
  DeleteHotDesk,
  GetDesks,
  GetFloorplan,
  GetOffices,
  UpdateFloorplan,
  UpdateFloorplanFile,
  UpdateHotDesk,
  UpdateOffice,
  deleteOffice,
} from "../../services/FloorplanService";
import { UpdateLocation } from "../../services/LocationService";
import { GetDeskData, GetOfficeData } from "../../services/RelatedDataService";
import ActionBarComponent from "../General/ActionBarComponent/ActionBarComponent";
import Header from "../General/Header";
import ProcessingComponent from "../General/ProcessingComponent";
import CustomModal from "../Modals/CustomModal";
import DeleteModal from "../Modals/DeleteModal";
import DeskModal from "../Modals/DeskModal";
import EditModal from "../Modals/EditModal";
import LocationModal from "../Modals/LocationModal";
import DeskRadiusModal from "./DeskRadiusModal";
import FloorplanComponent from "./FloorplanComponent";
import OfficeFloorplanSidebar from "./FloorplanSidebar/OfficeFloorplanSidebar";
import { ActivateHotDesk, DeactivateHotDesk } from "../../services/DeskService";
import { update } from "ramda";

const EditOfficeFloorplanComponent = ({ location }) => {
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [openOfficeModal, setOpenOfficeModal] = useState(false);
  const [openDeskModal, setOpenDeskModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openRadiusModal, setRadiusModal] = useState(false);
  const [locationEdited, setLocation] = useState({ ...location });
  const [floorplan, setFloorplan] = useState();
  const [drawing, setDrawing] = useState(false);
  const [allowDrawing, setAllowDrawing] = useState(false);
  const [deskActive, setDeskActive] = useState(false);
  const [editing, setEditing] = useState(false);
  const [deskEditing, setDeskEditing] = useState(false);
  const [officeEditing, setOfficeEditing] = useState(false);
  const [desks, setDesks] = useState([]);
  const [deskObjects, setDeskObjects] = useState([]);
  const [officeObjects, setOfficeObjects] = useState([]);
  const [offices, setOffices] = useState([]);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 });
  const [originalImage, setOriginalImage] = useState();
  const [image, setImage] = useState();
  const [activeOfficeId, setActiveOfficeId] = useState();
  const [activeDeskId, setActiveDeskId] = useState();
  const [officeSaved, setOfficeSaved] = useState(true);
  const [selectedOffice, setSelectedOffice] = useState();
  const [selectedDesk, setSelectedDesk] = useState();
  const [drawingOffice, setDrawingOffice] = useState();
  const [originalImageScale, setOriginalImageScale] = useState(1);
  const [countMap, setCountMap] = useState();
  const [officeTooltip, setOfficeTooltip] = useState(false);
  const [deskTooltip, setDeskTooltip] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [radius, setRadius] = useState(10);
  const [relatedData, setRelatedData] = useState({});
  const [loading, setLoading] = useState(false);
  const [disableDone, setDisableDone] = useState(false);
  const [showProcess, setShowProcess] = useState(false);
  const [processActions, setProcessActions] = useState();
  const officeRefs = useRef([]);
  const deskRefs = useRef([]);
  const transformerRef = useRef();
  const { t, i18n } = useTranslation();

  const getOffices = async () => {
    let tempOffices = await GetOffices(locationEdited.id);
    setOffices(tempOffices);
  };

  const getDesks = async () => {
    let desks = await GetDesks(locationEdited.id);
    setDesks(desks);
  };

  const onResize = useCallback(() => {
    let width = Math.min(originalImage?.width, (window.innerWidth - 100) * 0.8);
    let imageScale = width / originalImage.width;
    setOriginalImageScale(imageScale);
    image.height = originalImage?.height * imageScale;
    image.width = width;
    setImage(image);
  }, [image, originalImage]);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [image]);

  useEffect(() => {
    let tempOfficeObjects = offices
      .filter((o) => o.officeObject)
      .map((o) => {
        return {
          id: o.id,
          x: o.officeObject.x * originalImageScale,
          y: o.officeObject.y * originalImageScale,
          width: o.officeObject.width * originalImageScale,
          height: o.officeObject.height * originalImageScale,
          name: o.name,
          saved: true,
        };
      });
    setOfficeObjects(tempOfficeObjects);
  }, [offices, originalImageScale]);

  useEffect(() => {
    let tempDesks = desks
      .filter((d) => d.deskObject)
      .map((d) => {
        return {
          id: d.id,
          x: d.deskObject.x * originalImageScale,
          y: d.deskObject.y * originalImageScale,
          active: d.active,
          status: "Available",
          number: d.deskNumber,
          saved: true,
        };
      });
    setDeskObjects(tempDesks);
  }, [desks, originalImageScale]);

  useEffect(() => {
    const countMap = desks.reduce((acc, desk) => {
      const officeId = desk.officeId;
      const deskNumber = desk.deskNumber;
      if (!acc[officeId] || deskNumber > acc[officeId]) {
        acc[officeId] = deskNumber;
      }
      return acc;
    }, {});
    offices.forEach((office) => {
      if (!countMap[office.id]) {
        countMap[office.id] = 0;
      }
    });
    setCountMap(countMap);
  }, [desks, offices]);

  const handleImageChange = () => {
    GetFloorplan(locationEdited.id).then((res) => {
      setFloorplan(res.floorplan);
      const url = window.URL.createObjectURL(
        new Blob(res.file, { type: "image/png" })
      );
      const orUrl = window.URL.createObjectURL(
        new Blob(res.file, { type: "image/png" })
      );
      const orImage = new Image();
      orImage.src = orUrl;
      orImage.onload = () => {
        setOriginalImage(orImage);
      };
      const image = new Image();
      image.src = url;
      image.onload = () => {
        let width = Math.min(image?.width, window.innerWidth * 0.8);
        let imageScale = width / image.width;
        setOriginalImageScale(imageScale);
        image.height = image?.height * imageScale;
        image.width = width;
        setImage(image);
      };
      getOffices();
      getDesks();
    });
  };

  useEffect(() => {
    handleImageChange();
  }, []);

  useEffect(() => {
    if (floorplan && originalImageScale) {
      setRadius(
        floorplan?.radius
          ? Math.round(floorplan.radius * originalImageScale)
          : 13
      );
    } else {
      setRadius(13);
    }
  }, [floorplan, originalImageScale]);

  const addDesks = async (desk) => {
    await AddNewDesk(locationEdited.id, desk);
  };
  const addOffice = async (office) => {
    await AddNewOffice(locationEdited.id, office);
  };

  const handleMouseUp = useCallback(() => {
    if (!drawing) return;

    if (deskActive) {
      const newDesk = {
        x: Math.min(startPos.x, currentPos.x),
        y: Math.min(startPos.y, currentPos.y),
        radius: 25,
        status: "Available",
        saved: false,
        id: deskObjects.length,
      };
      setDeskObjects((prevDesks) => [...prevDesks, newDesk]);
    } else {
      setDrawingOffice(null);
      const newOffice = {
        x: Math.min(startPos.x, currentPos.x),
        y: Math.min(startPos.y, currentPos.y),
        width:
          Math.abs(currentPos.x - startPos.x) < 10
            ? 10
            : Math.abs(currentPos.x - startPos.x),
        height:
          Math.abs(currentPos.y - startPos.y) < 10
            ? 10
            : Math.abs(currentPos.y - startPos.y),
        saved: false,
        id: officeObjects.length,
        name: selectedOffice.name,
      };
      setOfficeObjects((prevOffices) => [...prevOffices, newOffice]);
      setTimeout(() => {
        if (activeOfficeId !== null && officeRefs.current[activeOfficeId]) {
          transformerRef.current.rotateEnabled(false);
          transformerRef.current.nodes([officeRefs.current[activeOfficeId]]);
          transformerRef.current.getLayer().batchDraw();
        }
      }, 0);
    }
    setDrawing(false);
    setDisableDone(false);
  }, [
    drawing,
    startPos,
    currentPos,
    offices,
    officeObjects,
    activeOfficeId,
    desks,
    deskObjects,
  ]);

  const handleMouseMove = useCallback(
    (e) => {
      if (!drawing) return;

      const stage = e.target.getStage();
      const { x, y } = stage.getPointerPosition();
      const { x: stageX, y: stageY } = stage.getPosition();
      const stageScale = stage.getScale();
      const transformedPointerPos = {
        x: (x - stageX) / stageScale.x,
        y: (y - stageY) / stageScale.y,
      };
      const newOffice = {
        x: Math.min(startPos.x, transformedPointerPos.x),
        y: Math.min(startPos.y, transformedPointerPos.y),
        width: Math.abs(transformedPointerPos.x - startPos.x),
        height: Math.abs(transformedPointerPos.y - startPos.y),
        saved: false,
        id: officeObjects.length,
        name: selectedOffice.name,
      };
      if (!deskActive) {
        setDrawingOffice(newOffice);
      }
      setCurrentPos(transformedPointerPos);
    },
    [drawing]
  );

  const handleMouseDown = useCallback(
    (e) => {
      if (!allowDrawing || !officeSaved) return;

      const stage = e.target.getStage();
      const { x, y } = stage.getPointerPosition();
      const { x: stageX, y: stageY } = stage.getPosition();
      const stageScale = stage.getScale();
      const transformedPointerPos = {
        x: (x - stageX) / stageScale.x,
        y: (y - stageY) / stageScale.y,
      };
      if (deskActive) {
        for (let i = 0; i < deskObjects.length; i++) {
          let shape = deskRefs.current[i];
          if (!shape) return;
          if (
            transformedPointerPos.x >= shape.x() - shape.radius() &&
            transformedPointerPos.x <= shape.x() + shape.radius() &&
            transformedPointerPos.y >= shape.y() - shape.radius() &&
            transformedPointerPos.y <= shape.y() + shape.radius()
          ) {
            return;
          }
        }
      }

      setStartPos(transformedPointerPos);
      setCurrentPos(transformedPointerPos);
      setDrawing(true);
      if (!deskActive) {
        setOfficeSaved(false);
        setActiveOfficeId(officeObjects.length);
      }
    },
    [
      allowDrawing,
      officeSaved,
      officeObjects.length,
      deskActive,
      desks,
      deskObjects,
    ]
  );

  const handleDone = useCallback(() => {
    setActiveOfficeId(null);
    setAllowDrawing(false);
    setOfficeSaved(true);
    setOfficeTooltip(false);
    setDeskTooltip(false);
    setEditing(false);
    let index =
      editing && !deskEditing ? activeOfficeId : officeObjects.length - 1;
    if (!officeRefs.current[index]) return;
    const shape = officeRefs.current[index];
    let promises = [];
    const newOffices = [
      ...officeObjects.map((o) => {
        return { ...o, selected: false };
      }),
    ];
    const newDeskObjects = [...deskObjects];
    if (shape && !deskEditing) {
      if (officeObjects.length !== 0) officeObjects[index].saved = true;
      newOffices[index] = {
        ...newOffices[index],
        name: selectedOffice.name,
        x: shape.x(),
        y: shape.y(),
        width: shape.width() * shape.scaleX(),
        height: shape.height() * shape.scaleY(),
      };
      shape.scaleX(1);
      shape.scaleY(1);

      setOfficeObjects(newOffices);
      if (editing && !deskEditing) {
        let o = newOffices[index];
        promises.push(
          UpdateOffice(locationEdited.id, {
            id: o.id,
            name: o.name,
            internalName: o.name,
            locationId: locationEdited.id,
            officeObject: {
              x: o.x / originalImageScale,
              y: o.y / originalImageScale,
              width: o.width / originalImageScale,
              height: o.height / originalImageScale,
            },
          })
        );
      }
    }
    if (deskObjects.length !== 0 && (deskActive || deskEditing)) {
      let i = 1;
      if (!deskEditing) setShowProcess(true);
      for (let d of deskObjects.filter((d) => !d.saved)) {
        let currentCircle = deskRefs.current[d.id];
        if (deskEditing) {
          currentCircle = deskRefs.current[activeDeskId];
          promises.push(
            UpdateHotDesk(locationEdited.id, {
              id: d.id,
              deskNumber: d.number,
              deskObject: {
                x:
                  (currentCircle ? currentCircle.x() : d.x) /
                  originalImageScale,
                y:
                  (currentCircle ? currentCircle.y() : d.y) /
                  originalImageScale,
              },
            })
          );
        } else {
          promises.push(
            addDesks({
              officeId: selectedOffice.id,
              deskNumber: (Number(countMap[selectedOffice.id]) + i)
                .toString()
                .padStart(2, "0"),
              internalName: "DESK".concat(
                (Number(countMap[selectedOffice.id]) + i)
                  .toString()
                  .padStart(2, "0")
              ),
              active: true,
              deskObject: {
                x:
                  (currentCircle ? currentCircle.x() : d.x) /
                  originalImageScale,
                y:
                  (currentCircle ? currentCircle.y() : d.y) /
                  originalImageScale,
              },
            })
          );
          i++;
        }
      }
      if (!deskEditing) setProcessActions(promises);
    }
    transformerRef.current.nodes([]);
    if (!deskActive && !editing) {
      let o = newOffices[index];
      promises.push(
        addOffice({
          name: o.name,
          internalName: o.name,
          locationId: locationEdited.id,
          officeObject: {
            x: o.x / originalImageScale,
            y: o.y / originalImageScale,
            width: o.width / originalImageScale,
            height: o.height / originalImageScale,
          },
        })
      );
    }
    setDeskActive(false);
    setDeskEditing(false);
    setOfficeEditing(false);
    setEditing(false);
    Promise.all(promises).then(() => {
      getDesks();
      getOffices();
    });
  }, [
    officeObjects,
    activeOfficeId,
    offices,
    editing,
    desks,
    deskObjects,
    transformerRef,
    originalImageScale,
    selectedOffice,
  ]);

  const handleOnOfficeModalSubmit = (name) => {
    setOpenOfficeModal(false);
    setOfficeTooltip(true);
    setDeskActive(false);
    setDisableDone(true);
    setAllowDrawing(true);
    setSelectedOffice({ name: name });
  };

  const handleOnDeskModalSubmit = (office) => {
    setOpenDeskModal(false);
    setDeskTooltip(true);
    setAllowDrawing(true);
    setDisableDone(true);
    setDeskActive(true);
    setOfficeObjects(
      officeObjects.map((o) => {
        return { ...o, selected: o.id === office.id };
      })
    );
    setSelectedOffice(office);
  };

  const handleEditLocation = async (name, file) => {
    setOpenLocationModal(false);
    let promises = [];
    if (name)
      promises.push(
        UpdateLocation(locationEdited.id, {
          ...locationEdited,
          name: name,
        }).then(setLocation)
      );
    if (file) promises.push(UpdateFloorplanFile(locationEdited.id, file));
    await Promise.all(promises).then(() => {
      handleImageChange();
    });
  };

  const handleOfficeDelete = async (office) => {
    setOpenDeleteModal(false);
    setSelectedOffice(null);
    setProcessActions([
      deleteOffice(office.id).then(() => {
        getDesks();
        getOffices();
      }),
    ]);
    setShowProcess(true);
  };

  const handleHotDeskDelete = async (desk) => {
    setOpenDeleteModal(false);
    setSelectedDesk(null);
    setProcessActions([DeleteHotDesk(desk.id).then(() => getDesks())]);
    setShowProcess(true);
  };

  const handleEditModal = async (name, office) => {
    setOpenEditModal(false);
    setDeskEditing(false);
    setOfficeEditing(false);
    if (deskEditing) {
      let desk = selectedDesk;
      if (name != null && name != "") desk.deskNumber = name;
      if (office != null) desk.officeId = office.key;
      await UpdateHotDesk(locationEdited.id, desk);
      await getDesks();
    } else {
      let editOffice = {
        ...selectedOffice,
        name: name,
      };
      await UpdateOffice(locationEdited.id, editOffice);
      await getOffices();
    }
  };

  const getOfficeData = async (id) => {
    setOpenDeleteModal(true);
    setLoading(true);
    setRelatedData(await GetOfficeData(id));
    setLoading(false);
  };

  const getDeskData = async (id) => {
    setOpenDeleteModal(true);
    setLoading(true);
    setRelatedData(await GetDeskData(id));
    setLoading(false);
  };

  const handleEditMove = (name) => {
    setOpenEditModal(false);
    setEditing(true);
    if (!deskEditing) {
      const index = officeObjects.findIndex((o) => o.id === selectedOffice.id);
      if (index !== -1) {
        transformerRef.current.rotateEnabled(false);
        transformerRef.current.nodes([officeRefs.current[index]]);
        transformerRef.current.getLayer().batchDraw();
        setActiveOfficeId(index);
        const updatedOfficeObjects = [...officeObjects];
        const office = updatedOfficeObjects[index];
        if (name) setSelectedOffice({ ...office, saved: false, name: name });
        updatedOfficeObjects[index] = {
          ...office,
          name: name ? name : office.name,
          saved: false,
        };
        setOfficeObjects(updatedOfficeObjects);
      }
    } else {
      const index = deskObjects.findIndex((d) => d.id === selectedDesk.id);
      if (index !== -1) {
        setActiveDeskId(index);
        const updatedDeskObjects = [...deskObjects];
        const desk = updatedDeskObjects[index];
        if (name)
          setSelectedDesk({
            ...desk,
            status: "Edit",
            saved: false,
            number: name,
          });
        updatedDeskObjects[index] = {
          ...desk,
          number: name ? name : desk.number,
          status: "Edit",
          saved: false,
        };
        setDeskObjects(updatedDeskObjects);
      }
    }
  };

  const handleRadiusChange = useCallback(
    (radius) => {
      UpdateFloorplan(locationEdited.id, {
        radius: radius / originalImageScale,
      });
      setRadiusModal(false);
    },
    [originalImageScale]
  );

  const handleOfficeSelection = (office, select) => {
    const index = officeObjects.findIndex((o) => o.id === office.id);
    if (index !== -1) {
      const updateOfficeObjects = [...officeObjects];
      updateOfficeObjects[index].selected = select;
      setOfficeObjects(updateOfficeObjects);
    }
  };

  const handleDeskSelection = (desk, select) => {
    const index = deskObjects.findIndex((d) => d.id === desk.id);
    if (index !== -1) {
      const updatedDeskObjects = [...deskObjects];
      updatedDeskObjects[index].selected = select;
      setDeskObjects(updatedDeskObjects);
    }
  };

  const handleDeskActivation = (desk) => {
    let promise;
    if (desk.active) {
      promise = DeactivateHotDesk(desk.id);
    } else {
      promise = ActivateHotDesk(desk.id);
    }
    promise.then((response) => {
      const index = desks.findIndex((d) => d.id === response.id);
      if (index !== -1) {
        const updatedDesks = [...desks];
        updatedDesks[index].active = response.active;
        setDesks(updatedDesks);
      }
    });
  };

  return (
    <Stack verticalFill>
      <DeleteModal
        isOpen={openDeleteModal}
        loading={loading}
        modalText={t("deleteMessage.confirmDelete")}
        office={selectedOffice}
        location={locationEdited}
        data={relatedData}
        onDismiss={() => setOpenDeleteModal(false)}
        onDelete={() =>
          selectedOffice
            ? handleOfficeDelete(selectedOffice)
            : handleHotDeskDelete(selectedDesk)
        }
      />
      <ActionBarComponent
        actions={[
          {
            text: t("floorplan.edit"),
            primary: true,
            action: () => setOpenLocationModal(true),
            disabled: allowDrawing || editing,
          },
          {
            text: t("floorplan.office"),
            primary: true,
            action: () => {
              setOpenOfficeModal(true);
              setOfficeTooltip(true);
            },
            disabled: allowDrawing || editing,
          },
          {
            text: t("floorplan.desk"),
            primary: true,
            disabled: offices.length === 0 || allowDrawing || editing,
            action: () => {
              setOpenDeskModal(true);
              setDeskTooltip(true);
            },
          },
          {
            text: t("common.done"),
            disabled: (!allowDrawing && !editing) || disableDone,
            primary: true,
            action: handleDone,
          },
          {
            text: t("floorplan.editDeskRadius"),
            primary: false,
            action: () => setRadiusModal(true),
          },
        ]}
      />
      {showProcess && (
        <ProcessingComponent
          actions={processActions}
          onFinish={() => setShowProcess(false)}
        />
      )}
      <DeskRadiusModal
        isOpen={openRadiusModal}
        onDismiss={() => setRadiusModal(false)}
        defaultValue={radius}
        onChange={setRadius}
        onSubmit={handleRadiusChange}
      />
      <LocationModal
        isOpen={openLocationModal}
        onDismiss={() => setOpenLocationModal(false)}
        onSubmit={handleEditLocation}
        location={locationEdited}
        edit
      />
      <CustomModal
        isOpen={openOfficeModal}
        onDismiss={() => {
          setOpenOfficeModal(false);
          setOfficeTooltip(false);
        }}
        onSubmit={handleOnOfficeModalSubmit}
        modalText={t("floorplan.createOffice")}
      />
      <DeskModal
        isOpen={openDeskModal}
        onDismiss={() => {
          setOpenDeskModal(false);
          setDeskTooltip(false);
        }}
        onSubmit={handleOnDeskModalSubmit}
        offices={offices}
      />
      <EditModal
        isOpen={openEditModal}
        onDismiss={() => {
          setOpenEditModal(false);
          setDeskEditing(false);
          setOfficeEditing(false);
        }}
        offices={!officeEditing && deskEditing ? offices : null}
        onMove={handleEditMove}
        selectedDesk={selectedDesk}
        modalText={t(
          officeEditing ? "floorplan.editOffice" : "floorplan.editDesk"
        )}
        onSubmit={handleEditModal}
      />
      <Stack horizontal verticalFill>
        <StackItem grow={9}>
          {(officeTooltip || deskTooltip) && (
            <MessageBar
              messageBarType={MessageBarType.warning}
              dismissButtonAriaLabel="Close"
            >
              {officeTooltip ? t("tooltip.office") : t("tooltip.desk")}
            </MessageBar>
          )}
          <Stack className="page">
            <Header style={{ fontSize: 30 }} text={locationEdited?.name} />
          </Stack>
          <FloorplanComponent
            offices={
              drawingOffice
                ? officeObjects.concat(drawingOffice)
                : officeObjects
            }
            drawing={drawing}
            allowDrawing={allowDrawing}
            desks={deskObjects}
            image={image}
            edit={true}
            editing={editing}
            deskEditing={deskEditing}
            officeEditing={officeEditing}
            officeRefs={officeRefs}
            deskRefs={deskRefs}
            setNewDesks={setDeskObjects}
            transformerRef={transformerRef}
            handleMouseMove={handleMouseMove}
            handleMouseUp={handleMouseUp}
            handleMouseDown={handleMouseDown}
            radius={radius}
          />
        </StackItem>
        <StackItem grow={1}>
          <OfficeFloorplanSidebar
            offices={offices}
            desks={desks}
            deskEditing={deskEditing}
            officeEditing={officeEditing}
            drawing={allowDrawing}
            handleActivation={handleDeskActivation}
            selectOffice={handleOfficeSelection}
            selectDesk={handleDeskSelection}
            onEditOffice={(office) => {
              setOpenEditModal(true);
              setDeskEditing(false);
              setOfficeEditing(true);
              setSelectedOffice(office);
            }}
            onDeleteOffice={(office) => {
              getOfficeData(office.id);
              setSelectedOffice(office);
              setSelectedDesk(null);
            }}
            onEditDesk={(desk) => {
              setOpenEditModal(true);
              setDeskEditing(true);
              setOfficeEditing(false);
              setSelectedDesk(desk);
            }}
            onDeleteDesk={(desk) => {
              getDeskData(desk.id);
              setSelectedDesk(desk);
              setSelectedOffice(null);
            }}
          />
        </StackItem>
      </Stack>
    </Stack>
  );
};

export default EditOfficeFloorplanComponent;
