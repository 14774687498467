import { mergeStyles } from "@fluentui/merge-styles";
import {
  getFocusStyle,
  getTheme,
  mergeStyleSets,
} from "@fluentui/react/lib/Styling";

const theme = getTheme();
const { palette, semanticColors, fonts } = theme;

export const classNames = mergeStyleSets({
  itemCell: [
    getFocusStyle(theme, { inset: -1 }),
    {
      minHeight: 34,
      padding: 5,
      boxSizing: "border-box",
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      display: "flex",
      selectors: {
        "&:hover": { background: palette.neutralLight },
      },
    },
  ],
  itemContent: {
    margin: "10px",
    overflow: "hidden",
    flexGrow: 1,
  },
  itemName: [
    fonts.mediumPlus,
    {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  ],
  itemIndex: {
    fontSize: fonts.medium.fontSize,
    color: palette.neutralTertiary,
  },
  chevron: {
    alignSelf: "center",
    marginLeft: 10,
    color: palette.neutralTertiary,
    fontSize: fonts.large.fontSize,
    flexShrink: 0,
  },
  deleteStack: {
    borderBottom: `1px solid ${semanticColors.bodyDivider}`,
    marginLeft: "35px",
  },
});

export const classNamesDeskPermissions = mergeStyleSets({
  itemCell: [
    getFocusStyle(theme, { inset: -1 }),
    {
      minHeight: 15,
      padding: 5,
      boxSizing: "border-box",
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
    },
  ],
  itemContent: {
    marginLeft: 5,
    overflow: "hidden",
    flexGrow: 1,
  },
  itemName: [
    fonts.medium,
    {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      cursor: "pointer",
    },
  ],
  itemIndex: {
    fontSize: fonts.smallPlus.fontSize,
    color: palette.neutralTertiary,
    marginBottom: 10,
  },
  chevron: {
    alignSelf: "center",
    marginLeft: 10,
    color: palette.neutralTertiary,
    fontSize: fonts.large.fontSize,
    flexShrink: 0,
  },
  root: {
    background: "rgba(0, 0, 0, 0.2)",
    bottom: "0",
    left: "0",
    position: "fixed",
    right: "0",
    top: "0",
  },
  content: {
    background: "white",
    left: "50%",
    minWidth: "500px",
    maxWidth: "800px",
    padding: "0 2em 2em",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
});
export const parkingUsersColumns = [
  {
    key: "name",
    name: "common.userName",
    fieldName: "name",
    minWidth: 200,
    maxWidth: 450,
    isResizable: true,
  },
  {
    key: "upn",
    name: "common.upn",
    fieldName: "upn",
    minWidth: 200,
    maxWidth: 400,
    isResizable: true,
  },
  {
    flexGrow: 1,
    key: "plateNum",
    name: "common.licensePlate",
    fieldName: "plateNum",
    minWidth: 200,
    isResizable: true,
  },
  {
    key: "save",
    name: "common.save",
    fieldName: "save",
    minWidth: 50,
    maxWidth: 50,
    isResizable: true,
  },
  {
    key: "delete",
    name: "common.delete",
    fieldName: "Delete",
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
  },
];

export const deskUserColumns = [
  {
    key: "displayName",
    name: "common.userName",
    fieldName: "displayName",
    isRowHeader: true,
    minWidth: 200,
    maxWidth: 450,
    isSorted: false,
    isSortedDescending: false,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
    isResizable: true,
    searchable: true,
  },
  {
    flexGrow: 1,
    key: "userPrincipalName",
    name: "common.upn",
    fieldName: "userPrincipalName",
    isRowHeader: true,
    minWidth: 200,
    isSorted: false,
    isSortedDescending: false,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
    isResizable: true,
    searchable: true,
  },
  {
    key: "view",
    name: "common.view",
    fieldName: "view",
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    unSortable: true,
  },
];

export const deskUserColumnsWithDelete = [
  {
    key: "displayName",
    name: "common.userName",
    fieldName: "displayName",
    isRowHeader: true,
    minWidth: 200,
    maxWidth: 450,
    isSorted: false,
    isSortedDescending: false,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
    isResizable: true,
    searchable: true,
  },
  {
    flexGrow: 1,
    key: "userPrincipalName",
    name: "common.upn",
    fieldName: "userPrincipalName",
    isRowHeader: true,
    minWidth: 200,
    isSorted: false,
    isSortedDescending: false,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
    isResizable: true,
    searchable: true,
  },
  {
    key: "delete",
    name: "common.delete",
    fieldName: "delete",
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    unSortable: true,
  },
  {
    key: "view",
    name: "common.view",
    fieldName: "view",
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    unSortable: true,
  },
];

export const groupColumns = [
  {
    key: "name",
    flexGrow: 1,
    name: "common.name",
    fieldName: "name",
    isRowHeader: true,
    minWidth: 200,
    isSorted: false,
    isSortedDescending: false,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
    isResizable: true,
    searchable: true,
  },
  {
    flexGrow: 1,
    key: "delete",
    name: "common.delete",
    fieldName: "delete",
    unSortable: true,
  },
  {
    key: "edit",
    name: "common.edit",
    fieldName: "edit",
    minWidth: 100,
    maxWidth: 100,
    unSortable: true,
  },
  {
    key: "view",
    name: "common.view",
    fieldName: "view",
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    unSortable: true,
  },
];

export const teamsColumns = [
  {
    key: "name",
    flexGrow: 1,
    name: "common.name",
    fieldName: "name",
    isRowHeader: true,
    minWidth: 200,
    isSorted: false,
    isSortedDescending: false,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
    isResizable: true,
    searchable: true,
  },
  {
    flexGrow: 1,
    key: "delete",
    name: "common.delete",
    fieldName: "delete",
    unSortable: true,
  },
  {
    key: "view",
    name: "common.view",
    fieldName: "view",
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    unSortable: true,
  },
];

export const officeColumns = [
  {
    key: "name",
    flexGrow: 1,
    name: "common.name",
    fieldName: "name",
    isRowHeader: true,
    minWidth: 200,
    isSorted: false,
    isSortedDescending: false,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
    isResizable: true,
    searchable: true,
  },
  {
    key: "view",
    name: "common.view",
    fieldName: "view",
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    unSortable: true,
  },
];

export const groupPermissionColumns = [
  {
    key: "name",
    flexGrow: 1,
    name: "common.name",
    fieldName: "name",
    isRowHeader: true,
    minWidth: 200,
    isSorted: false,
    isSortedDescending: false,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
    isResizable: true,
    searchable: true,
  },
  {
    flexGrow: 1,
    key: "delete",
    name: "common.delete",
    fieldName: "delete",
    unSortable: true,
  },
  {
    key: "view",
    name: "common.view",
    fieldName: "view",
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    unSortable: true,
  },
];

export const assignedSpotColumns = [
  {
    key: "name",
    name: "common.name",
    fieldName: "name",
    minWidth: 200,
    maxWidth: 300,
    isResizable: true,
  },
  {
    key: "location",
    name: "common.location",
    fieldName: "location",
    minWidth: 150,
    maxWidth: 300,
    isResizable: true,
  },
  {
    flexGrow: 1,
    key: "spotNum",
    name: "common.spotNumber",
    fieldName: "spotNum",
    minWidth: 100,
    isResizable: true,
  },
  {
    key: "delete",
    name: "common.delete",
    fieldName: "Delete",
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
  },
];

export const teamMembersColumns = [
  {
    key: "name",
    name: "common.name",
    fieldName: "name",
    isRowHeader: true,
    minWidth: 200,
    maxWidth: 500,
    isSorted: false,
    isSortedDescending: false,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
    isResizable: true,
    searchable: true,
  },
  {
    key: "role",
    name: "teams.role",
    filedName: "role",
    minWidth: 200,
    flexGrow: 1,
    isResizable: false,
    unSortable: true,
  },
  {
    key: "delete",
    name: "common.delete",
    fieldName: "Delete",
    minWidth: 70,
    maxWidth: 70,
    isResizable: false,
    unSortable: true,
  },
];

export const defaultModalStyle = mergeStyles({
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "stretch",
  padding: "10px",
});

export const defaultModalInnerSyle = mergeStyles({
  margin: "10px",
  padding: "5px 12px",
  paddingBottom: "10px",
  minWidth: 500,
});

export const compactStackStyle = { childrenGap: 5 };

export const defaultStackStyle = { childrenGap: 10 };

export const largeStackStyle = { childrenGap: 30 };

export const defaultModalInnerStyle = mergeStyles({
  margin: "10px",
  padding: "5px 12px",
  paddingBottom: "10px",
  minWidth: 500,
});

export const labelHeaderStyle = mergeStyles({
  fontSize: "20px",
});

export const dropdownStyles = {
  dropdown: { width: 250 },
};

export const TeamRoles = {
  0: "teams.teamMember",
  1: "teams.teamLeader",
};
