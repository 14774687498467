import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { GetHotDesk } from "../../services/DeskService";
import { GetFloorplan } from "../../services/FloorplanService";
import { GetParkingSpotById } from "../../services/ParkingService";
import { isLoading } from "../General/Loading";
import EmptyMessage from "../General/Messages/EmptyMessage";
import FloorplanComponent from "./FloorplanComponent";
import { IsMobileContext } from "../General/Layout";

const ViewFloorplanComponent = ({ location, hotDeskId, parkingSpotId }) => {
  const [loading, setLoading] = useState(true);
  const [floorplan, setFloorplan] = useState();
  const [radius, setRadius] = useState(13);
  const [desks, setDesks] = useState([]);
  const [offices, setOffices] = useState([]);
  const [image, setImage] = useState();
  const [locationNotFound, setLocationNotFound] = useState();
  const [originalImage, setOriginalImage] = useState();
  const [originalImageScale, setOriginalImageScale] = useState(1);
  const { t, i18n } = useTranslation();
  const officeRefs = useRef([]);
  const deskRefs = useRef([]);
  const transformerRef = useRef();
  const mobile = useContext(IsMobileContext);

  useEffect(() => {
    GetFloorplan(location.id)
      .then((res) => {
        setFloorplan(res.floorplan);
        const url = window.URL.createObjectURL(
          new Blob(res.file, { type: "image/png" })
        );
        const orUrl = window.URL.createObjectURL(
          new Blob(res.file, { type: "image/png" })
        );
        const orImage = new Image();
        orImage.src = orUrl;
        orImage.onload = () => {
          setOriginalImage(orImage);
        };
        const image = new Image();
        image.src = url;
        image.onload = () => {
          let width = Math.min(image?.width, window.innerWidth * 0.8);
          let imageScale = width / image?.width;
          setOriginalImageScale(imageScale);
          image.height = image?.height * imageScale;
          image.width = width;
          setImage(image);
        };
        if (hotDeskId) getDesk();
        if (parkingSpotId) getParking();
      })
      .catch(() => setLocationNotFound(true))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [image]);

  useEffect(() => {
    if (floorplan && originalImageScale) {
      setRadius(
        floorplan?.radius
          ? Math.round(floorplan.radius * originalImageScale)
          : 13
      );
    } else {
      setRadius(13);
    }
  }, [floorplan, originalImageScale]);

  const onResize = useCallback(() => {
    let width = Math.min(originalImage?.width, window.innerWidth * 0.8);
    let imageScale = width / originalImage?.width;
    setOriginalImageScale(imageScale);
    if (image) {
      image.height = originalImage?.height * imageScale;
      image.width = width;
    }
    setImage(image);
  }, [image, originalImage]);

  const getDesk = async () => {
    let desk = await GetHotDesk(hotDeskId);
    desk = [
      {
        id: desk.id,
        x: desk.deskObject.x * originalImageScale,
        y: desk.deskObject.y * originalImageScale,
        number: desk.deskNumber,
        status: "Selected",
        saved: true,
        view: true,
      },
    ];
    setDesks(desk);
  };

  useEffect(() => {
    if (hotDeskId && image !== undefined) getDesk();
    if (parkingSpotId && image !== undefined) getParking();
  }, [originalImageScale, image]);

  const getParking = async () => {
    let parking = await GetParkingSpotById(location.id, parkingSpotId);
    parking = [
      {
        id: parking.id,
        x: parking.parkingSpotObject?.x * originalImageScale,
        y: parking.parkingSpotObject?.y * originalImageScale,
        width: parking.parkingSpotObject?.width * originalImageScale,
        height: parking.parkingSpotObject?.height * originalImageScale,
        saved: true,
        name: parking.spotNumber,
        view: true,
      },
    ];
    setOffices(parking);
  };

  return (
    <>
      {isLoading(
        loading,
        !locationNotFound ? (
          <FloorplanComponent
            offices={offices}
            drawing={false}
            desks={desks}
            image={image}
            edit={false}
            rotation={
              window.innerWidth * 0.8 < window.innerHeight &&
              image?.width > image?.height
            }
            radius={radius}
            mobile={mobile}
            officeRefs={officeRefs}
            deskRefs={deskRefs}
            setNewDesks={setDesks}
            transformerRef={transformerRef}
          />
        ) : (
          <div style={{ marginTop: "60px" }}>
            <EmptyMessage text={t("emptyMessages.noFloorplan")} icon="Cancel" />
          </div>
        )
      )}
    </>
  );
};
export default ViewFloorplanComponent;
