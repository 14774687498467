import { IconButton, Stack, useTheme } from "@fluentui/react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { SettingsContext } from "../App";
import ActionBarComponent from "../components/General/ActionBarComponent/ActionBarComponent";
import Header from "../components/General/Header";
import {
  IsMobileContext,
  PermissionContext,
} from "../components/General/Layout";
import { UpcomingReservations } from "../components/General/UpcomingReservations";
import { reservationToObject, sortByDate } from "../helpers/Functions";
import { GetConfig } from "../services/ConfigService";
import { GetReservations } from "../services/DeskService";
import { GetParkingReservations } from "../services/ParkingService";
import "../styles/custom.css";
import { useTitle } from "../helpers/useTitle";

const classNames = mergeStyleSets({
  container: {
    textAlign: "right",
  },
});

export const HomePage = () => {
  const history = useHistory();
  const parking = useContext(PermissionContext);
  const settingsContext = useContext(SettingsContext);
  const { t, i18n } = useTranslation();
  const isMobile = useContext(IsMobileContext);
  const theme = useTheme();
  useTitle("Upcoming reservations");

  const iconColor = theme?.palette?.themePrimary;

  useEffect(() => {
    GetConfig("color").then((c) => {
      localStorage.setItem("primaryColor", c.data);
      if (settingsContext.setColor) settingsContext.setColor(c.data);
    });
  }, []);

  const getReservations = async () => {
    let promises = [];
    let deskReservations = [];
    let parkingReservations = [];
    promises.push(GetReservations().then((d) => (deskReservations = d)));
    promises.push(
      GetParkingReservations().then((p) => (parkingReservations = p))
    );
    await Promise.all(promises);
    let tempItems = [];
    tempItems.push(
      ...deskReservations.map((r, i) => reservationToObject(r, i, "desk"))
    );
    tempItems.push(
      ...parkingReservations.map((r, i) =>
        reservationToObject(r, i + deskReservations.length, "parking")
      )
    );
    tempItems.sort(sortByDate);
    return tempItems;
  };

  return (
    <>
      {!isMobile && (
        <ActionBarComponent
          id="action-bar"
          actions={[
            {
              key: "desk",
              text: t("home.addDeskReservation"),
              icon: "System",
              primary: true,
              action: () => history.push("/deskreservation"),
              disabled: false,
            },
            {
              key: "parking",
              text: t("home.addParkReservation"),
              icon: "Car",
              primary: true,
              action: () => history.push("/parkingreservation"),
              disabled: false,
            },
          ].filter((a) => a.key !== "parking" || parking)}
        />
      )}
      <Stack verticalFill className="page">
        <Header text={t("home.upcoming")} />
        {isMobile && (
          <Stack
            horizontalAlign="end"
            verticalAlign="center"
            horizontal
            tokens={{ childrenGap: 10 }}
            style={{ paddingBottom: 15 }}
          >
            <IconButton
              iconProps={{ iconName: "System", color: iconColor }}
              onClick={() => history.push("/deskreservation")}
            />
            {parking && (
              <IconButton
                iconProps={{ iconName: "Car", color: iconColor }}
                onClick={() => history.push("/parkingreservation")}
              />
            )}
          </Stack>
        )}
        <UpcomingReservations getItems={getReservations} />
      </Stack>
    </>
  );
};
