import { createContext, useEffect, useState } from "react";
import fetchApi from "../services/Axios";

export const ReactConfigContext = createContext();

const ReactConfigSetup = (props) => {
  const [config, setConfig] = useState();

  useEffect(() => {
    fetchApi.get("react-config").then(({ data }) => setConfig(data));
  }, []);

  return (
    <ReactConfigContext.Provider value={config}>
      {props.children}
    </ReactConfigContext.Provider>
  );
};

export default ReactConfigSetup;
