import { Stack } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import DeskReservation from "../components/General/DeskReservation";
import Header from "../components/General/Header";
import BasicCalendar from "../components/General/Inputs/BasicCalendar";
import BasicDropdown from "../components/General/Inputs/BasicDropdown";
import UserComboBox from "../components/General/Inputs/UserComboBox";
import { AddDeskReservation, GetLocations } from "../services/LocationService";
import EmptyMessage from "../components/General/Messages/EmptyMessage";
import { useTitle } from "../helpers/useTitle";

const periods = [
  { key: "morning", text: "only morning (till 12:00PM)" },
  { key: "afternoon", text: "only afternoon (from 12:30PM)" },
  { key: "oneDay", text: "all day" },
  { key: "twoDays", text: "2 working days" },
  { key: "threeDays", text: "3 working days" },
  { key: "fourDays", text: "4 working days" },
  { key: "fiveDays", text: "5 working days" },
  { key: "permanent", text: "Permanent" },
];

const AssignDeskPage = () => {
  const [user, setUser] = useState(null);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();
  const [period, setPeriod] = useState("oneDay");
  const [fromDate, setFromDate] = useState();
  const [selectedDesk, setSelectedDesk] = useState();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  useTitle("Assign desk");

  const getLocations = async () => {
    let tempLocations = await GetLocations();
    let tempArray = tempLocations
      .filter((l) => l.hotdeskAllowed)
      .map((location) => {
        return { text: location.name, key: location.id, data: location };
      });
    setLocations(tempArray);
  };

  useEffect(() => {
    getLocations();
  }, [selectedLocation]);

  const handleSubmit = async () => {
    AddDeskReservation(selectedLocation.key, {
      HotDeskId: selectedDesk.id,
      UserID: user ? user.key : null,
      StartDate: fromDate,
      Period: period.key,
    }).then(() => {
      setUser(null);
      setSelectedLocation(null);
      setPeriod("oneDay");
      setFromDate(null);
      setSelectedDesk(null);
      history.go(0);
    });
  };

  return (
    <Stack verticalFill className="page">
      <Header text={t("sidebar.assignDesk")} />
      <Stack tokens={{ childrenGap: 15 }} horizontal verticalAlign="end">
        <UserComboBox
          handleSelect={(user) => {
            setUser(user ? user[0] : null);
          }}
        />
        <BasicDropdown
          label={t("common.period")}
          placeholder={t("common.selectPeriod")}
          handleChange={setPeriod}
          options={periods}
          defaultItem={{ key: "oneDay", text: "all day" }}
        />
        <BasicDropdown
          label={t("common.location")}
          placeholder={t("common.selectLocation")}
          handleChange={(item) => {
            setSelectedLocation(item);
          }}
          options={locations}
        />
        <BasicCalendar text="From Date" handleSelect={setFromDate} />
      </Stack>
      {user && selectedLocation ? (
        <DeskReservation
          startDate={fromDate}
          period={period}
          location={selectedLocation}
          handleSelection={setSelectedDesk}
          handleSubmit={handleSubmit}
          forUser={user}
        />
      ) : (
        <EmptyMessage
          text={t("emptyMessages.makeReservationAssign")}
          icon="List"
        />
      )}
    </Stack>
  );
};

export default AssignDeskPage;
