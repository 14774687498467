import {
  IconButton,
  Label,
  MessageBar,
  MessageBarType,
  Stack,
} from "@fluentui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "./Header";

const Tooltip = ({ label, fontSize, style }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { t, i18n } = useTranslation();

  return (
    <>
      <Stack horizontal verticalAlign={"baseline"}>
        {label.includes("application") ? (
          <Header text={t(label)} />
        ) : (
          <Label>{t(label)}</Label>
        )}
        <IconButton
          iconProps={{
            iconName: "Info",
            styles: { root: { fontSize: fontSize } },
          }}
          title="Reset Zoom"
          ariaLabel="Reset Zoom"
          style={style}
          onClick={() => setShowTooltip(!showTooltip)}
        />
      </Stack>
      {showTooltip && (
        <MessageBar
          messageBarType={MessageBarType.info}
          dismissButtonAriaLabel="Close"
          isMultiline
        >
          {t("adminRole.adminRole")} <br />
          {t("adminRole.permissionManager")}
        </MessageBar>
      )}
    </>
  );
};

export default Tooltip;
