import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { ThemeProvider, initializeIcons } from "@fluentui/react";
import Color from "color";
import React, { createContext, useEffect, useState } from "react";
import { CacheBuster } from "react-cache-buster/dist/CacheBuster";
import { Route, Router, Switch, useHistory } from "react-router-dom";
import Layout from "./components/General/Layout";
import Loading from "./components/General/Loading";
import AxiosInterceptorInjector from "./components/Utility/AxiosInterceptorInjector/AxiosInterceptorInjector";
import AppInsightsSetup from "./helpers/AppInsightsSetup";
import ReactConfigSetup from "./helpers/ReactConfigSetup";
import AdminRolePage from "./pages/AdminRolePage";
import AssignDeskPage from "./pages/AssignDeskPage";
import AuthPage from "./pages/AuthPage";
import ConfigurationPage from "./pages/ConfigurationPage";
import DeskManagementPage from "./pages/DeskManagementPage";
import DeskPermissionsPage from "./pages/DeskPermissionsPage";
import ErrorPage from "./pages/ErrorPage";
import FloorplanPage from "./pages/FloorplanPage";
import FloorplansPage from "./pages/FloorplansPage";
import { HomePage } from "./pages/HomePage";
import LogoutPage from "./pages/LogoutPage";
import ManageParkingReservationPage from "./pages/ManageParkingReservationPage";
import NewDeskReservation from "./pages/NewDeskReservation";
import NewParkingReservation from "./pages/NewParkingReservation";
import NoMatchPage from "./pages/NoMatchPage";
import NoPermissionPage from "./pages/NoPermissionPage";
import ParkingManagementPage from "./pages/ParkingManagementPage";
import ParkingPermissionsPage from "./pages/ParkingPermissionsPage";
import PermanentParkingPage from "./pages/PermanentParkingPage";
import ReportPage from "./pages/ReportPage";
import TeamPage from "./pages/TeamPage";
import TeamsPage from "./pages/TeamsPage";
import ViewRemoveReservationsPage from "./pages/ViewRemoveReservationsPage";
import fetchApi from "./services/Axios";
import "./styles/custom.css";

initializeIcons();

export const SettingsContext = createContext();

function App(props) {
  const history = useHistory();
  const isAuthenticated = useIsAuthenticated();
  const [primaryColor, setPrimaryColor] = useState();
  const [theme, setTheme] = useState({});
  const { instance, accounts, inProgress } = useMsal();

  useEffect(() => {
    if (accounts && accounts[0]) {
      instance.setActiveAccount(accounts[0]);
    }
    let color = localStorage.getItem("primaryColor");
    setPrimaryColor(color);
  }, [accounts]);

  useEffect(
    () =>
      setTheme(
        primaryColor
          ? {
              palette: {
                themePrimary: `#${primaryColor}`,
              },
              semanticColors: {
                primaryButtonBackgroundHovered: Color(
                  `#${primaryColor}`.toUpperCase()
                )
                  .darken(0.2)
                  .hex(),
                primaryButtonBackgroundPressed: Color(
                  `#${primaryColor}`.toUpperCase()
                )
                  .darken(0.3)
                  .hex(),
              },
            }
          : {}
      ),
    [primaryColor]
  );
  const isProduction = process.env.NODE_ENV === "production";
  return (
    <CacheBuster
      currentVersion={process.env["REACT_APP_VERSION"]}
      isEnabled={isProduction}
      isVerboseMode={false}
      loadingComponent={<Loading />}
      metaFileDirectory={"."}
    >
      {isAuthenticated && accounts && accounts[0] ? (
        <SettingsContext.Provider
          value={{
            color: primaryColor,
            setColor: setPrimaryColor,
          }}
        >
          <ThemeProvider theme={theme}>
            <Router history={history}>
              <AxiosInterceptorInjector api={fetchApi} />
              <ReactConfigSetup>
                <AppInsightsSetup>
                  <AuthenticatedTemplate>
                    <Layout {...props}>
                      <Switch>
                        <Route
                          exact
                          path="/"
                          component={() => {
                            return <HomePage user={accounts[0]} />;
                          }}
                        />
                        <Route
                          exact
                          path="/deskreservation"
                          component={NewDeskReservation}
                        />
                        <Route
                          exact
                          path="/deskmanagement"
                          component={DeskManagementPage}
                        />
                        <Route
                          exact
                          path="/parkingmanagement"
                          component={ParkingManagementPage}
                        />
                        <Route
                          exact
                          path="/parkingreservation"
                          component={NewParkingReservation}
                        />
                        <Route
                          exact
                          path="/deskmanagement/floorplans"
                          component={FloorplansPage}
                        />
                        <Route
                          exact
                          path="/parkingmanagement/permanentparking"
                          component={PermanentParkingPage}
                        />
                        <Route
                          exact
                          path="/parkingmanagement/manageparkingreservations"
                          component={ManageParkingReservationPage}
                        />
                        <Route
                          exact
                          path="/config"
                          component={ConfigurationPage}
                        />
                        <Route
                          exact
                          path="/deskmanagement/assigndesk"
                          component={AssignDeskPage}
                        />
                        <Route
                          exact
                          path="/deskmanagement/floorplans/:id"
                          component={FloorplanPage}
                        />
                        <Route
                          exact
                          path="/parkingmanagement/floorplans"
                          component={() => <FloorplansPage parking />}
                        />
                        <Route
                          exact
                          path="/parkingmanagement/floorplans/:id"
                          component={FloorplanPage}
                        />
                        <Route
                          exact
                          path="/parkingmanagement/parkingpermissions"
                          component={ParkingPermissionsPage}
                        />
                        <Route
                          exact
                          path="/permissions"
                          component={DeskPermissionsPage}
                        />
                        <Route
                          exact
                          path="/deskmanagement/view-remove"
                          component={ViewRemoveReservationsPage}
                        />
                        <Route exact path="/report" component={ReportPage} />
                        <Route
                          exact
                          path="/parkingmanagement/view-remove"
                          component={() => (
                            <ViewRemoveReservationsPage parking />
                          )}
                        />
                        <Route
                          exact
                          path="/applicationroles"
                          component={AdminRolePage}
                        />
                        <Route exact path="/teams" component={TeamsPage} />
                        <Route exact path="/teams/:id" component={TeamPage} />
                        <Route exact path="/error" component={ErrorPage} />
                        <Route
                          exact
                          path="/nopermission"
                          component={NoPermissionPage}
                        />
                        <Route component={NoMatchPage} />
                      </Switch>
                    </Layout>
                  </AuthenticatedTemplate>
                </AppInsightsSetup>
              </ReactConfigSetup>
            </Router>
          </ThemeProvider>
        </SettingsContext.Provider>
      ) : (
        <Router history={history}>
          <Switch>
            <Route exact path="/Logout" component={LogoutPage} />
            <Route path="*" component={AuthPage} />
          </Switch>
        </Router>
      )}
    </CacheBuster>
  );
}

export default App;
