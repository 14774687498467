import { Stack, StackItem } from "@fluentui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AddNewParkingSpot,
  GetFloorplan,
  GetParkingSpots,
  UpdateFloorplanFile,
  UpdateParkingSpot,
  deleteParkingSpot,
} from "../../services/FloorplanService";
import { UpdateLocation } from "../../services/LocationService";
import { GetParkingSpotData } from "../../services/RelatedDataService";
import ActionBarComponent from "../General/ActionBarComponent/ActionBarComponent";
import Header from "../General/Header";
import EmptyMessage from "../General/Messages/EmptyMessage";
import CustomModal from "../Modals/CustomModal";
import DeleteModal from "../Modals/DeleteModal";
import LocationModal from "../Modals/LocationModal";
import FloorplanComponent from "./FloorplanComponent";
import ParkingFloorplanSidebar from "./FloorplanSidebar/ParkingFloorplanSidebar";
import ProcessingComponent from "../General/ProcessingComponent";

const EditParkingFloorplanComponent = ({ location }) => {
  const [openOfficeModal, setOpenOfficeModal] = useState(false);
  const [drawing, setDrawing] = useState(false);
  const [allowDrawing, setAllowDrawing] = useState(false);
  const [parkingObjects, setParkingObjects] = useState([]);
  const [parking, setParking] = useState([]);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 });
  const [originalImage, setOriginalImage] = useState();
  const [image, setImage] = useState();
  const [activeParkingSpotId, setActiveParkingSpotId] = useState();
  const [officeSaved, setParkingSpotSaved] = useState(true);
  const [selectedParking, setSelectedParking] = useState();
  const [drawingParkingSpot, setDrawingParkingSpot] = useState();
  const [originalImageScale, setOriginalImageScale] = useState(1);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [relatedData, setRelatedData] = useState({});
  const [loading, setLoading] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [locationEdited, setLocation] = useState({ ...location });
  const [editing, setEditing] = useState(false);
  const [disableDone, setDisableDone] = useState(false);
  const [showProcess, setShowProcess] = useState(false);
  const [processActions, setProcessActions] = useState();
  const parkingRefs = useRef([]);
  const transformerRef = useRef();
  const { t, i18n } = useTranslation();

  const onResize = useCallback(() => {
    let width = Math.min(originalImage?.width, window.innerWidth * 0.8);
    let imageScale = !originalImage ? 1 : width / originalImage.width;
    setOriginalImageScale(imageScale);
    image.height = !originalImage ? 0 : originalImage?.height * imageScale;
    image.width = width;
    setImage(image);
  }, [image, originalImage]);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [image]);

  const handleImageChange = () => {
    GetFloorplan(locationEdited.id)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob(res.file, { type: "image/png" })
        );
        const orUrl = window.URL.createObjectURL(
          new Blob(res.file, { type: "image/png" })
        );
        const orImage = new Image();
        orImage.src = orUrl;
        orImage.onload = () => {
          setOriginalImage(orImage);
        };
        const image = new Image();
        image.src = url;
        image.onload = () => {
          let width = Math.min(image?.width, window.innerWidth * 0.8);
          let imageScale = width / image.width;
          setOriginalImageScale(imageScale);
          image.height = image?.height * imageScale;
          image.width = width;
          setImage(image);
        };
        getParkingSpots();
      })
      .catch(getParkingSpots);
  };

  useEffect(() => {
    handleImageChange();
  }, []);

  const onDeleteParkingSpot = async (parkingSpot) => {
    setOpenDeleteModal(false);
    setSelectedParking(null);
    setProcessActions([
      deleteParkingSpot(parkingSpot.id).then(() => getParkingSpots()),
    ]);
    setShowProcess(true);
  };

  useEffect(() => {
    let tempParkingSpots = parking
      .filter((p) => p.parkingSpotObject)
      .map((p) => {
        return {
          id: p.id,
          x: p.parkingSpotObject.x * originalImageScale,
          y: p.parkingSpotObject.y * originalImageScale,
          width: p.parkingSpotObject.width * originalImageScale,
          height: p.parkingSpotObject.height * originalImageScale,
          name: p.spotNumber,
          selected: false,
          saved: true,
        };
      });
    setParkingObjects(tempParkingSpots);
  }, [parking, originalImageScale]);

  const handleMouseUp = useCallback(() => {
    if (!drawing) return;

    setDrawingParkingSpot(null);
    const newOffice = {
      x: Math.min(startPos.x, currentPos.x),
      y: Math.min(startPos.y, currentPos.y),
      width:
        Math.abs(currentPos.x - startPos.x) < 10
          ? 10
          : Math.abs(currentPos.x - startPos.x),
      height:
        Math.abs(currentPos.y - startPos.y) < 10
          ? 10
          : Math.abs(currentPos.y - startPos.y),
      saved: false,
      id: parkingObjects.length,
      name: selectedParking.name,
    };
    setParkingObjects((prevOffices) => [...prevOffices, newOffice]);
    setTimeout(() => {
      if (
        activeParkingSpotId !== null &&
        parkingRefs.current[activeParkingSpotId]
      ) {
        transformerRef.current.rotateEnabled(false);
        transformerRef.current.nodes([
          parkingRefs.current[activeParkingSpotId],
        ]);
        transformerRef.current.getLayer().batchDraw();
      }
    }, 0);
    setDisableDone(false);
    setDrawing(false);
  }, [
    drawing,
    startPos,
    currentPos,
    parking,
    parkingObjects,
    activeParkingSpotId,
  ]);

  const handleMouseMove = useCallback(
    (e) => {
      if (!drawing) return;

      const stage = e.target.getStage();
      const { x, y } = stage.getPointerPosition();
      const { x: stageX, y: stageY } = stage.getPosition();
      const stageScale = stage.getScale();
      const transformedPointerPos = {
        x: (x - stageX) / stageScale.x,
        y: (y - stageY) / stageScale.y,
      };
      const newOffice = {
        x: Math.min(startPos.x, transformedPointerPos.x),
        y: Math.min(startPos.y, transformedPointerPos.y),
        width: Math.abs(transformedPointerPos.x - startPos.x),
        height: Math.abs(transformedPointerPos.y - startPos.y),
        saved: false,
        id: parkingObjects.length,
        name: selectedParking.name,
      };
      setDrawingParkingSpot(newOffice);
      setCurrentPos(transformedPointerPos);
    },
    [drawing]
  );

  const handleMouseDown = useCallback(
    (e) => {
      if (!allowDrawing || !officeSaved) return;

      const stage = e.target.getStage();
      const { x, y } = stage.getPointerPosition();
      const { x: stageX, y: stageY } = stage.getPosition();
      const stageScale = stage.getScale();
      const transformedPointerPos = {
        x: (x - stageX) / stageScale.x,
        y: (y - stageY) / stageScale.y,
      };
      setStartPos(transformedPointerPos);
      setCurrentPos(transformedPointerPos);
      setDrawing(true);
      setParkingSpotSaved(false);
      setActiveParkingSpotId(parkingObjects.length);
    },
    [allowDrawing, officeSaved, parkingObjects.length]
  );

  const handleDone = useCallback(() => {
    setActiveParkingSpotId(null);
    setAllowDrawing(false);
    setParkingSpotSaved(true);
    let index = editing ? activeParkingSpotId : parkingObjects.length - 1;
    if (!parkingRefs.current[index]) return;
    const shape = parkingRefs.current[index];
    if (!shape) return;
    if (parkingObjects.length !== 0) parkingObjects[index].saved = true;
    const newParkingSpots = [...parkingObjects];
    newParkingSpots[index] = {
      ...newParkingSpots[index],
      name: selectedParking?.name,
      x: shape.x(),
      y: shape.y(),
      width: shape.width() * shape.scaleX(),
      height: shape.height() * shape.scaleY(),
    };
    shape.scaleX(1);
    shape.scaleY(1);
    setParkingObjects(newParkingSpots);
    transformerRef.current.nodes([]);
    let ps = newParkingSpots[index];
    let promises = [];
    if (editing) {
      let ps = newParkingSpots[index];
      promises.push(
        updateParkingSpot({
          id: ps.id,
          locationId: locationEdited.id,
          parkingSpotObject: {
            x: ps.x / originalImageScale,
            y: ps.y / originalImageScale,
            width: ps.width / originalImageScale,
            height: ps.height / originalImageScale,
          },
        })
      );
    } else {
      promises.push(
        addParkingSpot({
          spotNumber: ps.name,
          locationId: locationEdited.id,
          parkingSpotObject: {
            x: ps.x / originalImageScale,
            y: ps.y / originalImageScale,
            width: ps.width / originalImageScale,
            height: ps.height / originalImageScale,
          },
        })
      );
    }
    Promise.all(promises).then(getParkingSpots);
    setEditing(false);
  }, [parkingObjects, parking, transformerRef, originalImageScale]);

  const handleOnModalSubmit = (name) => {
    setOpenOfficeModal(false);
    setDisableDone(true);
    if (image) {
      setAllowDrawing(true);
      setSelectedParking({ name: name });
    } else {
      setDisableDone(false);
      addParkingSpot({
        spotNumber: name,
        locationId: locationEdited.id,
        parkingSpotObject: {
          x: 0,
          y: 0,
          width: 0,
          height: 0,
        },
      }).then(getParkingSpots);
    }
  };

  const addParkingSpot = async (parkingSpot) => {
    await AddNewParkingSpot(locationEdited.id, parkingSpot);
  };

  const updateParkingSpot = async (parkingSpot) => {
    await UpdateParkingSpot(locationEdited.id, parkingSpot);
  };

  const getParkingSpotData = async (id) => {
    setOpenDeleteModal(true);
    setLoading(true);
    setRelatedData(await GetParkingSpotData(id));
    setLoading(false);
  };

  const getParkingSpots = async () => {
    let parkingSpots = await GetParkingSpots(locationEdited.id);
    setParking(parkingSpots);
  };

  const handleEditLocation = async (name, file) => {
    setOpenLocationModal(false);
    let promises = [];
    if (name)
      promises.push(
        UpdateLocation(location.id, { ...locationEdited, name: name }).then(
          setLocation
        )
      );
    if (file) promises.push(UpdateFloorplanFile(locationEdited.id, file));
    await Promise.all(promises).then(() => {
      handleImageChange();
    });
  };

  const handleEditMove = (parking) => {
    setEditing(true);
    setSelectedParking(parking);
    const index = parkingObjects.findIndex((p) => p.id === parking.id);
    if (index !== -1) {
      transformerRef.current.rotateEnabled(false);
      transformerRef.current.nodes([parkingRefs.current[index]]);
      transformerRef.current.getLayer().batchDraw();
      setActiveParkingSpotId(index);
      const updatedParkingObjects = [...parkingObjects];
      const p = updatedParkingObjects[index];
      updatedParkingObjects[index] = {
        ...p,
        saved: false,
        width: p.width === 0 ? 100 : p.width,
        height: p.height === 0 ? 100 : p.height,
      };
      setParkingObjects(updatedParkingObjects);
    }
  };

  const handleParkingSelection = (parking) => {
    setParkingObjects(
      parkingObjects.map((p) => {
        return { ...p, selected: p.id === parking.id };
      })
    );
  };

  return (
    <Stack verticalFill>
      <DeleteModal
        isOpen={openDeleteModal}
        loading={loading}
        parkingSpot={selectedParking}
        modalText={t("deleteMessage.confirmDelete")}
        location={locationEdited}
        data={relatedData}
        onDismiss={() => setOpenDeleteModal(false)}
        onDelete={() => onDeleteParkingSpot(selectedParking)}
        parking
      />
      <ActionBarComponent
        actions={[
          {
            text: t("floorplan.edit"),
            primary: true,
            action: () => setOpenLocationModal(true),
            disabled: allowDrawing || editing,
          },
          {
            text: t("floorplan.addParking"),
            primary: true,
            action: () => setOpenOfficeModal(true),
            disabled: allowDrawing || editing,
          },
          {
            text: t("common.done"),
            disabled: (!allowDrawing && !editing) || disableDone,
            primary: true,
            action: handleDone,
          },
        ]}
      />
      {showProcess && (
        <ProcessingComponent
          actions={processActions}
          onFinish={() => setShowProcess(false)}
        />
      )}
      <LocationModal
        isOpen={openLocationModal}
        onDismiss={() => setOpenLocationModal(false)}
        onSubmit={handleEditLocation}
        parking
        location={locationEdited}
        edit
      />
      <CustomModal
        isOpen={openOfficeModal}
        onDismiss={() => setOpenOfficeModal(false)}
        onSubmit={handleOnModalSubmit}
        modalText={t("floorplan.createParking")}
      />
      <Stack horizontal verticalFill>
        <StackItem grow>
          <Stack className="page">
            <Header style={{ fontSize: 30 }} text={locationEdited?.name} />
          </Stack>
          {!!image ? (
            <FloorplanComponent
              offices={
                drawingParkingSpot
                  ? parkingObjects.concat(drawingParkingSpot)
                  : parkingObjects
              }
              drawing={drawing}
              desks={[]}
              image={image}
              edit={true}
              editing={editing}
              officeEditing={editing}
              officeRefs={parkingRefs}
              allowDrawing={allowDrawing}
              deskRefs={[]}
              transformerRef={transformerRef}
              handleMouseMove={handleMouseMove}
              handleMouseUp={handleMouseUp}
              handleMouseDown={handleMouseDown}
            />
          ) : (
            <Stack verticalFill>
              <EmptyMessage icon="Car" text={t("floorplan.noFloorplan")} />
            </Stack>
          )}
        </StackItem>
        <ParkingFloorplanSidebar
          parkingSpots={parking}
          editing={editing}
          drawing={allowDrawing}
          selectSpot={handleParkingSelection}
          onDeleteParkingSpot={(parking) => {
            getParkingSpotData(parking.id).then(() => setOpenDeleteModal(true));
            setSelectedParking(parking);
          }}
          onEditParkingSpot={(parking) => handleEditMove(parking)}
        />
      </Stack>
    </Stack>
  );
};

export default EditParkingFloorplanComponent;
