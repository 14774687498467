import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Stack,
} from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isLoading } from "./Loading";
import { isEmpty } from "./Messages/EmptyMessage";

const SubPage = ({
  items,
  loading,
  columns,
  emptyMessageProps,
  renderFunction,
  countText,
  filter,
}) => {
  const { t } = useTranslation();
  const [sortedItems, setSortedItems] = useState([...items]);
  const [height, setHeight] = useState(window.innerHeight);
  const [listColumns, setListColumns] = useState([...columns]);

  useEffect(() => {
    setSortedItems(items);
  }, [items]);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onColumnClick = (e, column) => {
    let tempColumns = listColumns.map((c) => {
      return {
        ...c,
        isSorted: c.key == column.key,
        isSortedDescending: c.key == column.key && !c.isSortedDescending,
      };
    });
    setListColumns(tempColumns);
    sortItems(column.key, !column.isSortedDescending);
  };

  const sortItems = (key, descending) => {
    let tempItems = [...sortedItems];
    tempItems.sort((a, b) => {
      const valueA = String(a[key]).toLowerCase();
      const valueB = String(b[key]).toLowerCase();

      return !descending
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    });
    setSortedItems(tempItems);
  };

  const filterItems = useCallback(
    () =>
      !filter
        ? sortedItems
        : sortedItems.filter((i) =>
            listColumns
              .filter((c) => c.searchable)
              .some((c) =>
                String(i[c.key]).toLowerCase().includes(filter.toLowerCase())
              )
          ),
    [sortedItems, filter]
  );

  return (
    <Stack
      verticalFill
      grow
      style={{
        overflow: "auto",
        height: window.innerHeight - 200,
      }}
    >
      {isLoading(
        loading,
        isEmpty(
          filterItems(),
          <DetailsList
            data-is-scrollable={true}
            items={filterItems()}
            columns={listColumns.map((c) => {
              return {
                ...c,
                onColumnClick: c.unSortable ? null : onColumnClick,
                name:
                  c.key == "view"
                    ? `${countText}: ${filterItems().length}`
                    : t(c.name),
              };
            })}
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.justified}
            onRenderItemColumn={renderFunction}
          />,
          emptyMessageProps
        )
      )}
    </Stack>
  );
};

export default SubPage;
