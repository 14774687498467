import {
  DefaultButton,
  Modal,
  PrimaryButton,
  Stack,
  TextField,
} from "@fluentui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { defaultModalInnerStyle } from "../../helpers/data";
import { AddGroupPermissions, UpdateGroup } from "../../services/GroupService";

const CreateGroupModal = ({
  isOpen,
  onSubmit,
  onDismiss,
  acceptText,
  title,
  create,
  selectedGroup,
}) => {
  const { t } = useTranslation();
  const [groupName, setGroupName] = useState("");
  const [disableCreate, setDisableCreate] = useState(false);

  const handleCreateSubmit = async () => {
    setDisableCreate(true);
    onSubmit(await AddGroupPermissions({ name: groupName }));
    setGroupName("");
    setDisableCreate(false);
  };

  const handleEditSubmit = async () => {
    setDisableCreate(true);
    onSubmit(
      await UpdateGroup(selectedGroup.id, { ...selectedGroup, name: groupName })
    );
    setGroupName("");
    setDisableCreate(false);
  };

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Stack className={defaultModalInnerStyle}>
        <Stack
          grow
          horizontalAlign="space-between"
          horizontal
          verticalAlign="center"
        >
          <h4 style={{ marginTop: "15px", marginBottom: "30px" }}>{title}</h4>
        </Stack>
        <TextField
          onChange={(event) => {
            setGroupName(event.target.value);
          }}
          value={groupName}
          label={t("management.groupName")}
          required
        />
        <br />
        <Stack horizontalAlign="end" horizontal tokens={{ childrenGap: 10 }}>
          <PrimaryButton
            disabled={!groupName || disableCreate}
            onClick={create ? handleCreateSubmit : handleEditSubmit}
          >
            {acceptText}
          </PrimaryButton>
          <DefaultButton onClick={onDismiss}>{t("common.close")}</DefaultButton>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default CreateGroupModal;
