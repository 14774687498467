import React from "react";
import ViewFloorplanComponent from "../FloorplanEditor/ViewFloorplanComponent";
import { IconButton } from "@fluentui/react";

function ViewReservation({
  location,
  hotdeskId,
  onDismiss,
  parkingSpotNumber,
}) {
  return (
    <div
      style={{
        background: "#00000069",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={onDismiss}
    >
      <div
        style={{
          position: "relative",
          background: "white",
          width: "80vw",
          overflow: "hidden",
          borderRadius: "10px",
          boxShadow: "3px 3px 11px -2px rgba(0,0,0,0.5)",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <IconButton
          iconProps={{
            iconName: "Cancel",
            styles: { root: { fontSize: 20 } },
          }}
          title="Close"
          ariaLabel="Close"
          style={{
            position: "absolute",
            top: "5px",
            left: "5px",
            zIndex: 1,
            height: "25px",
            width: "25px",
          }}
          onClick={onDismiss}
        />
        <ViewFloorplanComponent
          location={location}
          hotDeskId={hotdeskId}
          parkingSpotId={parkingSpotNumber}
        />
      </div>
    </div>
  );
}

export default ViewReservation;
