import React from "react";
import { Circle } from "react-konva";

const getColorFromState = (state) => {
  switch (state) {
    case "Available":
      return "#69b206c0";
    case "Selected":
      return "#0058d4c0";
    case "Unavailable":
      return "#a4262cc0";
    case "PermissionDenied":
    case "Disabled":
    default:
      return "#C8C8C8cc";
  }
};

const DeskComponent = ({
  desk,
  deskRefs,
  index,
  handleTransform,
  handleDragMove,
  onClick,
  edit,
  deskEdit,
  radius,
}) => {
  const color = edit
    ? !desk.active
      ? "#C8C8C8"
      : "#0058d4c0"
    : getColorFromState(desk.status);
  return (
    <Circle
      ref={(node) => {
        deskRefs.current[index] = node;
      }}
      key={index}
      id={"desk" + index}
      x={desk.x}
      y={desk.y}
      onTransform={() => handleTransform(index)}
      onDragMove={() => handleDragMove(true, index)}
      draggable={!desk.saved}
      onClick={onClick}
      onTouchStart={onClick}
      radius={radius ?? 13}
      fill={
        desk.selected
          ? "#65CE13"
          : deskEdit && desk.status === "Edit"
          ? "#fff700cc"
          : color
      }
    />
  );
};

export default DeskComponent;
