import { Icon, Stack, Text } from "@fluentui/react";
import React from "react";

export const isEmpty = (items, component, emptyProps) =>
  items && items.length > 0 ? component : <EmptyMessage {...emptyProps} />;

const EmptyMessage = (props) => {
  return (
    <Stack
      style={{ marginBottom: "20%", textAlign: "center" }}
      horizontalAlign="center"
      verticalFill
      grow
      verticalAlign="center"
    >
      <Icon style={{ fontSize: 30 }} iconName={props.icon} />
      <Text style={{ fontSize: 20 }}>{props.text}</Text>
    </Stack>
  );
};

export default EmptyMessage;
