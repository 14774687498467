import { Dropdown } from "@fluentui/react/lib/Dropdown";
import React, { useEffect } from "react";
import { dropdownStyles } from "../../../helpers/data";
import { Icon, Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";

export const BasicDropdown = ({
  options,
  placeholder,
  label,
  handleChange,
  defaultItem,
  style,
  item,
  clearButton = false,
  onClear,
  disabled,
}) => {
  const [selectedItem, setSelectedItem] = React.useState(item);
  const [t] = useTranslation();

  const onChange = (event, item) => {
    setSelectedItem(item);
    handleChange(item);
  };

  useEffect(() => {
    if (defaultItem && !selectedItem) {
      onChange(null, defaultItem);
    } else if (options.length === 1 && !selectedItem) {
      onChange(null, options[0]);
    }
  }, [options, defaultItem]);

  return (
    <Dropdown
      placeholder={placeholder}
      selectedKey={selectedItem ? selectedItem.key : undefined}
      onChange={onChange}
      label={label}
      options={options}
      styles={style == null ? dropdownStyles : style}
      responsiveMode={"small"}
      disabled={disabled}
      onRenderCaretDown={(event) => {
        return (
          <>
            {clearButton && (
              <Stack horizontal verticalAlign={"center"}>
                {!!selectedItem?.key && (
                  <Icon
                    iconName={"Cancel"}
                    styles={{
                      root: {
                        color: "rgb(96, 94, 92)",
                        paddingRight: ".7em",
                        "&:hover": {
                          fontWeight: 800,
                        },
                      },
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      setSelectedItem({ key: null });
                      onClear();
                    }}
                  />
                )}
                <Icon
                  iconName={"ChevronDown"}
                  styles={{
                    root: {
                      color: "rgb(96, 94, 92)",
                      "&:hover": {
                        fontWeight: 800,
                      },
                    },
                  }}
                  onClick={(event) => event.currentTarget.parentNode.onClick}
                />
              </Stack>
            )}
          </>
        );
      }}
    />
  );
};

export default BasicDropdown;
