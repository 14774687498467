import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import Loading from "./components/General/Loading";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

export const LoginConfigContext = createContext();

const ConfigProvider = (props) => {
  const [loading, setLoading] = useState(true);
  const [msalInstance, setMsalInstance] = useState();
  const [loginConfig, setLoginConfig] = useState();

  const handleConfig = ({ data }) => {
    setLoginConfig(data);
    const msalConfig = {
      auth: {
        authority: `https://login.microsoftonline.com/${data.tenantId}`,
        clientId: data.clientId,
        redirectUri: document.getElementById("root").baseURI,
        knownAuthorities: ["login.microsoftonline.com"],
        scope: data.scope,
        navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
      },
    };
    setMsalInstance(new PublicClientApplication(msalConfig));
  };

  useEffect(() => {
    if (msalInstance) {
      setLoading(false);
    }
  }, [msalInstance]);

  useEffect(() => {
    const api = axios.create({
      baseURL: `${document.getElementById("root").baseURI}api`,
    });

    api.get("react-config/login").then(handleConfig);
  }, []);

  if (loading) return <Loading />;

  return (
    <LoginConfigContext.Provider value={loginConfig}>
      <BrowserRouter basename={baseUrl}>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </BrowserRouter>
    </LoginConfigContext.Provider>
  );
};

ReactDOM.render(<ConfigProvider />, rootElement);
