import {
  ComboBox,
  DefaultButton,
  DialogFooter,
  Modal,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { defaultModalInnerStyle, defaultStackStyle } from "../../helpers/data";

const DeskPermissionModal = ({
  items,
  isOpen,
  onDismiss,
  onSubmit,
  text,
  placeholder,
  label,
}) => {
  const [item, setItem] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const dataRef = useRef(null);
  const data = items.map((item) => {
    return {
      text: item.location ? `${item.location?.name} - ${item.name}` : item.name,
      key: item.id,
      data: item,
    };
  });
  const { t, i18n } = useTranslation();

  const handlePrimaryButtonClick = () => {
    setDisableButton(true);
    onSubmit(item.data);
    onDismiss();
    setDisableButton(false);
  };

  useEffect(() => {
    setItem(null);
    setDisableButton(false);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Stack tokens={defaultStackStyle} className={defaultModalInnerStyle}>
        <ComboBox
          required
          componentRef={dataRef}
          options={
            searchTerm
              ? data.filter((o) =>
                  o.text.toLowerCase().includes(searchTerm.toLowerCase())
                )
              : data
          }
          onInputValueChange={(text) => {
            setSearchTerm(text);
            if (text === "") {
              dataRef.current?.dismissMenu();
            } else {
              dataRef.current?.focus(true);
            }
          }}
          onChange={(event, option) => {
            setItem(option);
          }}
          allowFreeform
          dropdownWidth={300}
          autoComplete="off"
          placeholder={placeholder}
          label={label}
        />
        <DialogFooter>
          <PrimaryButton
            disabled={!item || disableButton}
            text={text}
            onClick={handlePrimaryButtonClick}
          />
          <DefaultButton text={t("common.close")} onClick={onDismiss} />
        </DialogFooter>
      </Stack>
    </Modal>
  );
};

export default DeskPermissionModal;
