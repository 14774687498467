import {
  Calendar,
  Callout,
  DayOfWeek,
  DefaultButton,
  DirectionalHint,
  FocusTrapZone,
  Label,
  defaultCalendarStrings,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { GetConfig } from "../../../services/ConfigService";

const getSQLDateFormat = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}-${month}-${day}`;
};

const BasicCalendar = (props) => {
  const [selectedDate, setSelectedDate] = React.useState();
  const [showCalendar, { toggle: toggleShowCalendar, setFalse: hideCalendar }] =
    useBoolean(false);
  const [deskDays, setDeskDays] = React.useState();
  const buttonContainerRef = React.useRef(null);
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    GetConfig("deskDays").then((c) => setDeskDays(c.data));
  }, []);

  const getReadableDate = (date) => {
    return new Date(date).toLocaleDateString(i18n.language);
  };

  const onSelectDate = React.useCallback(
    (date, dateRangeArray) => {
      setSelectedDate(date);
      hideCalendar();
      props.handleSelect(getSQLDateFormat(date));
    },
    [hideCalendar]
  );

  let maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + deskDays);

  return (
    <div>
      <div ref={buttonContainerRef} style={{ marginRight: "10px" }}>
        <Label>{t("common.fromDate")}</Label>
        <DefaultButton
          onClick={toggleShowCalendar}
          text={
            !selectedDate
              ? t("common.selectDate")
              : getReadableDate(selectedDate)
          }
          disabled={props.disabled}
        />
      </div>
      {showCalendar && (
        <Callout
          isBeakVisible={false}
          gapSpace={0}
          doNotLayer={false}
          target={buttonContainerRef}
          directionalHint={DirectionalHint.bottomLeftEdge}
          onDismiss={hideCalendar}
          setInitialFocus>
          <FocusTrapZone isClickableOutsideFocusTrap>
            <Calendar
              firstDayOfWeek={DayOfWeek.Monday}
              onSelectDate={onSelectDate}
              onDismiss={hideCalendar}
              isMonthPickerVisible={false}
              value={selectedDate}
              highlightCurrentMonth
              isDayPickerVisible
              showGoToToday
              minDate={new Date()}
              maxDate={maxDate}
              // Calendar uses English strings by default. For localized apps, you must override this prop.
              strings={defaultCalendarStrings}
            />
          </FocusTrapZone>
        </Callout>
      )}
    </div>
  );
};

export default BasicCalendar;
