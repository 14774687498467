import { useMsal } from "@azure/msal-react";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { useContext, useEffect, useState } from "react";
import { ReactConfigContext } from "./ReactConfigSetup";

const AppInsightsSetup = (props) => {
  const reactConfig = useContext(ReactConfigContext);
  const { instance, accounts, inProgress } = useMsal();
  const [appInsights, setAppInsights] = useState();

  useEffect(() => {
    if (reactConfig) {
      var reactPlugin = new ReactPlugin();
      let tempAppInsights = new ApplicationInsights({
        config: {
          connectionString: reactConfig.appInsightsKey,
          enableAutoRouteTracking: true,
          extensions: [reactPlugin],
        },
      });
      if (reactConfig.AppInsightsKey) {
        try {
          tempAppInsights.loadAppInsights();
          setAppInsights(tempAppInsights);
        } catch {}
      }
    }
  }, [reactConfig]);

  useEffect(() => {
    if (accounts[0].username && appInsights)
      appInsights.setAuthenticatedUserContext(accounts[0].username, null, true);
  }, [appInsights, accounts]);
  return props.children;
};

export default AppInsightsSetup;
