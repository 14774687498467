import { MessageBar, MessageBarType } from "@fluentui/react";
import React, { useEffect } from "react";

const CustomMessageBar = ({
  message,
  visible,
  setShowSuccessMessage,
  error,
}) => {
  useEffect(() => {
    setTimeout(() => {
      setShowSuccessMessage();
    }, 5000);
  }, [visible]);

  return (
    <MessageBar
      messageBarType={error ? MessageBarType.error : MessageBarType.success}
      dismissButtonAriaLabel="Close"
    >
      {message}
    </MessageBar>
  );
};

export default CustomMessageBar;
